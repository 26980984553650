import dayjs from 'dayjs'
import React, { useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import StudyObjectSelection from './inputs/StudyObjectSelection'
import GroupCompareSelection from './inputs/GroupCompareSelection'
import {
  CompareGroupType,
  StudyObjectType,
} from './configs/StudiesObjectsCompareGroupTypesConfig'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  newReadershipProfileStateReinitializePostPreview,
  newReadershipProfileStateSetAnalyticsName,
  newReadershipProfileStateSetCompareGroupBooks,
  newReadershipProfileStateSetCompareGroupType,
  newReadershipProfileStateSetCompareGroupValue,
  newReadershipProfileStateSetStudyObjectBooks,
  newReadershipProfileStateSetStudyObjectType,
  newReadershipProfileStateSetStudyObjectValue,
} from '../../../../store/reducers/analytics-details/new-analytics-readership-profile-state.reducer'
import {
  selectNewAnalyticsReadershipProfileStateCompareGroupBooks,
  selectNewAnalyticsReadershipProfileStateCompareGroupType,
  selectNewAnalyticsReadershipProfileStateCompareGroupValue,
  selectNewAnalyticsReadershipProfileStateIsLoadingPostPreview,
  selectNewAnalyticsReadershipProfileStatePostPreviewError,
  selectNewAnalyticsReadershipProfileStatePostPreviewSuccess,
  selectNewAnalyticsReadershipProfileStateStudyObjectBooks,
  selectNewAnalyticsReadershipProfileStateStudyObjectType,
  selectNewAnalyticsReadershipProfileStateStudyObjectValue,
} from '../../../../store/getters/analytics-details/new-analytics-readership-profile.getters'
import { newAnalyticsReadershipProfileStatePostPreviewForReadershipProfile } from '../../../../store/actions/analytics-details/new-analytics-readership-profile.actions'
import GlVDivider from '../../../share/GlVDivider'
import {
  CompareGroupValueType,
  StudyObjectValueType,
} from '../../../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import RecapInputTag from './inputs/recap/RecapInputTag'
import { SetColorConfig } from '../../../../core/config/SetColorConfig'
import GlSpinner from '../../../share/GlSpinner'
import {
  selectAnalyticDetailsStateAnalyticDetails,
  selectAnalyticDetailsStateBookDetailsBookReco,
  selectAnalyticDetailsStateBookDetailsSelected,
  selectAnalyticDetailsStateBookDetailsSelectedB2BInfo,
  selectAnalyticDetailsStateBookDetailsSelectedEditions,
  selectAnalyticDetailsStateIsLoadingBookDetailsGetBookReco,
  selectAnalyticDetailsStateIsLoadingGetB2BBookDetails,
  selectAnalyticDetailsStateIsLoadingGetBookDetails,
  selectAnalyticDetailsStateIsLoadingGetEditions,
} from '../../../../store/getters/analytics-details/analytic-details-v2.getters'
import GlSideBar from '../../../share/GlSideBar'
import WorkMirrorBookDetailsCard from '../work-mirror/WorkMirrorBookDetailsCard'
import { analyticDetailsV2StateGetBookDetailsDataById } from '../../../../store/actions/analytics-details/analytic-details-v2.actions'
import { BookInterface } from '../../../../core/interface/book.interface'

const ValidationCardStyle = styled.div`
  overflow: hidden;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const ValidationButtonStyle = styled.button`
  width: 220px;
  height: 40px;
  text-align: center;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  background-color: #252525;
  color: white;

  :disabled {
    background-color: #e9e9e9;
    color: #929292;
  }
`

const AnalyticDetailsNewRequestStartStep = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const analyticsDetails = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetails,
  )
  const studyObjectType = useAppSelector(
    selectNewAnalyticsReadershipProfileStateStudyObjectType,
  )
  const studyObjectValue = useAppSelector(
    selectNewAnalyticsReadershipProfileStateStudyObjectValue,
  )

  const studyObjectBooks = useAppSelector(
    selectNewAnalyticsReadershipProfileStateStudyObjectBooks,
  )
  const setStudyObjectType = (studyObjectType: StudyObjectType) => {
    dispatch(newReadershipProfileStateSetStudyObjectType(studyObjectType))
  }

  const compareGroupType = useAppSelector(
    selectNewAnalyticsReadershipProfileStateCompareGroupType,
  )

  const compareGroupValue = useAppSelector(
    selectNewAnalyticsReadershipProfileStateCompareGroupValue,
  )

  const compareGroupBooks = useAppSelector(
    selectNewAnalyticsReadershipProfileStateCompareGroupBooks,
  )
  const setCompareGroupType = (compareGroupType: CompareGroupType) => {
    dispatch(newReadershipProfileStateSetCompareGroupType(compareGroupType))
  }

  const handleOnSelectStudyObjectsBooks = (books: BookInterface[]) => {
    dispatch(newReadershipProfileStateSetStudyObjectBooks(books))
  }
  const handleOnSelectStudyObjectValue = (
    value: StudyObjectValueType,
    booksToRemove?: BookInterface[],
  ) => {
    const eanIdsToRemove = booksToRemove?.map((b: BookInterface) => b.id_ean)
    dispatch(
      newReadershipProfileStateSetStudyObjectValue({ value, eanIdsToRemove }),
    )
  }
  const handleOnSelectCompareGroupValue = (
    value: CompareGroupValueType,
    booksToRemove?: BookInterface[],
  ) => {
    const eanIdsToRemove = booksToRemove?.map((b: BookInterface) => b.id_ean)
    dispatch(
      newReadershipProfileStateSetCompareGroupValue({ value, eanIdsToRemove }),
    )
  }
  const handleOnSelectGroupCompareBooks = (books: BookInterface[]) => {
    dispatch(newReadershipProfileStateSetCompareGroupBooks(books))
  }

  const onClickValidateRequest = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    void dispatch(
      newAnalyticsReadershipProfileStatePostPreviewForReadershipProfile(),
    )
  }

  const isLoadingPostPreview = useAppSelector(
    selectNewAnalyticsReadershipProfileStateIsLoadingPostPreview,
  )
  const postPreviewSuccess = useAppSelector(
    selectNewAnalyticsReadershipProfileStatePostPreviewSuccess,
  )
  const postPreviewError = useAppSelector(
    selectNewAnalyticsReadershipProfileStatePostPreviewError,
  )

  const isLoadingGetBookDetails = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetBookDetails,
  )
  const isLoadingGetEditions = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetEditions,
  )
  const isLoadingGetB2BBookDetails = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetB2BBookDetails,
  )
  const isLoadingBookDetailsGetBookReco = useAppSelector(
    selectAnalyticDetailsStateIsLoadingBookDetailsGetBookReco,
  )

  const bookDetailsBookReco = useAppSelector(
    selectAnalyticDetailsStateBookDetailsBookReco,
  )

  const bookDetailsSelectedB2BInfo = useAppSelector(
    selectAnalyticDetailsStateBookDetailsSelectedB2BInfo,
  )

  const bookDetailsSelected = useAppSelector(
    selectAnalyticDetailsStateBookDetailsSelected,
  )

  const bookDetailsSelectedEditions = useAppSelector(
    selectAnalyticDetailsStateBookDetailsSelectedEditions,
  )

  useEffect(() => {
    dispatch(
      newReadershipProfileStateSetAnalyticsName(
        analyticsDetails?.name ||
          `Profile lectorat du ${dayjs().format('DD/MM/YYYY')}`,
      ),
    )
  }, [analyticsDetails])
  useEffect(() => {
    dispatch(newReadershipProfileStateReinitializePostPreview)
  }, [postPreviewError, postPreviewSuccess])

  const handleCloseBookDetails = useCallback(() => {
    void dispatch(analyticDetailsV2StateGetBookDetailsDataById({ eanId: null }))
  }, [bookDetailsSelected])

  const handleOpenBookDetails = useCallback(
    (eanId: number | null) => {
      void dispatch(analyticDetailsV2StateGetBookDetailsDataById({ eanId }))
    },
    [
      bookDetailsSelected,
      bookDetailsSelectedEditions,
      bookDetailsSelectedB2BInfo,
    ],
  )

  return (
    <div className="flex-auto flex flex-col">
      <div className="flex-auto flex flex-col p-8 gap-[32px]">
        <div
          className="flex-auto flex flex-col bg-white rounded-xl justify-between"
          style={{
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div className="flex-auto flex flex-row items-start justify-center">
            <StudyObjectSelection
              disable={isLoadingPostPreview}
              studyObjectType={studyObjectType}
              studyObjectValue={studyObjectValue}
              handleOnClickInputSelectionButton={setStudyObjectType}
              handleSelectValue={handleOnSelectStudyObjectValue}
              booksSelected={studyObjectBooks}
              eanSelected={bookDetailsSelected && bookDetailsSelected.id_ean}
              handleUpdateBooksSelected={handleOnSelectStudyObjectsBooks}
              handleOpenBookDetails={handleOpenBookDetails}
            />
            <GlVDivider height="100%" />
            <GroupCompareSelection
              disable={isLoadingPostPreview}
              compareGroupType={compareGroupType}
              compareGroupValue={compareGroupValue}
              handleOnClickInputSelectionButton={setCompareGroupType}
              handleSelectValue={handleOnSelectCompareGroupValue}
              booksSelected={compareGroupBooks}
              eanSelected={bookDetailsSelected && bookDetailsSelected.id_ean}
              handleUpdateBooksSelected={handleOnSelectGroupCompareBooks}
              handleOpenBookDetails={handleOpenBookDetails}
            />
          </div>
        </div>
        <ValidationCardStyle
          className="flex-none flex flex-col bg-white rounded justify-between"
          style={{
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="flex flex-row justify-between items-center"
            style={{
              padding: '8px 8px 8px 32px',
            }}
          >
            <div className="flex flex-row flex-wrap gap-2 justify-start items-center">
              <span>{t('iStudyTheReadersOf')}</span>
              <RecapInputTag<StudyObjectType, StudyObjectValueType>
                type={studyObjectType}
                value={studyObjectValue}
                color={SetColorConfig[0].color1}
                menuPositionTop
                booksSelected={studyObjectBooks}
              />
              <span>{t('inComparisonWithThoseOf')}</span>
              <RecapInputTag<CompareGroupType, CompareGroupValueType>
                type={compareGroupType}
                value={compareGroupValue}
                color={SetColorConfig[0].color2}
                menuPositionTop
                booksSelected={compareGroupBooks}
              />
            </div>
            <div>
              <ValidationButtonStyle
                type="button"
                style={{
                  width: 220,
                }}
                className="relative justify-center items-center rounded"
                disabled={
                  !studyObjectType ||
                  !studyObjectValue ||
                  !compareGroupType ||
                  !compareGroupValue ||
                  isLoadingPostPreview
                }
                onClick={onClickValidateRequest}
              >
                <span>{t('validateTheSearch')}</span>
                {(isLoadingPostPreview && (
                  <div className="absolute w-full h-full flex items-center justify-center top-0">
                    <GlSpinner color="#25252566" />
                  </div>
                )) ||
                  null}
              </ValidationButtonStyle>
            </div>
          </div>
        </ValidationCardStyle>
      </div>
      {bookDetailsSelected && (
        <GlSideBar
          open
          position="right"
          hasOverlay
          width={506}
          scrollable={false}
          height="h-[calc(100%-48px)]"
        >
          <WorkMirrorBookDetailsCard
            isLoadingBookDetailsGetBookReco={isLoadingBookDetailsGetBookReco}
            bookDetailsBookReco={bookDetailsBookReco}
            isLoadingGetBookDetails={isLoadingGetBookDetails}
            isLoadingGetEditions={isLoadingGetEditions}
            isLoadingGetB2BBookDetails={isLoadingGetB2BBookDetails}
            openListCard={false}
            bookDetails={bookDetailsSelected}
            bookDetailsSelectedEditions={bookDetailsSelectedEditions}
            bookDetailsSelectedB2BInfo={bookDetailsSelectedB2BInfo}
            handleCloseBookDetails={handleCloseBookDetails}
          />
        </GlSideBar>
      )}
    </div>
  )
}

export default AnalyticDetailsNewRequestStartStep
