import { useTranslation } from 'react-i18next'
import {
  StudiesObjectsCompareGroupTypesConfig,
  StudyObjectType,
} from '../configs/StudiesObjectsCompareGroupTypesConfig'
import InputTypeChoice from './InputTypeChoice'
import { StudyObjectValueType } from '../../../../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import { SetColorConfig } from '../../../../../core/config/SetColorConfig'
import { BookInterface } from '../../../../../core/interface/book.interface'

const StudyObjectSelection = ({
  disable,
  studyObjectType,
  studyObjectValue,
  handleOnClickInputSelectionButton,
  handleSelectValue,
  booksSelected,
  handleUpdateBooksSelected,
  handleOpenBookDetails,
  eanSelected,
}: {
  disable: boolean
  studyObjectType: StudyObjectType
  studyObjectValue: StudyObjectValueType
  handleOnClickInputSelectionButton: (value: StudyObjectType) => void
  handleSelectValue: (
    value: StudyObjectValueType,
    booksToRemove?: BookInterface[],
  ) => void
  booksSelected: BookInterface[]
  handleUpdateBooksSelected: (books: BookInterface[]) => void
  handleOpenBookDetails: (eanId: number | null) => void
  eanSelected: number | null
}) => {
  const { t } = useTranslation()
  return (
    <InputTypeChoice<StudyObjectType, StudyObjectValueType>
      disable={disable}
      keyValue="study-object"
      color={SetColorConfig[0].color1}
      title={t('iStudyTheReadersOfNew')}
      subTitle={t('StudyObject')}
      inputsTypeConfig={StudiesObjectsCompareGroupTypesConfig}
      inputTypeSelected={studyObjectType}
      inputValue={studyObjectValue}
      handleOnClickInputSelectionButton={handleOnClickInputSelectionButton}
      handleSelectValue={handleSelectValue}
      booksSelected={booksSelected}
      handleUpdateBooksSelected={handleUpdateBooksSelected}
      handleOpenBookDetails={handleOpenBookDetails}
      eanSelected={eanSelected}
    />
  )
}

export default StudyObjectSelection
