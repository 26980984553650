import '../../../../styles/_shared.scss'
import '../../../../styles/pages/List.scss'
import styled from '@emotion/styled'
import AudiencesResultsTableV2 from './table/AudiencesResultsTableV2'
import { BookDetailInterface } from '../../../../core/interface/rank/book-detail.interface'
import { TrendV2Interface } from '../../../../core/interface/trend-v2.interface'
import { TrendsItemFilterActionInterface } from '../../../../core/interface/audiences/trends-item-filter-action.interface'
import { SortDirectionEnum } from '../../../../core/enums/sort-direction.enum'
import { TagFilterEnum } from '../../../../core/enums/tag-filter.enum'
import AudiencesResultsBookDetails from '../book-details/AudiencesResultsBookDetails'
import BackGroundNoData from '../../../../assets/images/no-result-background.svg'

const LayoutStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ hasFilter }: { hasFilter: boolean }) => 'calc(100% + 234px)'};
  display: flex;
  flex-direction: column;
  background-image: url('${BackGroundNoData}');
  background-repeat: no-repeat;
  background-position: 30% 30%;
`

const Title = styled.div`
  color: #252525;
  text-align: center;
  font-size: 17px;
  font-family: 'Vollkorn', serif;
  font-weight: 500;
  line-height: 153.23%;
`

const Description = styled.div`
  color: #595959;
  text-align: center;
  font-size: 12px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;
  line-height: 160%;
  max-width: 360px;
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 4px;
  background-color: #252525;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.28px;
  text-transform: uppercase;
`

const AudiencesResultsViewNoData = () => {
  return (
    <div className="relative h-full flex flex-row bg-[#f7f7f7]">
      <LayoutStyle
        hasFilter={false}
        className="flex flex-col items-center justify-center"
      >
        <div className="flex flex-col">
          <Title>Essayez avec d’autres critères</Title>
          <Description>
            Vous pouvez modifier les paramètres de votre recherche pour obtenir
            des résultats significatifs !
          </Description>
        </div>
      </LayoutStyle>
    </div>
  )
}

export default AudiencesResultsViewNoData
