import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { nanoid } from 'nanoid'
import styled from '@emotion/styled'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import GlCardExpand from '../../components/share/GlCardExpand'
import { ReactComponent as SearchIcon } from '../../assets/icons/app/search.icon.svg'
import { ReactComponent as SaveIcon } from '../../assets/icons/app/save.icon.svg'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  selectAudiencesSearchIsLoadingTopRequestSaved,
  selectAudiencesSearchIsLoadingTopRequestSavedNext,
  selectAudiencesSearchTopRequestSaved,
  selectAudiencesSearchTopRequestSavedCount,
} from '../../store/getters/dynamics-search.getters'
import {
  audiencesSearchDeleteTopRequestSavedAction,
  audiencesSearchGetTopRequestSavedAction,
  audiencesSearchPutTopRequestSavedAction,
} from '../../store/actions/dynamics-search.actions'
import { reinitializeAudiencesSearchState } from '../../store/reducers/dynamics-search.reducer'
import AudiencesSearchFilters from '../../components/dynamics-search/AudiencesSearchFilters'
import AudiencesSearchTopRequestSaved from '../../components/dynamics-search/AudienceSearchTopRequestSaved'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'
import GlLoader from '../../components/share/GlLoader'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import BackGroundAuthIcon from '../../assets/icons/app/background-auth.icon.svg'
import { ReactComponent as ThickSearchIcon } from '../../assets/icons/app/thick-search.icon.svg'
import {
  buildPathWithParams,
  buildRelativePath,
  PathConfig,
} from '../../core/config/PathConfig'
import { AudienceRequestTypeEnum } from '../../core/enums/audiences/audience-request-type.enum'
import { AgeFilterEnum } from '../../core/enums/age-filter.enum'
import { GenderFilterEnum } from '../../core/enums/gender-filter.enum'
import { PeriodTypeEnum } from '../../core/enums/period-type.enum'
import {
  selectWebAppStateCatalogs,
  selectWebAppStateMySelf,
  selectWebAppStateMySelfHasDynamicsAccess,
  selectWebAppStateTagGroups,
} from '../../store/getters/web-app.getters'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import { webAppGetCatalogsAction } from '../../store/actions/web-app.actions'

const AudiencesSearchLLayout = styled.div`
  background-image: url('${BackGroundAuthIcon}');
  background-repeat: no-repeat;
  background-position: 90% 10%;
  width: calc(100% - 48px);
  height: calc(100% - 64px);
  padding: 0 8px 0 0;
  margin-bottom: 48px;
`

const DynamicsTitle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  color: #252525;
`

const ButtonStyle = styled.button`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  z-index: 50;
  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 0 20px rgba(0, 0, 0, 0.1);
  }
  &:active {
    box-shadow: none;
  }
`

const DynamicsSearchView = () => {
  const tagGroups = useAppSelector(selectWebAppStateTagGroups)
  const { t } = useTranslation()
  const { hash } = useLocation()
  const navigate = useNavigate()
  const ref = useRef<any>(null)
  const lastWeek = dayjs().subtract(1, 'week')
  const catalogs = useAppSelector(selectWebAppStateCatalogs)
  const [openRequest, setOpenRequest] = useState<boolean>(true)
  const [openTopSaved, setOpenTopSaved] = useState<boolean>(false)
  const [catalogSelected, setCatalogSelected] =
    useState<CatalogInterface | null>(null)

  const [tagGroupSelected, setTagGroupSelected] = useState<string>(
    tagGroups[0]?.id,
  )
  const [ageSelected, setAgeSelected] = useState(AgeFilterEnum.AGE_CATEGORY_ALL)
  const [genderSelected, setGenderSelected] = useState(
    GenderFilterEnum.SEX_CATEGORY_ALL,
  )
  const [periodTypeSelected, setPeriodTypeSelected] = useState<PeriodTypeEnum>(
    PeriodTypeEnum.WEEK,
  )
  const [dateSelected, setDateSelected] = useState<{
    startDate: string
    endDate: string
  }>({
    startDate: lastWeek.startOf('weeks').toISOString(),
    endDate: lastWeek.endOf('weeks').toISOString(),
  })

  const dispatch = useAppDispatch()

  const topRequestSaved = useAppSelector(selectAudiencesSearchTopRequestSaved)
  const topRequestSavedCount = useAppSelector(
    selectAudiencesSearchTopRequestSavedCount,
  )

  const isLoadingTopRequestSaved = useAppSelector(
    selectAudiencesSearchIsLoadingTopRequestSaved,
  )
  const isLoadingTopRequestSavedNext = useAppSelector(
    selectAudiencesSearchIsLoadingTopRequestSavedNext,
  )

  const mySelf = useAppSelector(selectWebAppStateMySelf)
  const hasDynamicsAccess = useAppSelector(
    selectWebAppStateMySelfHasDynamicsAccess,
  )

  useEffect(() => {
    if (mySelf && !hasDynamicsAccess) {
      dispatch(setShowSnackBar(t('webApp.notAccessToFeature')))
      navigate(buildRelativePath([PathConfig.BOOKMETRIE]))
    } else {
      void dispatch(audiencesSearchGetTopRequestSavedAction())
    }
  }, [mySelf, hasDynamicsAccess])

  useEffect(() => {
    return () => {
      dispatch(reinitializeAudiencesSearchState())
    }
  }, [])

  useEffect(() => {
    if (catalogs.length === 0) {
      void dispatch(webAppGetCatalogsAction())
    }
  }, [])

  useEffect(() => {
    const element = document.getElementById(hash.replace('#', ''))

    if (element && hash === '#topRequestSaved-anchor') {
      setOpenRequest(false)
      setOpenTopSaved(true)
      if (element) {
        element.scrollIntoView()
      }
    }
  }, [hash])

  useEffect(() => {
    if (!catalogSelected) {
      setCatalogSelected(catalogs[0])
    }
  }, [catalogs])

  const handleRenameTopRequestSaved = (id: string, name: string) => {
    const request = topRequestSaved.find((r) => r.id === id)
    if (request) {
      dispatch(
        audiencesSearchPutTopRequestSavedAction({
          requestId: id,
          request: {
            name,
            catalog_id: request.catalog_id,
            start_date: request.start_date,
            end_date: request.end_date,
            period_type: request.period_type,
            tag_group: request.tag_group,
            age_category: request.age_category,
            sex_category: request.sex_category,
          },
        }),
      )
        .then(() => {
          void dispatch(audiencesSearchGetTopRequestSavedAction())
        })
        .catch(() =>
          dispatch(setShowSnackBar(t('snackbarMessage.errorMessage'))),
        )
    } else {
      dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
    }
  }

  const handleDeleteTopRequestSaved = (id: string) => {
    dispatch(audiencesSearchDeleteTopRequestSavedAction({ requestId: id }))
      .then(() => {
        void dispatch(audiencesSearchGetTopRequestSavedAction())
      })
      .catch(() => dispatch(setShowSnackBar(t('snackbarMessage.errorMessage'))))
  }

  const onClickToSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    navigate(
      buildPathWithParams(
        buildRelativePath([
          PathConfig.BOOKMETRIE,
          PathConfig.DYNAMICS,
          PathConfig.RESULTS,
        ]),
        {
          catalogId: catalogSelected?.id,
          tag_group: tagGroupSelected,
          tag_group_name: tagGroups.find((tg) => tg.id === tagGroupSelected)
            ?.name,
          age: ageSelected,
          gender: genderSelected,
          period: periodTypeSelected,
          startDate: dateSelected.startDate,
          endDate: dateSelected.endDate,
          requestType: AudienceRequestTypeEnum.NEW,
        },
      ),
    )
  }

  return (
    <AudiencesSearchLLayout className="absolute flex-auto pb-8" ref={ref}>
      <PerfectScrollbar className="flex-auto flex flex-col py-8 mb-8 px-12 gap-4">
        <DynamicsTitle>{t('webApp.audiences.search.title')}</DynamicsTitle>
        <GlCardExpand
          label={t('webApp.audiences.search.newSearch')}
          icon={<SearchIcon className="w-8 h-8 text-[#595959] flex" />}
          maxHeight={528}
          open={openRequest}
          setOpen={setOpenRequest}
          buttonIcon={
            <ButtonStyle
              type="button"
              className="flex flex-row items-center self-end px-4 py-2.5 bg-[#252525] hover:bg-[#25252599] active:bg-[#252525e6] gap-2 rounded-lg text-white"
              onClick={onClickToSearch}
            >
              <span>{t('commons.buttons.search').toUpperCase()}</span>
              <ThickSearchIcon className="text-white w-5 h-5" />
            </ButtonStyle>
          }
        >
          <AudiencesSearchFilters
            catalogs={catalogs}
            periodTypeSelected={periodTypeSelected}
            genderSelected={genderSelected}
            catalogSelected={catalogSelected}
            setCatalogSelected={setCatalogSelected}
            ageSelected={ageSelected}
            tagGroups={tagGroups}
            tagGroupSelected={tagGroupSelected}
            setAgeSelected={setAgeSelected}
            setGenderSelected={setGenderSelected}
            setPeriodTypeSelected={setPeriodTypeSelected}
            setTagGroupSelected={setTagGroupSelected}
            dateSelected={dateSelected}
            setDateSelected={setDateSelected}
          />
        </GlCardExpand>
        <GlCardExpand
          label={t('webApp.audiences.search.searchSave', {
            count: topRequestSavedCount,
          })}
          count={topRequestSavedCount}
          icon={<SaveIcon className="w-8 h-8 text-[#595959] flex" />}
          maxHeight={450}
          open={openTopSaved}
          setOpen={setOpenTopSaved}
          anchorName="topRequestSaved"
        >
          <div className="relative h-full">
            {isLoadingTopRequestSaved && (
              <div className="h-full w-full absolute z-10 top-0 flex items-center justify-center bg-[#25252533]">
                <GlLoader />
              </div>
            )}
            <div className="h-full flex flex-col">
              <PerfectScrollbar className="flex flex-col gap-0.5">
                {topRequestSaved.map(
                  (request: TopRequestSavedInterface, index: number) => (
                    <AudiencesSearchTopRequestSaved
                      key={nanoid(5)}
                      name={request.name || ''}
                      catalog={request.catalog}
                      tagGroup={request.tag_group}
                      tagGroupName={request.tag_group_name}
                      sexCategory={request.sex_category}
                      ageCategory={request.age_category}
                      periodType={request.period_type}
                      startDate={request.start_date}
                      endDate={request.end_date}
                      id={request.id}
                      index={index}
                      handleOnClickTopRequestSaved={() => null}
                      handleRenameTopRequestSaved={handleRenameTopRequestSaved}
                      handleDeleteTopRequestSaved={handleDeleteTopRequestSaved}
                    />
                  ),
                )}
                {(isLoadingTopRequestSavedNext && (
                  <div
                    className="flex flex-row items-center justify-center w-full"
                    style={{
                      height: 52,
                    }}
                  >
                    <GlLoader />
                  </div>
                )) ||
                  null}
              </PerfectScrollbar>
            </div>
          </div>
        </GlCardExpand>
      </PerfectScrollbar>
    </AudiencesSearchLLayout>
  )
}

export default DynamicsSearchView
