import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../core/enums/analytic-output-layout-size.enum'
import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import { AnalyticOutputTypeEnum } from '../../../../../../core/enums/analytic-output-type.enum'
import FullTabular from '../tabular/FullTabular'
import AnalyticsOutputHorizontalClilLegend from '../AnalyticsOutputHorizontalClilLegend'
import AnalyticsOutputWrapper from '../AnalyticsOutputWrapper'
import {
  AnalyticOutputLayoutFullHeightEnum,
  AnalyticOutputLayoutShrinkHeightEnum,
  SizableHeightAnalyticOutput,
} from '../../../../../../core/enums/analytic-output-layout-full-height.enum'
import ConstellationChart from './chartComponents/ConstellationChart'

const AnalyticsOutputConstellation = ({
  valuesConfigurations,
  display,
  title,
  subTitle,
  dataset,
  datasetModule,
  typeDataset,
  isPreview,
  handleAnalyticsChangeOutput,
  handleAnalyticsDeleteOutput,
  eanSelected,
  handleOpenBookDetails,
  isScreenSmall,
  isOrganizeMenuOpen,
}: {
  valuesConfigurations: ValueConfigurationsInterface[]
  display: AnalyticOutputLayoutSizeEnum
  title: string
  subTitle?: string
  dataset: DatasetInterface[]
  datasetModule: DatasetModuleInterface
  typeDataset: DatasetTypeEnum
  isPreview: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleAnalyticsChangeOutput?: () => void
  eanSelected?: number | null
  handleOpenBookDetails?: (
    eanId: number | null,
    showBookDetail: boolean,
  ) => void
  isScreenSmall?: boolean
  isOrganizeMenuOpen?: boolean
}) => {
  const { t } = useTranslation()
  const [refresh, setRefresh] = useState<number>(0)

  let infoBody1: string
  let infoBody2: string
  switch (typeDataset) {
    case DatasetTypeEnum.WORKS_MIRROR:
      infoBody1 = 'InfoBulles.workMirrorMulti.1'
      infoBody2 = 'InfoBulles.workMirrorMulti.2'
      break
    case DatasetTypeEnum.AUTHORS_MIRROR:
      infoBody1 = 'InfoBulles.authorMirrorMulti.1'
      infoBody2 = 'InfoBulles.authorMirrorMulti.2'
      break
    default:
      infoBody1 = ''
      infoBody2 = ''
  }

  const handleRefresh = () => {
    setRefresh((current) => current + 1)
  }

  useEffect(() => {
    window.addEventListener('resize', handleRefresh)
    return () => {
      window.removeEventListener('resize', handleRefresh)
    }
  }, [])

  useEffect(() => {
    handleRefresh()
  }, [datasetModule.show_table, isOrganizeMenuOpen])

  return (
    <AnalyticsOutputWrapper
      outputId={datasetModule.id}
      display={display}
      title={title}
      subTitle={subTitle}
      infoBody={[t(infoBody1), t(infoBody2)]}
      datasetModule={datasetModule}
      isPreview={isPreview}
      outputType={AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET}
      handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
      handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
      type={
        datasetModule.show_table
          ? SizableHeightAnalyticOutput.CONSTELLATION_TABULAR
          : SizableHeightAnalyticOutput.CONSTELLATION
      }
      shrink={(isPreview && isScreenSmall) || isOrganizeMenuOpen}
      customStyle={{
        ...(datasetModule.show_table &&
          isPreview && {
            height: isScreenSmall
              ? AnalyticOutputLayoutShrinkHeightEnum.MEDIUM
              : AnalyticOutputLayoutFullHeightEnum.MEDIUM,
          }),
      }}
    >
      <>
        <ConstellationChart
          dataset={dataset}
          typeDataset={typeDataset}
          refresh={refresh}
          eanSelected={eanSelected}
          handleOpenBookDetails={handleOpenBookDetails}
        />
        <AnalyticsOutputHorizontalClilLegend
          dataset={dataset}
          typeDataset={typeDataset}
        />
        {datasetModule.show_table && (
          <FullTabular
            dataset={dataset}
            valuesConfigurations={valuesConfigurations}
            typeDataset={typeDataset}
            typeModule={datasetModule.type}
            limitToTenLines
            shrink={isOrganizeMenuOpen || isPreview}
            eanSelected={eanSelected}
            handleOpenBookDetails={handleOpenBookDetails}
          />
        )}
      </>
    </AnalyticsOutputWrapper>
  )
}

export default AnalyticsOutputConstellation
