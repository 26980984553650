import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import '../../styles/_shared.scss'
import '../../styles/components/NavBar.scss'
import { useDispatch } from 'react-redux'
import HelpIcon from '../../assets/icons/help-icon.svg'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import { ranksShowList } from '../../store/reducers/trends.reducer'
import { ReactComponent as HomeIcon } from '../../assets/icons/app/home.icon.svg'
import { ReactComponent as ShelfIcon } from '../../assets/icons/app/shelf.icon.svg'
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/app/analytics.icon.svg'
import GlDialog from '../share/dialog/GlDialog'
import SettingContactCard from '../settings/SettingContactCard'
import { useAppSelector } from '../../store/hooks'
import {
  selectWebAppStateMySelfHasAnalyticsAccess,
  selectWebAppStateMySelfHasDynamicsAccess,
} from '../../store/getters/web-app.getters'

const styles = {
  backGroundIcon: (icon: string) => ({
    background: `url(${icon}) no-repeat center`,
  }),
}
const WebAppNavBar = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const [openHelpDialog, setOpenHelpDialog] = useState<boolean>(false)

  const handleClickContactUs = () => {
    setOpenHelpDialog(false)
    window.location.href = 'mailto:info@gleeph.pro'
  }

  const hasAnalyticsAccess = useAppSelector(
    selectWebAppStateMySelfHasAnalyticsAccess,
  )
  const hasDynamicsDynamicsAccess = useAppSelector(
    selectWebAppStateMySelfHasDynamicsAccess,
  )

  return (
    <nav className="flex-none flex flex-col p-2 gap-2 bg-white shadow-[0px_2px_10px_rgba(0,0,0,0.16)] border-r border-[#e3e3e3]">
      <NavLink to={`/${PathConfig.BOOKMETRIE}`}>
        <div
          className={`rounded w-10 h-10 flex justify-center items-center ${
            pathname === `/${PathConfig.BOOKMETRIE}`
              ? 'border border-black bg-header text-white'
              : 'text-[#252525]'
          }`}
        >
          <HomeIcon className="flex w-8 h-8" />
        </div>
      </NavLink>
      {(hasDynamicsDynamicsAccess && (
        <NavLink
          to={`${buildRelativePath([
            PathConfig.BOOKMETRIE,
            PathConfig.DYNAMICS,
            PathConfig.SEARCH,
          ])}`}
          onClick={() => {
            dispatch(ranksShowList(true))
          }}
        >
          <div
            className={`rounded w-10 h-10 flex justify-center items-center  ${
              pathname.startsWith(
                `/${PathConfig.BOOKMETRIE}/${PathConfig.DYNAMICS}`,
              )
                ? 'border border-black bg-header text-white'
                : 'text-[#252525]'
            }`}
          >
            <ShelfIcon className="flex w-8 h-8" />
          </div>
        </NavLink>
      )) ||
        null}
      {(hasAnalyticsAccess && (
        <NavLink to={`/${PathConfig.BOOKMETRIE}/${PathConfig.ANALYTICS}`}>
          <div
            className={`rounded w-10 h-10 flex justify-center items-center  ${
              pathname.startsWith(
                `/${PathConfig.BOOKMETRIE}/${PathConfig.ANALYTICS}`,
              )
                ? 'border border-black bg-header text-white'
                : 'text-[#252525]'
            }`}
          >
            <AnalyticsIcon className="flex w-8 h-8" />
          </div>
        </NavLink>
      )) ||
        null}
      <div className="flex flex-1 flex-auto" />

      <div
        className="rounded w-10 h-10 cursor-pointer"
        onClick={() => setOpenHelpDialog(true)}
      >
        <div
          className="w-full h-full"
          style={styles.backGroundIcon(HelpIcon)}
        />
      </div>
      <GlDialog open={openHelpDialog} onClose={() => setOpenHelpDialog(false)}>
        <SettingContactCard
          title={t('webApp.gleephCenter.helpCenterTitle')}
          mainText={t('webApp.gleephCenter.contentHelpCenter')}
          buttonText={t('commons.buttons.contactUs')}
          onClickButton={handleClickContactUs}
        />
      </GlDialog>
    </nav>
  )
}

export default WebAppNavBar
