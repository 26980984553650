import styled from '@emotion/styled'
import { ReactNode } from 'react'

const DialogBodyStyle = styled.div`
  color: #666;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const GlDialogBody = ({ children }: { children: ReactNode }) => {
  return <DialogBodyStyle className="flex flex-col">{children}</DialogBodyStyle>
}

export default GlDialogBody
