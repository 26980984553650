import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from '../../../../assets/icons/app/add-icon-plain.icon.svg'

const TitleStyle = styled.div`
  color: #3b3b3b;
  text-align: center;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`

const CardStyle = styled.button`
  width: 280px;
  height: 179px;
  margin: 8px 0;
  border-radius: 4px;
  cursor: pointer;

  :hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 0 20px rgba(0, 0, 0, 0.1);
  }
  background: #f7f7f7;
`
const CreateAnalyticsCard = ({
  handleCreateNewAnalytics,
}: {
  handleCreateNewAnalytics: () => void
}) => {
  const { t } = useTranslation()

  const onClickCreateAnalytic = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    handleCreateNewAnalytics()
  }
  return (
    <CardStyle
      type="button"
      onClick={onClickCreateAnalytic}
      className="flex flex-col items-center justify-center gap-4"
    >
      <AddIcon
        className="stroke-2"
        style={{
          height: 24,
          width: 24,
          color: '#3B3B3B',
        }}
      />
      <TitleStyle>{t('createAnAnalytics')}</TitleStyle>
    </CardStyle>
  )
}

export default CreateAnalyticsCard
