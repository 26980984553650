import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { ReportLightResponseInterface } from '../../../interface/report/report-light-response.interface'
import { BookInterface } from '../../../interface/book.interface'
import { ReportInputTypeEnum } from '../../../enums/report-input-type.enum'
import { ReactComponent as WMFullIcon } from '../../../../assets/icons/app/work-mirror-full.icon.svg'
import { ReactComponent as UniversIcon } from '../../../../assets/icons/app/univers.icon.svg'
import { ReactComponent as ReadershipProfilIcon } from '../../../../assets/icons/readership-profil-card.icon.svg'
import { ReactComponent as ShareIcon } from '../../../../assets/icons/app/share.icon.svg'
import { ReactComponent as MoreIcon } from '../../../../assets/icons/app/more.icon.svg'
import { buildRelativePath, PathConfig } from '../../PathConfig'
import { useAppSelector } from '../../../../store/hooks'
import { selectWebAppStateCompanyUserId } from '../../../../store/getters/web-app.getters'
import AnalyticsDashboardMoreActionMenu from '../../../../components/analytics-dashboard/more-actions/AnalyticsDashboardMoreActionMenu'
import { AnalyticsTypeEnum } from '../../../enums/analytics-type.enum'
import { AnalyticsStatusEnum } from '../../../enums/analytics-status.enum'
import { ReactComponent as SharedIcon } from '../../../../assets/icons/app/shared.icon.svg'

const TitleStyle = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #252525;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

const ModifiedDateStyle = styled.div`
  color: #929292;
  /* Caption/C1 - Regular */
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
`
const CardStyle = styled.div(
  ({ width, height }: { width: number; height: number }) => `
  width: ${width}px;
  height: ${height}px;
  margin: 0px;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .more-action-btn {
    visibility: hidden;
  }

  .opacity-card {
    opacity : 1;
  }

  &:hover .more-action-btn {
    visibility: visible;
  }

  &:hover .opacity-card {
    opacity : 0.5;
    background-color: rgba(0, 0, 0, 0.5);
  }
`,
)

const AnalyticsSharedPanel = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
  padding: 1px 4px;
  gap: 4px;
  border-radius: 2px;
  background: #fff;
  z-index: 2;
`

const AnalyticsSharePanelText = styled.span`
  color: #252525;
  font-family: 'Fira Sans';
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
`

const OpacityBackground = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
`

const AnalyticsCard = ({
  analytic,
  handleReloadAction = undefined,
  width = 280,
  height = 179,
  sharedAnalytics = false,
}: {
  analytic: ReportLightResponseInterface
  handleReloadAction?: () => void
  width?: number
  height?: number
  sharedAnalytics?: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const companyUserId = useAppSelector(selectWebAppStateCompanyUserId)

  const getInputBook = (): any => {
    const input = analytic.inputs.find(
      (input: any) => input.type === ReportInputTypeEnum.BOOKS,
    )
    if (input) {
      return input.books
    }

    return []
  }

  const onClickCardButton = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    navigate(
      buildRelativePath([
        PathConfig.BOOKMETRIE,
        PathConfig.ANALYTICS,
        analytic.id,
      ]),
    )
  }

  return (
    <CardStyle
      className="relative flex flex-col justify-between"
      onClick={onClickCardButton}
      width={width}
      height={height}
    >
      {(analytic?.share_count > 0 &&
        analytic?.shared_by?.id === companyUserId && (
          <div
            className="absolute flex flex-row bg-white top-0 left-0"
            style={{
              zIndex: 3,
            }}
          >
            <ShareIcon className="w-4 h-4" />
            <span
              style={{
                textTransform: 'uppercase',
              }}
            >
              {t('shared')}
            </span>
          </div>
        )) ||
        null}
      {sharedAnalytics && (
        <AnalyticsSharedPanel className="more-action-btn absolute left-2 top-2">
          <div className="relative flex flex-row items-center justify-center gap-1 ">
            <SharedIcon className="rotate-180	" />
            <AnalyticsSharePanelText>
              {t('webApp.reports.shared.isShared')}
            </AnalyticsSharePanelText>
          </div>
        </AnalyticsSharedPanel>
      )}
      <AnalyticsDashboardMoreActionMenu
        className="more-action-btn absolute right-2 top-2"
        analytic={analytic}
        isOwner={analytic.owner?.id === companyUserId}
        handleReloadAction={handleReloadAction}
      />
      <div
        className="relative flex flex-row"
        style={{
          height: 128,
          width: '100%',
          borderRadius: 4,
          overflow: 'hidden',
          backgroundColor:
            analytic.type === AnalyticsTypeEnum.WORK_MIRROR
              ? '#86daad'
              : analytic.type === AnalyticsTypeEnum.READERSHIP_PROFILE
              ? analytic.status === AnalyticsStatusEnum.GENERATING
                ? '#666666'
                : '#7bbfff'
              : '#a3a6ff',
        }}
      >
        <OpacityBackground className="opacity-card" />
        <div className="absolute top-0 left-0 h-full w-full items-center justify-center flex">
          {(analytic.type === AnalyticsTypeEnum.WORK_MIRROR && (
            <WMFullIcon
              className="text-white"
              style={{
                width: 32,
                height: 32,
                zIndex: 1,
                backgroundColor: 'transparent',
              }}
            />
          )) ||
            null}

          {(analytic.type === AnalyticsTypeEnum.UNIVERS && (
            <UniversIcon
              className="text-white"
              style={{
                width: 32,
                height: 32,
                zIndex: 1,
                backgroundColor: 'transparent',
              }}
            />
          )) ||
            null}

          {(analytic.type === AnalyticsTypeEnum.READERSHIP_PROFILE &&
            analytic.status === AnalyticsStatusEnum.GENERATED && (
              <ReadershipProfilIcon
                className="text-white"
                style={{
                  width: 32,
                  height: 32,
                  zIndex: 1,
                  backgroundColor: 'transparent',
                }}
              />
            )) ||
            null}

          {(analytic.status === AnalyticsStatusEnum.GENERATING && (
            <div className="text-white flex flex-col items-center justify-center">
              <MoreIcon
                style={{
                  width: 32,
                  height: 32,
                  zIndex: 1,
                  backgroundColor: 'transparent',
                }}
              />
              <span>{t('Generating')}</span>
            </div>
          )) ||
            null}
        </div>
        {getInputBook().map((book: BookInterface, index: number) => (
          <img
            key={`image-book-card-${analytic.id}-${book.id_ean}-${index + 1}`}
            src={book.imgs}
            alt="Cover book"
            style={{
              width: 280 / getInputBook().length,
              height: 128,
              objectFit: 'cover',
              zIndex: 2,
            }}
          />
        ))}
      </div>
      <div className="flex flex-col mt-2 text-left">
        <TitleStyle>{analytic.name}</TitleStyle>
        <ModifiedDateStyle>
          {dayjs(analytic.modified).format('DD/MM/YYYY')}
        </ModifiedDateStyle>
      </div>
    </CardStyle>
  )
}

export default AnalyticsCard
