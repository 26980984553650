import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect, memo } from 'react'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import { ReactComponent as LittleArrowBottomArrowIcon } from '../../../../assets/icons/app/bottom.icon.svg'
import GlHDivider from '../../../share/GlHDivider'
import { RequestFiltersPeriodTypesConfig } from '../../../../core/config/audiences/request-filters-period-types.config'
import { PeriodTypeEnum } from '../../../../core/enums/period-type.enum'
import { ReactComponent as SelectedIcon } from '../../../../assets/icons/selected.icon.svg'
import AudiencesResultsPeriodDateType from './period/AudiencesResultsPeriodDateType'

const PeriodsFilterContainerStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #252525;
  width: 260px;
  height: 32px;
  border-radius: 4px;
  border-bottom: 1px solid #e3e3e3;
`
const LabelStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #252525;
  padding: 8px;
`

const MenuStyle = styled.div`
  z-index: 45;
  position: absolute;
  top: 36px;
  left: 0;
  height: ${({ open }: { open: boolean }) => (open ? 'auto' : '0')};
  max-height: ${({ open }: { open: boolean }) => (open ? '334px' : '0')};
  visibility: ${({ open }: { open: boolean }) => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }: { open: boolean }) => (open ? '1' : '0')};
  background: white;
  width: 100%;
  border-radius: 4px;
  // -webkit-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  // -moz-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  // -o-transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  // transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;
  padding-bottom: 8px;
`

const TitleSelectStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #b2b2b2;
  padding: 8px 12px;
`

const AudiencesResultsPeriodsFilters = ({
  periodTypeSelected,
  handleChangeQuery,
  startDateSelected,
  endDateSelected,
  menuKey,
  menuOpen,
  handleMenuOpen,
}: {
  periodTypeSelected: PeriodTypeEnum
  handleChangeQuery: (key: any, value: any) => void
  startDateSelected: string
  endDateSelected: string
  menuKey: string
  menuOpen: string | null
  handleMenuOpen: (value: string | null) => void
}) => {
  const { t } = useTranslation()

  let timer: NodeJS.Timeout | null = null
  const [needClose, setNeedClose] = useState<boolean>(false)
  const [hasHover, setHasHover] = useState<boolean>(false)

  const handleTimeOut = () => {
    timer = setTimeout(() => {
      if (menuOpen === menuKey) {
        handleMenuOpen(null)
      }
      setNeedClose(false)
    }, 300)
  }

  useEffect(() => {
    if (needClose && !hasHover) {
      handleTimeOut()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needClose, hasHover, timer, menuOpen])

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    handleMenuOpen(menuKey)
    setHasHover(true)
  }

  const handleMouseLeave = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setHasHover(false)
    setNeedClose(true)
  }

  return (
    <PeriodsFilterContainerStyle
      className="flex flex-col relative bg-white catalog-filter"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <LabelStyle className="w-full h-full flex flex-row justify-between items-center catalog-filter">
        <span>
          {t(`webApp.audiences.topRequest.period.${periodTypeSelected}`, {
            startDate: dayjs(startDateSelected).format('DD/MM/YYYY'),
            endDate: dayjs(endDateSelected).format('DD/MM/YYYY'),
            month: dayjs(startDateSelected).format('MMMM YYYY'),
            quarter: dayjs(startDateSelected).format('Q'),
            year: dayjs(startDateSelected).format('YYYY'),
          })}
        </span>
        <LittleArrowBottomArrowIcon className="text-[#b2b2b2]" />
      </LabelStyle>
      <MenuStyle
        open={menuOpen === menuKey}
        className="shadow-2xl z-45 catalog-filter"
      >
        <div className="catalog-filter">
          <TitleSelectStyle>PÉRIODE</TitleSelectStyle>
          <GlHDivider />
          {Object.keys(RequestFiltersPeriodTypesConfig).map((keyValue) => (
            <div
              key={nanoid(5)}
              className={`
                flex
                justify-between
                h-7
                items-center
                px-3
                py-[4.5px]
                ${
                  (periodTypeSelected ===
                    RequestFiltersPeriodTypesConfig[keyValue as PeriodTypeEnum]
                      .value &&
                    'bg-[#e3e3e3]') ||
                  ''
                }
                hover:bg-[#f7f7f7]
                active:bg-[#e3e3e3]
              `}
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.preventDefault()
                handleChangeQuery(
                  'period',
                  RequestFiltersPeriodTypesConfig[keyValue as PeriodTypeEnum]
                    .value,
                )
              }}
            >
              <span>
                {
                  RequestFiltersPeriodTypesConfig[keyValue as PeriodTypeEnum]
                    .label
                }
              </span>
              {periodTypeSelected ===
                RequestFiltersPeriodTypesConfig[keyValue as PeriodTypeEnum]
                  .value && <SelectedIcon />}
            </div>
          ))}
        </div>
        <GlHDivider />
        <div className="catalog-filter">
          <TitleSelectStyle>DATE</TitleSelectStyle>
          <GlHDivider />
          <AudiencesResultsPeriodDateType
            menuIsOpen={menuOpen === menuKey}
            periodTypeSelected={periodTypeSelected}
            startDateSelected={startDateSelected}
            endDateSelected={endDateSelected}
            handleChangeQuery={handleChangeQuery}
          />
        </div>
      </MenuStyle>
    </PeriodsFilterContainerStyle>
  )
}

export default memo(AudiencesResultsPeriodsFilters)
