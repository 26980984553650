import { NewReportInterface } from '../../interface/report/new-report.interface'
import { ReportTypeEnum } from '../../enums/report-type.enum'
import { ReportInputTypeEnum } from '../../enums/report-input-type.enum'
import { ReportOutputTypeEnum } from '../../enums/report-output-type.enum'

export const getReportBaseCreation = (
  reportType: ReportTypeEnum,
): NewReportInterface | null => {
  switch (reportType) {
    case ReportTypeEnum.WORK_MIRROR:
      return {
        name: 'Nom de l’Analytics',
        type: ReportTypeEnum.WORK_MIRROR,
        inputs: [
          {
            type: ReportInputTypeEnum.BOOKS,
            values: [],
            outputs: [
              {
                type: ReportOutputTypeEnum.WORK_MIRROR,
                work_mirror_books: [],
              },
            ],
          },
        ],
      }
    default:
      throw new Error('NEW_REPORT_REPORT_TYPE_UNDEFINED')
  }
}
