import dayjs from 'dayjs'
import styled from '@emotion/styled'
import GlBookTitle from '../../../../share/GlBookTitle'
import GlLabelIcon from '../../../../share/GlLabelIcon'
import { ReactComponent as AuthorIcon } from '../../../../../assets/icons/app/author.icon.svg'
import { ReactComponent as EditorIcon } from '../../../../../assets/icons/app/editor.icon.svg'
import { ReactComponent as FormatIcon } from '../../../../../assets/icons/app/format.icon.svg'
import { ReactComponent as ReleaseDateIcon } from '../../../../../assets/icons/app/date.icon.svg'

const SmallDividerStyle = styled.hr`
  border-top: 1px solid #595959;
  width: 30px;
  margin: 0;
`

const InfoStyle = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`

const LabelStyle = styled.span`
  font-family: 'Vollkorn', serif;
  flex: 1 1 0;
`

const AudienceCardDetails = ({
  title,
  strings,
  edition,
  format,
  date,
}: {
  title: string
  strings: string[]
  edition: string
  format: string
  date: string
}) => {
  return (
    <div className="flex flex-col gap-3 flex-1">
      <GlBookTitle title={title} />
      <SmallDividerStyle />
      <div className="flex flex-col">
        <InfoStyle>
          <GlLabelIcon>
            <AuthorIcon className="w-4 h-4 text-[#252525]" />
            <LabelStyle>{strings.join(', ')}</LabelStyle>
          </GlLabelIcon>
        </InfoStyle>
        <InfoStyle>
          <GlLabelIcon>
            <EditorIcon className="w-4 h-4 text-[#252525]" />
            <LabelStyle>{edition}</LabelStyle>
          </GlLabelIcon>
        </InfoStyle>
        <InfoStyle>
          <GlLabelIcon>
            <FormatIcon className="w-4 h-4 text-[#252525]" />
            <LabelStyle>{format}</LabelStyle>
          </GlLabelIcon>
        </InfoStyle>
        <InfoStyle>
          <GlLabelIcon>
            <ReleaseDateIcon className="w-4 h-4 text-[#252525]" />
            <LabelStyle>{dayjs(date).format('DD/MM/YY')}</LabelStyle>
          </GlLabelIcon>
        </InfoStyle>
      </div>
    </div>
  )
}

export default AudienceCardDetails
