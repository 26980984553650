export enum SourceEnum {
  CLIL = 'clil',
  AGE = 'age_range',
  BOOK_READ_COUNT = 'book_count_range',
  SUBJECT_OBJECT_VALUE = 'studyObjectValue',
  COMPARE_GROUP_VALUE = 'compareGroupValue',
  IPSOS_VALUE = 'ipsosValue',
  SCORE = 'score',
  PROPORTION_IN_LIBRARIES = 'adjusted_proportion_in_libraries',
  RATIO = 'ratio',
  RANK = 'rank',
  WORK_TITLE = 'glid.bestshot.title',
  WORK_AUTHORS = 'glid.bestshot.authors',
  WORK_CLIL = 'glid.bestshot.clil',
  WORK_FIRST_TAG_DATE = 'glid.first_tag_date',
  WORK_POPULARITY = 'glid.popularity',
  EAN_ID = 'ean_id',
  EAN_TITLE = 'ean_id.title',
  EAN_AUTHORS = 'ean_id.authors',
  EAN_EDITION = 'ean_id.edition',
  EAN_FIRST_TAG_DATE = 'ean_id.first_tag_date',
  EAN_CLIL = 'ean_id.clil',
  EAN_POPULARITY = 'ean_id.glid.popularity',
  AUTHOR_NAME = 'author_id.name',
  AUTHOR_FIRST_TAG_DATE = 'author_id.first_tag_date',
  AUTHOR_POPULARITY = 'author_id.popularity',
  CLIL_FIRST = 'clil1',
  CLIL_SECOND = 'clil2',

  // these are not used as source but can be present in the dataset
  PARENT_CLIL = 'parent_clil',
  WORK_BESTSHOT_ID = 'glid.bestshot.ean_id',
}
