import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from '../../../../assets/icons/app/add-icon-plain.icon.svg'
import { PaginationResponseInterface } from '../../../interface/pagination-response.interface'
import { ReportLightResponseInterface } from '../../../interface/report/report-light-response.interface'
import { AnalyticsTypeDashboardEnum } from '../../../../pages/webapp/AnalyticsDashboard'
import TableAnalytics from './TableAnalytics'
import { useAppSelector } from '../../../../store/hooks'
import { selectWebAppStateCompanyUserId } from '../../../../store/getters/web-app.getters'

const ButtonAddStyle = styled.button`
  color: #3b3b3b;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: #f7f7f7;
  height: 48px;
  width: 100%;
`
const AnalyticsDashboardListView = ({
  analytics,
  displayOnlyMyAnalytics,
  handleCreateNewAnalytics,
  analyticsTypeSelected,
  handleReloadAction,
}: {
  displayOnlyMyAnalytics: boolean
  analytics: PaginationResponseInterface<ReportLightResponseInterface> | null
  handleCreateNewAnalytics: () => void
  analyticsTypeSelected: AnalyticsTypeDashboardEnum
  handleReloadAction?: () => void
}) => {
  const { t } = useTranslation()
  const userCompanyId = useAppSelector(selectWebAppStateCompanyUserId)
  const onClickCreateAnalytic = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    handleCreateNewAnalytics()
  }
  return (
    <div className="flex-auto flex flex-col gap-4 justify-start items-center mx-4 gap-4">
      <ButtonAddStyle
        type="button"
        className="flex flex-row items-center justify-center rounded"
        onClick={onClickCreateAnalytic}
      >
        <AddIcon
          style={{
            width: 16,
            height: 16,
          }}
        />
        <span className="ml-2">{t('createAnAnalytics')}</span>
      </ButtonAddStyle>
      {(analytics && analytics.count > 0 && (
        <TableAnalytics
          analytics={
            analytics?.results
              .filter((analytic: ReportLightResponseInterface) =>
                displayOnlyMyAnalytics
                  ? analytic.owner?.id === userCompanyId
                  : analytic,
              )
              .filter((analytic: ReportLightResponseInterface) => {
                if (analyticsTypeSelected === AnalyticsTypeDashboardEnum.ALL) {
                  return analytic
                }
                return `${analytic.type}` === `${analyticsTypeSelected}`
              }) || []
          }
          handleReloadAction={handleReloadAction}
        />
      )) ||
        null}
    </div>
  )
}

export default AnalyticsDashboardListView
