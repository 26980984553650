import styled from '@emotion/styled'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { nanoid } from 'nanoid'
import AudiencesResultsFilterActionTag from '../filters/actions-filters/AudiencesResultsFilterActionTag'

const HeaderStyle = styled.div`
  -webkit-transition: max-height 300ms ease-in-out, visibility 100ms ease-in-out,
    opacity 100ms ease-in-out, padding 300ms ease-in-out;
  -moz-transition: max-height 300ms ease-in-out, visibility 100ms ease-in-out,
    opacity 100ms ease-in-out, padding 300ms ease-in-out;
  -o-transition: max-height 300ms ease-in-out, visibility 100ms ease-in-out,
    opacity 100ms ease-in-out, padding 300ms ease-in-out;
  transition: max-height 300ms ease-in-out, visibility 100ms ease-in-out,
    opacity 100ms ease-in-out, padding 300ms ease-in-out;
  max-height: ${({ open }: { open: boolean }) =>
    open ? '100px ' : 0} !important;
  visibility: ${({ open }: { open: boolean }) =>
    open ? 'visible ' : 'hidden'} !important;
  opacity: ${({ open }: { open: boolean }) => (open ? '1 ' : '0')} !important;
  padding: ${({ open }: { open: boolean }) =>
    open ? '16px ' : '0'} !important;
  background-color: rgba(255, 255, 255, 0.04);
`

const AudiencesResultsHeaderFiltersDetails = ({
  hasFilters,
  filtersActions,
  handleRemoveItemFilter,
  handleResetFilter,
}: {
  filtersActions: {
    [key: string]: string | Array<string | number | null> | null
  }
  hasFilters: boolean
  handleRemoveItemFilter: (columnKey: string, value: string) => void
  handleResetFilter: () => void
}) => {
  return (
    <HeaderStyle
      open={hasFilters}
      className="flex flex-row items-start justify-between gap-2  py-4 px-12"
    >
      <div className="overflow-auto h-auto max-h-[80px] flex-grow">
        <PerfectScrollbar className="w-full flex flex-row inline flex-wrap gap-2 justify-center items-center">
          {hasFilters &&
            Object.keys(filtersActions).map((columnKey: string) => (
              <AudiencesResultsFilterActionTag
                key={nanoid(5)}
                columnKey={columnKey}
                filtersActions={filtersActions}
                handleRemoveItemFilter={handleRemoveItemFilter}
              />
            ))}
        </PerfectScrollbar>
      </div>
    </HeaderStyle>
  )
}

export default AudiencesResultsHeaderFiltersDetails
