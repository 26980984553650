import React, { ReactElement } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from '@emotion/styled'
import GlReadMore from '../../../share/GlReadMore'
import { CommentInterface } from '../../../../core/interface/rank/book-detail.interface'

const TitleCommentStyle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 153.23%;
  color: #252525;
`

interface CardComment {
  title: string
  content: string
}

interface CommentData {
  id: string
  title: string
  content: string
}

const Comment = ({ title, content }: CardComment): ReactElement => {
  return (
    <div className="mr-2 mt-2 mb-4 ">
      <div className="min-w-[253px] min-h-32 bg-white rounded-lg p-2 gap-2 shadow-[0_4px_10px_rgba(0,0,0,0.08),0_0px_20px_rgba(0,0,0,0.1)]">
        <TitleCommentStyle className="font-bold text-base mb-2">
          {title}
        </TitleCommentStyle>
        <div className="text-xs">
          <GlReadMore content={content} nbShowsCharacters={100} />
        </div>
      </div>
    </div>
  )
}

interface Props {
  comments: CommentInterface[]
}

const AudiencesResultsCommentHorizontalList = ({ comments }: Props) => {
  return (
    <PerfectScrollbar>
      <div className="px-5 flex">
        {comments.map((ppl: CommentData) => (
          <Comment title={ppl.title} content={ppl.content} key={ppl.id} />
        ))}
      </div>
    </PerfectScrollbar>
  )
}

export default AudiencesResultsCommentHorizontalList
