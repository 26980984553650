import styled from '@emotion/styled'

const Pill = styled.div`
  font-family: 'Fira Mono', monospace;
  background: #7ab9ff;
  color: white;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  height: 16px;
  text-align: center;
  padding: 0 6px 0 6px;
  margin-left: 6px;
`
interface Props {
  rank: number
  color: string | undefined
}
const AudiencesResultsPill = ({ rank, color }: Props) => (
  <Pill
    style={{
      backgroundColor: color,
    }}
  >
    {rank}
  </Pill>
)
export default AudiencesResultsPill
