import React from 'react'
import { DatasetInterface } from '../../../../../../../../core/interface/analytics/dataset.interface'
import { SourceEnum } from '../../../../../../../../core/interface/analytics/source.enum'
import GlPopularityPanel from '../../../../../../../share/GlPopularityPanel'
import {
  TooltipAuthorStyle,
  TooltipRankStyle,
  TooltipTitleStyle,
} from '../../../../ReadershipProfileStyles'
import GlBadgeClilIcon from '../../../../../../../share/badge/GlBadgeClilIcon'

type Props = {
  dataset: DatasetInterface
  isAuthor: boolean
}

const ConstellationTooltip = ({ dataset, isAuthor }: Props) => {
  const getPopularity = () => {
    const popularity = isAuthor
      ? dataset[SourceEnum.AUTHOR_POPULARITY]
      : dataset[SourceEnum.WORK_POPULARITY]

    return popularity ? Math.floor((popularity * 5) / 100) : undefined
  }

  return (
    <div className="bg-white rounded p-2 shadow-lg gap-3 flex flex-col">
      <div className="flex items-center gap-1">
        {!isAuthor && (
          <GlBadgeClilIcon id={String(dataset[SourceEnum.CLIL_FIRST])} />
        )}
        <TooltipRankStyle>#{dataset[SourceEnum.RANK]}</TooltipRankStyle>
      </div>
      {isAuthor ? (
        <span className="font-semibold text-[#3B3B3B] text-sm">
          {dataset[SourceEnum.AUTHOR_NAME]}
        </span>
      ) : (
        <TooltipTitleStyle>
          {dataset[SourceEnum.WORK_TITLE]}
          <TooltipAuthorStyle>
            {dataset[SourceEnum.WORK_AUTHORS].length > 0 &&
              ` - ${dataset[SourceEnum.WORK_AUTHORS].join(', ')}`}
          </TooltipAuthorStyle>
        </TooltipTitleStyle>
      )}
      <GlPopularityPanel
        popularity={getPopularity()}
        percentage={`${Math.round(dataset[SourceEnum.SCORE] || 0)}%`}
      />
    </div>
  )
}

export default ConstellationTooltip
