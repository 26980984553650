import dayjs from 'dayjs'
import styled from '@emotion/styled'
import React from 'react'
import GlCellBody from '../../../../share/table/GlCellBody'
import AudiencesResultsPill from './AudiencesResultsPill'
import AudiencesResultsProgress from './AudiencesResultsProgress'
import AudiencesResultsTitle from './AudiencesResultsTitle'
import AudiencesResultsTableBodyNoData from './AudiencesResultsTableBodyNoData'
import AudiencesResultsTableSearchResult from './AudiencesResultsTableSearchResult'
import GlBadgeClilV2 from '../../../../share/badge/GlBadgeClilV2'
import GlLoader from '../../../../share/GlLoader'
import AudiencesResultsCellAuthors from './AudiencesResultsCellAuthors'
import { TrendV2Interface } from '../../../../../core/interface/trend-v2.interface'
import { TagFilterEnum } from '../../../../../core/enums/tag-filter.enum'

// TODO
const LineStyle = styled.div`
  &:not(.copy-paste-button):hover {
    background-color: #f7f7f7 !important;
  }

  &:not(.copy-paste-button):active {
    background-color: #e3e3e3 !important;
  }
`

const AudiencesResultsTableBodyV2 = ({
  data,
  isLoadingTrends,
  handleBookSelected,
  handleSetShowSnackBar,
  tagSelected,
  bookSelected,
}: {
  data: TrendV2Interface[]
  isLoadingTrends: boolean
  handleBookSelected: (book: TrendV2Interface | null) => void
  handleSetShowSnackBar: () => void
  tagSelected: TagFilterEnum
  bookSelected: TrendV2Interface | null
}) => {
  return (
    <div className="flex grow flex-col h-auto">
      <AudiencesResultsTableSearchResult itemCount={data.length} />
      {(isLoadingTrends && <GlLoader />) ||
        (data.length &&
          data.map((trend: TrendV2Interface, index: number) => (
            <LineStyle
              key={`audience-line-${trend.id_ean}`}
              style={{
                borderRadius: data.length === index + 1 ? '0 0 4px 4px' : '',
                backgroundColor:
                  bookSelected?.id_ean === trend.id_ean ? '#e3e3e3' : 'white',
              }}
              className={`bg-white flex flex-row  ${
                data.length > index + 1 ? 'border-b-2' : ''
              }`}
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation()
                handleBookSelected(trend)
              }}
            >
              <GlCellBody className="min-w-[62px] max-w-[62px]">
                <AudiencesResultsPill color="#252525" rank={trend.rank + 1} />
              </GlCellBody>
              <GlCellBody className="min-w-[82px] max-w-[82px]">
                <AudiencesResultsProgress progress={trend.delta_rank} />
              </GlCellBody>
              <GlCellBody className="min-w-[180px] max-w-[400px]">
                <AudiencesResultsTitle
                  title={trend.title}
                  isbn={trend.id_ean}
                  miniature={trend.imgs}
                  handleSetShowSnackBar={handleSetShowSnackBar}
                  clil1={trend.clil1}
                />
              </GlCellBody>
              <GlCellBody
                className="max-w-[240px]"
                /* labelValue={
                  (rank.authors &&
                    rank.authors.length &&
                    rank.authors.length > 1 &&
                    'ok') ||
                  rank.authors[0] ||
                  ''
                } */
              >
                <AudiencesResultsCellAuthors authors={trend.authors} />
              </GlCellBody>
              <GlCellBody
                className="min-w-[80px] max-w-[80px]"
                labelValue={dayjs(trend.publishing_date).format('DD/MM/YY')}
              />
              <GlCellBody
                className="min-w-[120px] max-w-[220px]"
                labelValue={trend.edition}
              />
              <GlCellBody className="max-w-[120px]">
                <GlBadgeClilV2
                  id={`${trend?.clil1}`}
                  smallFontSize
                  smallWidth
                />
              </GlCellBody>
              <GlCellBody className="max-w-[120px]">
                <GlBadgeClilV2
                  id={`${trend?.clil2}`}
                  smallFontSize
                  smallWidth
                />
              </GlCellBody>
              <GlCellBody className="max-w-[120px]" labelValue={trend.format} />
            </LineStyle>
          ))) || <AudiencesResultsTableBodyNoData />}
    </div>
  )
}

export default AudiencesResultsTableBodyV2
