import { PaginationResponseInterface } from '../../../interface/pagination-response.interface'
import { ReportLightResponseInterface } from '../../../interface/report/report-light-response.interface'
import AnalyticsCard from './AnalyticsCard'
import CreateAnalyticsCard from './CreateAnalyticsCard'
import { useAppSelector } from '../../../../store/hooks'
import { selectWebAppStateCompanyUserId } from '../../../../store/getters/web-app.getters'
import { AnalyticsTypeDashboardEnum } from '../../../../pages/webapp/AnalyticsDashboard'

const AnalyticsDashboardCardView = ({
  analytics,
  displayOnlyMyAnalytics,
  handleCreateNewAnalytics,
  analyticsTypeSelected,
  handleReloadAction = undefined,
}: {
  displayOnlyMyAnalytics: boolean
  analytics: PaginationResponseInterface<ReportLightResponseInterface> | null
  handleCreateNewAnalytics: () => void
  analyticsTypeSelected: AnalyticsTypeDashboardEnum
  handleReloadAction?: () => void
}) => {
  const userCompanyId = useAppSelector(selectWebAppStateCompanyUserId)

  return (
    <div className="absolute top-0 left-0 flex-auto flex flex-row flex-wrap gap-4 justify-center items-center w-full h-full">
      {(analytics && analytics?.count > 0 && (
        <>
          <CreateAnalyticsCard
            handleCreateNewAnalytics={handleCreateNewAnalytics}
          />
          {analytics.results
            .filter((analytic: ReportLightResponseInterface) =>
              displayOnlyMyAnalytics
                ? analytic.owner?.id === userCompanyId
                : analytic,
            )
            .filter((analytic: ReportLightResponseInterface) => {
              if (analyticsTypeSelected === AnalyticsTypeDashboardEnum.ALL) {
                return analytic
              }
              return `${analytic.type}` === `${analyticsTypeSelected}`
            })
            .map((analytic: ReportLightResponseInterface) => (
              <AnalyticsCard
                key={`analytics-card-${analytic.id}`}
                analytic={analytic}
                handleReloadAction={handleReloadAction}
                sharedAnalytics={analytic.owner?.id !== userCompanyId}
              />
            ))}
        </>
      )) || (
        <div className="flex items-center justify-center w-full h-full">
          <CreateAnalyticsCard
            handleCreateNewAnalytics={handleCreateNewAnalytics}
          />
        </div>
      )}
    </div>
  )
}

export default AnalyticsDashboardCardView
