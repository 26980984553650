import { DatasetInterface } from '../../../../../core/interface/analytics/dataset.interface'
import { DatasetModuleInterface } from '../../../../../core/interface/analytics/dataset-module.interface'
import { AnalyticsChartTypeEnum } from '../../../../../core/enums/analytics-chart-type.enum'
import { SetColorEnum } from '../../../../../core/enums/set-color.enum'
import AnalyticsOutputVerticalReCharts from './chart/AnalyticsOutputVerticalReCharts'
import AnalyticsOutputTabular from './tabular/AnalyticsOutputTabular'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../core/enums/analytic-output-layout-size.enum'
import AnalyticsOutputRadarRechart from './chart/AnalyticsOutputRadarRechart'
import AnalyticsOutputTreemapRechart from './chart/AnalyticsOutputTreemapRechart'
import AnalyticsOutputHorizontalReCharts from './chart/AnalyticsOutputHorizontalReCharts'
import { DatasetTypeEnum } from '../../../../../core/enums/dataset-type.enum'
import AnalyticsOutputScatterRecharts from './chart/AnalyticsOutputScatterRecharts'
import AnalyticsOutputVerticalStackedReCharts from './chart/AnalyticsOutputVerticalStackedReCharts'
import { AnalyticOutputTypeEnum } from '../../../../../core/enums/analytic-output-type.enum'
import { AnalyticModuleReplace } from '../../../../../core/interface/analytics/analytic-module-replace.interface'
import AnalyticsOutputConstellation from './chart/AnalyticsOutputConstellation'

const OutputChartTypeSwitch = ({
  display,
  dataType,
  dataset,
  datasetModule,
  level,
  onClickClil,
  setColor = SetColorEnum.SET_1,
  eanSelected,
  handleOpenBookDetails,
  isPreview = false,
  handleAnalyticsDeleteOutput,
  handleChangeDatasetOutput,
  parentId,
  isScreenSmall,
  isOrganizeMenuOpen,
  outputIdToScroll,
}: {
  display: AnalyticOutputLayoutSizeEnum
  dataType: DatasetTypeEnum
  dataset: DatasetInterface[]
  datasetModule: DatasetModuleInterface
  level?: number
  onClickClil?: (clil: number, lvl: number) => void
  setColor?: SetColorEnum
  eanSelected?: number | null
  handleOpenBookDetails?: (
    eanId: number | null,
    showBookDetail: boolean,
  ) => void
  isPreview?: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleChangeDatasetOutput?: (moduleToReplace: AnalyticModuleReplace) => void
  parentId?: number | null
  isScreenSmall?: boolean
  isOrganizeMenuOpen?: boolean
  outputIdToScroll?: string
}) => {
  const primaryKey = datasetModule.values_configurations.filter(
    (p) => p.is_primary_key,
  )[0].source

  const handleAnalyticsChangeOutput = () => {
    if (!handleChangeDatasetOutput) return
    handleChangeDatasetOutput({
      id: datasetModule.id,
      dataType,
      moduleType: datasetModule.type,
    })
  }

  switch (datasetModule.type) {
    case AnalyticsChartTypeEnum.VERTICAL_BAR_CHART:
      return (
        <AnalyticsOutputVerticalReCharts
          display={display}
          title={datasetModule.title}
          subTitle={datasetModule.subtitle}
          valuesConfigurations={datasetModule.values_configurations}
          setColor={setColor}
          dataset={dataset}
          datasetModule={datasetModule}
          typeDataset={dataType}
          isPreview={isPreview}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
          isOrganizeMenuOpen={isOrganizeMenuOpen}
          outputIdToScroll={outputIdToScroll}
          onClick={onClickClil}
          level={level}
        />
      )
    case AnalyticsChartTypeEnum.HORIZONTAL_BAR_CHART:
      return (
        <AnalyticsOutputHorizontalReCharts
          display={display}
          title={datasetModule.title}
          subTitle={datasetModule.subtitle}
          valuesConfigurations={datasetModule.values_configurations}
          setColor={setColor}
          dataset={dataset}
          datasetModule={datasetModule}
          typeDataset={dataType}
          isPreview={isPreview}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
          isOrganizeMenuOpen={isOrganizeMenuOpen}
          outputIdToScroll={outputIdToScroll}
          onClick={onClickClil}
          level={level}
        />
      )
    case AnalyticsChartTypeEnum.TABULAR:
      return (
        <AnalyticsOutputTabular
          display={display}
          title={datasetModule.title}
          subTitle={datasetModule.subtitle}
          dataset={dataset}
          valuesConfigurations={datasetModule.values_configurations}
          eanSelected={eanSelected}
          handleOpenBookDetails={handleOpenBookDetails}
          typeDataset={dataType}
          datasetModule={datasetModule}
          isPreview={isPreview}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
          isScreenSmall={isScreenSmall}
          isOrganizeMenuOpen={isOrganizeMenuOpen}
        />
      )
    case AnalyticsChartTypeEnum.TREEMAP:
      return (
        <AnalyticsOutputTreemapRechart
          display={display}
          title={datasetModule.title}
          subTitle={datasetModule.subtitle}
          dataset={dataset}
          level={level}
          onClick={onClickClil}
          typeDataset={dataType}
          valuesConfigurations={datasetModule.values_configurations}
          datasetModule={datasetModule}
          isPreview={isPreview}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
          isScreenSmall={isScreenSmall}
          isOrganizeMenuOpen={isOrganizeMenuOpen}
        />
      )
    case AnalyticsChartTypeEnum.RADAR:
      return (
        <AnalyticsOutputRadarRechart
          primaryKey={primaryKey}
          display={display}
          title={datasetModule.title}
          subTitle={datasetModule.subtitle}
          dataset={dataset}
          level={level}
          onClick={onClickClil}
          setColor={setColor}
          typeDataset={dataType}
          valuesConfigurations={datasetModule.values_configurations}
          datasetModule={datasetModule}
          isPreview={isPreview}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
          isScreenSmall={isScreenSmall}
          isOrganizeMenuOpen={isOrganizeMenuOpen}
        />
      )
    case AnalyticsChartTypeEnum.VERTICAL_STACKED_BAR_CHART:
      return (
        <AnalyticsOutputVerticalStackedReCharts
          display={display}
          title={datasetModule.title}
          subTitle={datasetModule.subtitle}
          valuesConfigurations={datasetModule.values_configurations}
          dataset={dataset}
          datasetModule={datasetModule}
          isPreview={isPreview}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
          parentId={parentId || null}
          typeDataset={dataType}
          isOrganizeMenuOpen={isOrganizeMenuOpen}
          setColor={setColor}
          outputIdToScroll={outputIdToScroll}
          onClick={onClickClil}
          level={level}
        />
      )
    case AnalyticsChartTypeEnum.BUBBLE_CHART:
    case AnalyticsChartTypeEnum.SCATTER_PLOT:
      return (
        <AnalyticsOutputScatterRecharts
          display={display}
          title={datasetModule.title}
          subTitle={datasetModule.subtitle}
          dataset={dataset}
          typeDataset={dataType}
          valuesConfigurations={datasetModule.values_configurations}
          datasetModule={datasetModule}
          isPreview={isPreview}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
          isScreenSmall={isScreenSmall}
          isOrganizeMenuOpen={isOrganizeMenuOpen}
          eanSelected={eanSelected}
          handleOpenBookDetails={handleOpenBookDetails}
        />
      )
    case AnalyticsChartTypeEnum.CONSTELLATION:
      return (
        <AnalyticsOutputConstellation
          display={display}
          title={datasetModule.title}
          subTitle={datasetModule.subtitle}
          dataset={dataset}
          typeDataset={dataType}
          valuesConfigurations={datasetModule.values_configurations}
          datasetModule={datasetModule}
          isPreview={isPreview}
          handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
          handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
          isScreenSmall={isScreenSmall}
          isOrganizeMenuOpen={isOrganizeMenuOpen}
          eanSelected={eanSelected}
          handleOpenBookDetails={handleOpenBookDetails}
        />
      )
    default:
      return null
  }
}

export default OutputChartTypeSwitch
