export const ApiPathConfig = {
  // AUTHENTICATION
  SIGN_IN: '/token/',
  REFRESH_TOKEN: '/token/refresh/',
  SIGN_UP: '',
  LOG_OUT: '',
  FORGOT_PASSWORD: '/password-reset/',
  FORGOT_PASSWORD_VALIDATE_TOKEN: '/password-reset/validate-token/',
  FORGOT_PASSWORD_CONFIRM: '/password-reset/confirm/',
  CHANGE_PASSWORD_WITH_TOKEN: (changePasswordToken: string) =>
    `/change-password/${changePasswordToken}/`,
  // TRENDS / CATALOGS / TOPS
  CATALOGS: '/top/catalogs/',
  TOP_HOME_CATALOGS: '/top/catalogs/home/',
  TOP_REQUESTS_SAVED: '/top/saved/',
  TOP_REQUESTS_SAVED_BY_ID: (requestId: string) => `/top/saved/${requestId}/`,
  TOPS: (catalogId: string) => `/top/${catalogId}/`,
  TOPS_BY_CATALOG_ID: (catalogId: string) => `/top/${catalogId}/`,
  TAGS_EVOLUTION: '/tag-evolution/',
  BOOK_DETAIL: (ean: string | number) => `v1/b2b/book/${ean}`,
  // TODO revoir son implementation
  BOOK_RECO_WITH_EANS: ({
    eanIds,
    nbrecos = undefined,
  }: {
    eanIds: number[]
    nbrecos?: number
  }) =>
    `/bookreco/?${eanIds
      .map((ean: string | number) => `eans=${ean}`)
      .join('&')}${(nbrecos !== undefined && `&nbrecos=${nbrecos}`) || ''}`,
  BOOK_RECO_V2_WITH_EANS: ({
    eanIds,
    nbrecos = undefined,
  }: {
    eanIds: number[]
    nbrecos?: number
  }) =>
    `/bookreco/v2/?${eanIds
      .map((ean: string | number) => `eans=${ean}`)
      .join('&')}${(nbrecos !== undefined && `&nbrecos=${nbrecos}`) || ''}`,
  BOOK_RECO_V3_WITH_EANS: ({
    eanIds,
    nbrecos = undefined,
  }: {
    eanIds: number[]
    nbrecos?: number
  }) =>
    `/bookreco/v3/?${eanIds
      .map((ean: string | number) => `eans=${ean}`)
      .join('&')}${(nbrecos !== undefined && `&nbrecos=${nbrecos}`) || ''}`,
  REPORTS: '/reports/',
  REPORTS_NEW: '/reports/new/',
  REPORT_DETAIL_BY_ID: (reportId: string | number) => `/reports/${reportId}/`,
  BOOK_DETAIL_BY_EAN_ID: (eanId: string | number) => `/book/${eanId}/`,
  BOOK_EDITIONS: (ean: string | number) => `/book/${ean}/editions`,
  BOOK_SEARCH: '/book/search/',
  USERS: '/users/',
  TOP_TAG_GROUPS: '/top/tag-groups/',
  TEAMS: '/teams/',
  REPORT_BY_ID_USERS: (reportId: string) => `/reports/${reportId}/users/`,
  REPORT_BY_ID_CHANGE_OWNER: (reportId: string) =>
    `/reports/${reportId}/change-owner/`,
  REPORT_BY_ID_USERS_BY_ID: (reportId: string, userId: string) =>
    `/reports/${reportId}/users/${userId}/`,
  REPORT_BY_ID_USERS_SHARE: (reportId: string) =>
    `/reports/${reportId}/users/share/`,
  SHARE_ANALYTIC_DETAILS_BY_SLUG: (slug: string) => `/reports/public/${slug}/`,
  TOP_HISTORY: '/top/history/',
  TOP_BY_CATALOG_ID_ACCESS: (catalogId: string) => `/top/${catalogId}/access/`,
  ONBOARDING_INVITATIONS_BY_TOKEN: (invitationToken: string) =>
    `/onboarding/invitations/${invitationToken}/`,
  ACCOUNTS_PROFILE: '/accounts/profile/',
  ACCOUNTS_CHANGE_PASSWORD: '/accounts/change-password/',
  MY_SELF: '/myself/',
  AUTHOR_SEARCH: '/author/search/',
  AUTHOR: '/author/',
  AUTHOR_BY_ID: (authorId: string) => `/author/${authorId}/`,
  BOOKS_EDITIONS: '/book/get/',
  ANALYTICS_PREVIEW: '/reports/readership-profile/preview/',
  ANALYTICS_CARD_PREVIEW: (reportId: string, cardId: string) =>
    `/reports/${reportId}/cards/${cardId}/`,
  REPORT_READERSHIP_PROFILE_BY_ID: (reportId: string) =>
    `/reports/readership-profile/${reportId}/`,
  REPORT_BY_ID_PURCHASE: (reportId: string) => `/reports/${reportId}/purchase/`,
  REPORT_BY_ID_STATUS: (reportId: string) => `/reports/${reportId}/status/`,
  REPORT_BY_ID_OUTPUT_BY_ID_DATASET_MODULES_ORDER: (
    reportId: string,
    outputId: string | undefined,
  ) => `/reports/${reportId}/output/${outputId}/modules/order/`,
  REPORT_BY_ID_INPUT_BY_ID_OUTPUTS_ORDER: (reportId: string, inputId: string) =>
    `/reports/${reportId}/inputs/${inputId}/order/`,
  TITLE_OUTPUT: (reportId: string, inputId: string) =>
    `/reports/${reportId}/inputs/${inputId}/titles/`,
  TITLE_OUTPUT_BY_ID: (reportId: string, inputId: string, outputId: string) =>
    `/reports/${reportId}/inputs/${inputId}/titles/${outputId}/`,
  TEXT_BOXES_OUTPUT: (reportId: string, inputId: string) =>
    `/reports/${reportId}/inputs/${inputId}/text-boxes/`,
  TEXT_BOXES_OUTPUT_BY_ID: (
    reportId: string,
    inputId: string,
    outputId: string,
  ) => `/reports/${reportId}/inputs/${inputId}/text-boxes/${outputId}/`,
  IMAGE_OUTPUT: (reportId: string, inputId: string) =>
    `/reports/${reportId}/inputs/${inputId}/images/`,
  IMAGE_OUTPUT_BY_ID: (reportId: string, inputId: string, outputId: string) =>
    `/reports/${reportId}/inputs/${inputId}/images/${outputId}/`,
  STUDY_OBJECT_OUTPUT: (reportId: string, inputId: string) =>
    `/reports/${reportId}/inputs/${inputId}/study-object/`,
  STUDY_OBJECT_OUTPUT_BY_ID: (
    reportId: string,
    inputId: string,
    outputId: string,
  ) => `/reports/${reportId}/inputs/${inputId}/study-object/${outputId}/`,
  AVAILABLE_MODULES: (reportId: string) =>
    `/reports/${reportId}/available-modules/`,
  DATASET_MODULES: (reportId: string, outputId: string) =>
    `/reports/${reportId}/output/${outputId}/modules/`,
  DATASET_MODULES_BY_ID: (
    reportId: string,
    datasetId: string,
    datasetModuleId: string,
  ) => `/reports/${reportId}/output/${datasetId}/modules/${datasetModuleId}/`,
  REPORT_BY_ID_INPUT_BY_ID_READERSHIP_PROFILE: (
    reportId: string,
    inputId: string,
  ) => `/reports/${reportId}/inputs/${inputId}/readership-profile/`,
}
