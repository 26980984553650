import styled from '@emotion/styled'

export const TitleSelectionStyle = styled.div`
  overflow: hidden;
  color: #252525;
  text-overflow: ellipsis;
  font-family: Vollkorn, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
`

export const DatasetTitleSectionStyle = styled.div`
  overflow: hidden;
  color: #3b3b3b;
  text-overflow: ellipsis;
  font-family: Vollkorn, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
`

export const DatasetTitleStyle = styled.div`
  overflow: hidden;
  color: #252525;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const DatasetSubTitleStyle = styled.div`
  overflow: hidden;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const SubTitleSelectionStyle = styled.div`
  overflow: hidden;
  color: #929292;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const InputTypeClilCardStyle = styled.div`
  height: 48px;
  background: #252525;
  border-radius: 4px;
  overflow: hidden;
  color: white;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

export const InputStyle = styled.input`
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  overflow: hidden;
  color: #929292;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 142.857% */
  padding: 10px 64px 10px 10px;

  :focus {
    outline: none;
  }
`

export const ClilMenuStyle = styled.div`
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
  max-height: 264px;
`

export const TitleBookStyle = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${({ color = '#252525' }: { color?: string }) => color};
  font-family: Vollkorn, serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.28px;
`

export const TitleBookTableRowStyle = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${({ color = '#3b3b3b' }: { color?: string }) => color};
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-family: Vollkorn, serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.28px;
`

export const AuthorBookStyle = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${({ color = '#929292' }: { color?: string }) => color};
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.24px;
`

export const AuthorBookTableRowStyle = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${({ color = '#929292' }: { color?: string }) => color};
  font-family: Fira Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 140% */
  letter-spacing: 0.2px;
`

export const TableCellStyle = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

export const TooltipTitleStyle = styled.span`
  color: #3b3b3b;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
`

export const TooltipAuthorStyle = styled.span`
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

export const TooltipRankStyle = styled.span`
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #929292;
`
