import styled from '@emotion/styled'
import GlSlideToggle from './GlSlideToggle'
import { ReactComponent as InfoIcon } from '../../assets/icons/info-icon.svg'

const TextToggleStyle = styled.span`
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-family: 'Fira Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

const GlSlideToggleWithText = ({
  id,
  label,
  value = true,
  infoTooltip = false,
  handleOnToggle,
  textCustomStyle,
}: {
  id: string
  label: string
  value: boolean
  infoTooltip?: boolean
  handleOnToggle: (id: string, value: boolean) => void
  textCustomStyle?: React.CSSProperties
}) => {
  return (
    <div className="flex justify-between items-center self-stretch">
      {infoTooltip ? (
        <div className="flex items-center gap-2">
          <TextToggleStyle style={textCustomStyle}>{label}</TextToggleStyle>
          <InfoIcon className="h-3 w-3" />
        </div>
      ) : (
        <TextToggleStyle style={textCustomStyle}>{label}</TextToggleStyle>
      )}
      <GlSlideToggle
        enable={value}
        handleOnToggle={(value) => handleOnToggle(id, value)}
        activeColor="#41AFED"
      />
    </div>
  )
}

export default GlSlideToggleWithText
