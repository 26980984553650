import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { ModuleTabularConfig, TabularColumnConfigType } from './TabularConfig'
import { ReactComponent as CopyPasteIcon } from '../../../../../../assets/icons/app/copy-paste.icon.svg'
import { setShowSnackBar } from '../../../../../../store/reducers/web-app.reducer'
import { useAppDispatch } from '../../../../../../store/hooks'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'
import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'
import { PercentageNumber } from '../../../../../share/PercentageNumber'
import { clilReferencesConfig } from '../../../../../../core/config/clil-references.config'

const Badge = styled.span`
  font-family: 'Fira Mono', monospace;
  background: ${({ color }: { color?: string }) => color};
  color: ${({ color }: { color?: string }) =>
    color === '#252525' ? 'white' : '#3B3B3B'};
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  height: 20px;
  width: 40px;
  text-align: center;
  align-items: center;
  line-height: 20px;
`

const EANStyle = styled.span`
  color: #3b3b3b;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

const DefaultStyle = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #3b3b3b;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const CellTitleStyle = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #3b3b3b;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.32px;
`

const AnalyticsOutputBodyTabular = ({
  data,
  valuesConfigurations,
  eanSelected,
  handleOpenBookDetails,
  typeDataset,
}: {
  data: DatasetInterface
  valuesConfigurations: ValueConfigurationsInterface[]
  eanSelected?: number | null
  handleOpenBookDetails?: (
    eanId: number | null,
    showBookDetail: boolean,
  ) => void
  typeDataset: DatasetTypeEnum
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onClickTitleCard = (eanId: number) => {
    handleOpenBookDetails &&
      handleOpenBookDetails(
        eanSelected === eanId ? null : eanId,
        typeDataset !== DatasetTypeEnum.WORKS_MIRROR,
      )
  }
  const tabularConfig = ModuleTabularConfig(valuesConfigurations)
  return (
    <div
      className={`flex flex-row p-2 gap-2 h-10 ${
        data[SourceEnum.EAN_ID] || data[SourceEnum.WORK_BESTSHOT_ID]
          ? 'cursor-pointer'
          : ''
      }`}
      style={{
        borderRadius: 12,
      }}
    >
      {valuesConfigurations
        .filter(
          (c) =>
            c.is_visible &&
            c.source !== SourceEnum.WORK_CLIL &&
            c.source !== SourceEnum.EAN_CLIL,
        )
        .map((valueConfiguration) => {
          const keyValue = valueConfiguration.source
          const columnConfig = tabularConfig[
            keyValue
          ] as TabularColumnConfigType
          const value = data[keyValue]
          const color =
            (data.clil1 && clilReferencesConfig[data.clil1].color) || '#252525'

          return (
            <div
              key={keyValue}
              className={`flex flex-row items-center justify-start gap-2 p-1 ${
                keyValue === SourceEnum.EAN_TITLE ||
                keyValue === SourceEnum.WORK_TITLE
                  ? 'flex-1'
                  : ''
              }`}
              style={{
                ...{
                  ...((keyValue !== SourceEnum.EAN_TITLE &&
                    keyValue !== SourceEnum.WORK_TITLE && {
                      minWidth: columnConfig?.minWidth,
                      maxWidth: columnConfig?.maxWidth,
                    }) || {
                    flex: 1,
                  }),
                },
              }}
              onClick={(event) => {
                event.preventDefault()
                const ean =
                  data[SourceEnum.EAN_ID] || data[SourceEnum.WORK_BESTSHOT_ID]
                ean && onClickTitleCard(ean)
              }}
            >
              {(columnConfig?.type === 'badge' && (
                <Badge color={color}>{value as string}</Badge>
              )) ||
                (columnConfig?.type === 'percent' && (
                  <span>
                    <PercentageNumber value={value as number} />
                  </span>
                )) ||
                (columnConfig?.type === 'authors' && (
                  <span title={(value as string[])[0] || ''}>
                    {(value as string[])[0] || ''}
                  </span>
                )) ||
                (columnConfig?.type === 'title' && (
                  <CellTitleStyle title={value?.toString()}>
                    {value}
                  </CellTitleStyle>
                )) ||
                (columnConfig?.type === 'ean' && (
                  <>
                    <EANStyle>{value}</EANStyle>
                    <button
                      type="button"
                      className="text-[#25252566] hover:text-[#25252599] active:text-black"
                      onClick={(event: React.MouseEvent) => {
                        event.stopPropagation()
                        navigator.clipboard
                          .writeText(String(value))
                          .then(() => {
                            dispatch(
                              setShowSnackBar(t('snackbarMessage.copied')),
                            )
                          })
                          .catch(() => {})
                      }}
                    >
                      <CopyPasteIcon />
                    </button>
                  </>
                )) || (
                  <DefaultStyle title={value?.toString()}>{value}</DefaultStyle>
                )}
            </div>
          )
        })}
    </div>
  )
}

export default AnalyticsOutputBodyTabular
