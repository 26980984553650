import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import AudiencesSearchCatalogsMenu from './AudiencesSearchCatalogsMenu'
import AudiencesSearchTagsMenu from './AudiencesSearchTagsMenu'
import { AgeFilterEnum } from '../../core/enums/age-filter.enum'
import { GenderFilterEnum } from '../../core/enums/gender-filter.enum'
import AudiencesResultsReadersFilters from '../dynamics-results/filters/request-filters/AudiencesResultsReadersFilters'
import AudiencesResultsPeriodsFilters from '../dynamics-results/filters/request-filters/AudiencesResultsPeriodsFilters'
import { PeriodTypeEnum } from '../../core/enums/period-type.enum'
import { getPeriodByPeriodTypeUtil } from '../../core/utils/get-period-by-period-type.util'
import { TagGroupInterface } from '../../core/interface/TagGroupInterface'

const ContainerStyle = styled.div`
  /* mask: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.08) 0%,
    #d9d9d9 5.53%,
    #d9d9d9 63.94%,
    rgba(217, 217, 217, 0.08) 100%
  ); */
`
const LabelStyle = styled.span`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16.5px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #252525;
  height: 32px;
  white-space: nowrap;
`

const AudiencesSearchFilters = ({
  catalogs,
  catalogSelected,
  setCatalogSelected,
  setAgeSelected,
  setGenderSelected,
  setTagGroupSelected,
  ageSelected,
  genderSelected,
  tagGroupSelected,
  tagGroups,
  periodTypeSelected,
  setPeriodTypeSelected,
  dateSelected,
  setDateSelected,
}: {
  catalogs: CatalogInterface[]
  catalogSelected: any
  setCatalogSelected: (catalog: CatalogInterface) => void
  tagGroupSelected: string
  tagGroups: TagGroupInterface[]
  setTagGroupSelected: (value: string) => void
  ageSelected: any
  setAgeSelected: (value: any) => void
  genderSelected: any
  setGenderSelected: (value: any) => void
  periodTypeSelected: any
  setPeriodTypeSelected: (value: any) => void
  dateSelected: any
  setDateSelected: (value: any) => void
}) => {
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState<string | null>(null)

  const handleChangeQuery = (key: string, value: any) => {
    if (key === 'period') {
      setPeriodTypeSelected(value)
      const { start, end } = getPeriodByPeriodTypeUtil(value)
      setDateSelected({
        startDate: start,
        endDate: end,
      })
    }
    if (key === 'date') {
      const { start, end } = value
      setDateSelected({
        startDate: start,
        endDate: end,
      })
    }
    if (key === 'gender') {
      setGenderSelected(value)
    }
    if (key === 'age') {
      setAgeSelected(value)
    }
  }

  useEffect(() => {
    if (
      periodTypeSelected === PeriodTypeEnum.DAY ||
      periodTypeSelected === PeriodTypeEnum.WEEK
    ) {
      setAgeSelected(AgeFilterEnum.AGE_CATEGORY_ALL)
      setGenderSelected(GenderFilterEnum.SEX_CATEGORY_ALL)
    }
  }, [periodTypeSelected])

  return (
    <div className="flex flex-col justify-between h-full">
      <ContainerStyle className="flex flex-row pt-6 px-12 gap-2 overflow-hidden h-full">
        <LabelStyle>{t('webApp.audiences.topRequest.the')}</LabelStyle>
        <AudiencesSearchCatalogsMenu
          catalogs={catalogs}
          catalogSelected={catalogSelected}
          handleChangeSelected={setCatalogSelected}
        />

        <LabelStyle>{t('webApp.audiences.topRequest.the_most')}</LabelStyle>

        <AudiencesSearchTagsMenu
          tagGroups={tagGroups}
          tagGroupSelected={tagGroupSelected}
          handleChangeSelected={setTagGroupSelected}
        />

        <AudiencesResultsPeriodsFilters
          periodTypeSelected={periodTypeSelected}
          handleChangeQuery={handleChangeQuery}
          startDateSelected={dateSelected.startDate}
          endDateSelected={dateSelected.endDate}
          menuKey="PERIODS"
          menuOpen={menuOpen}
          handleMenuOpen={setMenuOpen}
        />

        <LabelStyle>{t('webApp.audiences.topRequest.by')}</LabelStyle>

        <AudiencesResultsReadersFilters
          disabled={
            periodTypeSelected === PeriodTypeEnum.DAY ||
            periodTypeSelected === PeriodTypeEnum.WEEK
          }
          ageSelected={ageSelected}
          genderSelected={genderSelected}
          handleChangeQuery={handleChangeQuery}
          menuKey="READERS"
          menuOpen={menuOpen}
          handleMenuOpen={setMenuOpen}
        />
      </ContainerStyle>
    </div>
  )
}

export default AudiencesSearchFilters
