import { ReactElement } from 'react'
import styled from '@emotion/styled'
import AnalyticDetailsHeaderLayout from './AnalyticDetailsHeaderLayout'
import { AnalyticDetailsInterface } from '../../core/interface/analytics/analytic-details.interface'
import GlLoader from '../share/GlLoader'

const LayoutStyle = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
`

const AnalyticDetailsLayout = ({
  isLoading,
  hasButtonPrevious,
  analyticDetails,
  children,
  handleExitAnalyticDetails,
}: {
  isLoading: boolean
  hasButtonPrevious: boolean
  analyticDetails: AnalyticDetailsInterface
  children: ReactElement
  handleExitAnalyticDetails: (toExit?: boolean) => void
}) => {
  return (
    <LayoutStyle>
      <AnalyticDetailsHeaderLayout
        handleExitAnalyticDetails={handleExitAnalyticDetails}
        analyticDetails={analyticDetails}
        isLoading={isLoading}
        hasButtonPrevious={hasButtonPrevious}
      />
      {(isLoading && (
        <div className="w-full h-full flex items-center justify-center">
          <GlLoader />
        </div>
      )) ||
        children}
    </LayoutStyle>
  )
}

export default AnalyticDetailsLayout
