import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { shareAnalyticDetailsGetShareAnalyticBySlugAction } from '../../store/actions/share-analytic-details.actions'
import {
  selectShareAnalyticDetailsStateIsLoadingGetShareAnalyticDetails,
  selectShareAnalyticDetailsStateAnalyticDetails,
  selectShareAnalyticDetailsStateGetShareAnalyticDetailsSuccess,
  selectShareAnalyticDetailsStateGetShareAnalyticDetailsError,
} from '../../store/getters/share-analytic-details.getters'
import SharedHeader from '../../components/layouts/SharedHeader'
import GlLoader from '../../components/share/GlLoader'
import {
  selectWebAppStateAccessToken,
  selectWebAppStateMySelfHasAnalyticsAccess,
  selectWebAppStateRefreshToken,
} from '../../store/getters/web-app.getters'
import { AnalyticsTypeEnum } from '../../core/enums/analytics-type.enum'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import {
  reinitializeShareAnalyticDetailsState,
  resetShareAnalyticDetailsStatus,
} from '../../store/reducers/share-analytic-details.reducer'
import ShareAnalyticDetailsSwitchView from '../../components/share-analytic-details/view/ShareAnalyticDetailsSwitchView'

const LayoutStyle = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
`

const ShareAnalyticDetailsView = () => {
  const { t } = useTranslation()
  const { slugId } = useParams<{ slugId: string }>()
  const dispatch = useAppDispatch()

  const isLoadingGetShareAnalyticDetails = useAppSelector(
    selectShareAnalyticDetailsStateIsLoadingGetShareAnalyticDetails,
  )
  const analyticDetails = useAppSelector(
    selectShareAnalyticDetailsStateAnalyticDetails,
  )
  const getShareAnalyticDetailsSuccess = useAppSelector(
    selectShareAnalyticDetailsStateGetShareAnalyticDetailsSuccess,
  )
  const getShareAnalyticDetailsError = useAppSelector(
    selectShareAnalyticDetailsStateGetShareAnalyticDetailsError,
  )

  const accessToken = useAppSelector(selectWebAppStateAccessToken)
  const refreshToken = useAppSelector(selectWebAppStateRefreshToken)
  const hasAnalyticsAccess = useAppSelector(
    selectWebAppStateMySelfHasAnalyticsAccess,
  )

  useEffect(() => {
    void dispatch(
      shareAnalyticDetailsGetShareAnalyticBySlugAction(slugId as string),
    )
    return () => {
      dispatch(reinitializeShareAnalyticDetailsState())
    }
  }, [slugId])

  useEffect(() => {
    if (getShareAnalyticDetailsError) {
      dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
    }

    if (getShareAnalyticDetailsError || getShareAnalyticDetailsSuccess) {
      dispatch(resetShareAnalyticDetailsStatus())
    }
  }, [getShareAnalyticDetailsSuccess, getShareAnalyticDetailsError])
  if (isLoadingGetShareAnalyticDetails) {
    return (
      <div className="flex-auto w-full h-full flex flex-col justify-center items-center">
        <GlLoader />
      </div>
    )
  }

  const isLoggedIn = (): boolean => {
    return !!accessToken && !!refreshToken
  }

  return (
    analyticDetails && (
      <LayoutStyle>
        <SharedHeader
          isLoggedIn={isLoggedIn()}
          hasAnalyticsAccess={hasAnalyticsAccess}
          analyticType={analyticDetails?.type}
          analyticName={analyticDetails?.name}
        />
        {(analyticDetails?.type && (
          <ShareAnalyticDetailsSwitchView type={analyticDetails?.type} />
        )) || (
          <div className="flex items-center justify-center w-full h-full">
            <div>{t('webApp.reports.noReportAvailable')}</div>
          </div>
        )}
      </LayoutStyle>
    )
  )
}

export default ShareAnalyticDetailsView
