import styled from '@emotion/styled'

const RankStyle = styled.span`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  color: #ffffff;
`

const AudienceBillRankPosition = ({ rank }: { rank: number }) => (
  <RankStyle className="flex">{rank + 1}</RankStyle>
)
export default AudienceBillRankPosition
