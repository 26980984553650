import { RootState } from '../../store'
import { AnalyticDetailsInterface } from '../../../core/interface/analytics/analytic-details.interface'
import { AnalyticsTypeEnum } from '../../../core/enums/analytics-type.enum'
import { AnalyticInputInterface } from '../../../core/interface/analytics/analytic-input.interface'
import { AnalyticInputTypeEnum } from '../../../core/enums/analytic-input-type.enum'
import { BookInterface } from '../../../core/interface/book.interface'
import {
  AnalyticOutputInterface,
  DatasetOutputInterface,
} from '../../../core/interface/analytics/analytic-output.interface'
import { AnalyticOutputTypeEnum } from '../../../core/enums/analytic-output-type.enum'
import { WorkMirrorBookInterface } from '../../../core/interface/report/work-mirror-book.interface'
import { BookDetailResponseInterface } from '../../../core/interface/rank/book-detail.interface'
import { AnalyticAvailableModulesInterface } from '../../../core/interface/analytics/analytic-available-modules.interface'
import { DatasetModuleInterface } from '../../../core/interface/analytics/dataset-module.interface'
import { AnalyticsPermissionEnum } from '../../../core/enums/analytics-permission.enum'

export const selectAnalyticDetailsStateIsLoadingGetAnalyticDetails = (
  state: RootState,
): boolean => {
  const { isLoadingGetAnalyticDetails } = state.analyticDetailsV2State
  return isLoadingGetAnalyticDetails
}
export const selectAnalyticDetailsStateAnalyticDetailsType = (
  state: RootState,
): AnalyticsTypeEnum => {
  const { analyticDetails } = state.analyticDetailsV2State
  return analyticDetails?.type
}

export const selectAnalyticDetailsStateAnalyticDetails = (
  state: RootState,
): AnalyticDetailsInterface => {
  const { analyticDetails } = state.analyticDetailsV2State
  return analyticDetails
}
export const selectAnalyticDetailsStateGetAnalyticDetailsSuccess = (
  state: RootState,
): boolean => {
  const { getAnalyticDetailsSuccess } = state.analyticDetailsV2State
  return getAnalyticDetailsSuccess
}

export const selectAnalyticDetailsStateGetAnalyticDetailsError = (
  state: RootState,
): boolean => {
  const { getAnalyticDetailsError } = state.analyticDetailsV2State
  return getAnalyticDetailsError
}

export const selectAnalyticDetailsStateAnalyticDetailsBooksInput = (
  state: RootState,
): BookInterface[] => {
  const { analyticDetails } = state.analyticDetailsV2State
  return (
    (analyticDetails?.inputs || []).find(
      (input: AnalyticInputInterface) =>
        input.type === AnalyticInputTypeEnum.BOOKS,
    )?.books || []
  )
}

export const selectAnalyticDetailsStateAnalyticDetailsBooksInputValues = (
  state: RootState,
): string[] => {
  const { analyticDetails } = state.analyticDetailsV2State
  return (
    (analyticDetails?.inputs || []).find(
      (input: AnalyticInputInterface) =>
        input.type === AnalyticInputTypeEnum.BOOKS,
    )?.values || []
  )
}

export const selectAnalyticDetailsStateAnalyticDetailsBooksOutput = (
  state: RootState,
): WorkMirrorBookInterface[] => {
  const { analyticDetails } = state.analyticDetailsV2State
  const input = (analyticDetails?.inputs || []).find(
    (input: AnalyticInputInterface) =>
      input.type === AnalyticInputTypeEnum.BOOKS,
  )
  if (input) {
    return (
      input.outputs.find(
        (output: AnalyticOutputInterface) =>
          output.type === AnalyticOutputTypeEnum.WORK_MIRROR,
      )?.work_mirror_books || []
    )
  }

  return []
}

export const selectAnalyticDetailsStateAnalyticDetailsOutputs = (
  state: RootState,
): AnalyticOutputInterface[] => {
  const { analyticDetails } = state.analyticDetailsV2State
  const input = (analyticDetails?.inputs || []).find(
    (input: AnalyticInputInterface) =>
      input.type === AnalyticInputTypeEnum.READERSHIP_PROFILE,
  )
  if (input) {
    return input.outputs
  }

  return []
}

export const selectAnalyticDetailsStateAnalyticDetailsDatasetOutput = (
  state: RootState,
): DatasetOutputInterface[] => {
  const { analyticDetails } = state.analyticDetailsV2State
  const input = (analyticDetails?.inputs || []).find(
    (input: AnalyticInputInterface) =>
      input.type === AnalyticInputTypeEnum.READERSHIP_PROFILE,
  )
  if (input) {
    return (
      input.outputs
        .filter(
          (output: AnalyticOutputInterface) =>
            output.type === AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET,
        )
        .map((output: AnalyticOutputInterface) => output.dataset_output) || []
    )
  }

  return []
}

export const selectAnalyticDetailsStateIsLoadingGetBookRecoV3 = (
  state: RootState,
): boolean => {
  const { isLoadingGetBookRecoV3Add, isLoadingGetBookRecoV3Remove } =
    state.analyticDetailsV2State
  return isLoadingGetBookRecoV3Add || isLoadingGetBookRecoV3Remove
}

export const selectAnalyticDetailsStateIsLoadingGetBookRecoV3Add = (
  state: RootState,
): boolean => {
  const { isLoadingGetBookRecoV3Add } = state.analyticDetailsV2State
  return isLoadingGetBookRecoV3Add
}

export const selectAnalyticDetailsStateIsLoadingGetBookRecoV3Remove = (
  state: RootState,
): boolean => {
  const { isLoadingGetBookRecoV3Remove } = state.analyticDetailsV2State
  return isLoadingGetBookRecoV3Remove
}

export const selectAnalyticDetailsStateGetBookRecoV3Success = (
  state: RootState,
): boolean => {
  const { getBookRecoV3Success } = state.analyticDetailsV2State
  return getBookRecoV3Success
}

export const selectAnalyticDetailsStateGetBookRecoV3Error = (
  state: RootState,
): boolean => {
  const { getBookRecoV3Error } = state.analyticDetailsV2State
  return getBookRecoV3Error
}

export const selectAnalyticDetailsStateIsLoadingPostNewAnalytic = (
  state: RootState,
): boolean => {
  const { isLoadingPostNewAnalytic } = state.analyticDetailsV2State
  return isLoadingPostNewAnalytic
}
export const selectAnalyticDetailsStatePostNewAnalyticSuccess = (
  state: RootState,
): boolean => {
  const { postNewAnalyticSuccess } = state.analyticDetailsV2State
  return postNewAnalyticSuccess
}
export const selectAnalyticDetailsStatePostNewAnalyticError = (
  state: RootState,
): boolean => {
  const { postNewAnalyticError } = state.analyticDetailsV2State
  return postNewAnalyticError
}

export const selectAnalyticDetailsStateNeedSaveAnalyticDetails = (
  state: RootState,
): boolean => {
  const { needSaveAnalyticDetails } = state.analyticDetailsV2State
  return needSaveAnalyticDetails
}

export const selectAnalyticDetailsStateIsLoadingPutAnalytic = (
  state: RootState,
): boolean => {
  const { isLoadingPutAnalytic } = state.analyticDetailsV2State
  return isLoadingPutAnalytic
}
export const selectAnalyticDetailsStatePutAnalyticSuccess = (
  state: RootState,
): boolean => {
  const { putAnalyticSuccess } = state.analyticDetailsV2State
  return putAnalyticSuccess
}
export const selectAnalyticDetailsStatePutAnalyticError = (
  state: RootState,
): boolean => {
  const { putAnalyticError } = state.analyticDetailsV2State
  return putAnalyticError
}

export const selectAnalyticDetailsStateBookDetailsSelected = (
  state: RootState,
): WorkMirrorBookInterface | null => {
  const { bookDetailsSelected } = state.analyticDetailsV2State
  return bookDetailsSelected
}
export const selectAnalyticDetailsStateIsLoadingGetBookDetails = (
  state: RootState,
): boolean => {
  const { isLoadingGetBookDetails } = state.analyticDetailsV2State
  return isLoadingGetBookDetails
}

export const selectAnalyticDetailsStateIsLoadingGetEditions = (
  state: RootState,
): boolean => {
  const { isLoadingGetEditions } = state.analyticDetailsV2State
  return isLoadingGetEditions
}

export const selectAnalyticDetailsStateBookDetailsSelectedEditions = (
  state: RootState,
): BookInterface[] => {
  const { bookDetailsSelectedEditions } = state.analyticDetailsV2State
  return bookDetailsSelectedEditions
}

export const selectAnalyticDetailsStateIsLoadingGetB2BBookDetails = (
  state: RootState,
): boolean => {
  const { isLoadingGetB2BBookDetails } = state.analyticDetailsV2State
  return isLoadingGetB2BBookDetails
}

export const selectAnalyticDetailsStateBookDetailsSelectedB2BInfo = (
  state: RootState,
): BookDetailResponseInterface => {
  const { bookDetailsSelectedB2BInfo } = state.analyticDetailsV2State
  return bookDetailsSelectedB2BInfo
}

export const selectAnalyticDetailsStateIsLoadingBookDetailsGetBookReco = (
  state: RootState,
): boolean => {
  const { isLoadingBookDetailsGetBookReco } = state.analyticDetailsV2State
  return isLoadingBookDetailsGetBookReco
}

export const selectAnalyticDetailsStateBookDetailsBookReco = (
  state: RootState,
): BookInterface[] => {
  const { bookDetailsBookReco } = state.analyticDetailsV2State
  return bookDetailsBookReco
}

export const selectAnalyticDetailsStateIsLoadingPatchUserPermissionToAnalytic =
  (state: RootState): boolean => {
    const { isLoadingPatchUserPermissionToAnalytic } =
      state.analyticDetailsV2State
    return isLoadingPatchUserPermissionToAnalytic
  }
export const selectAnalyticDetailsStatePatchUserPermissionToAnalyticSuccess = (
  state: RootState,
): boolean => {
  const { patchUserPermissionToAnalyticSuccess } = state.analyticDetailsV2State
  return patchUserPermissionToAnalyticSuccess
}

export const selectAnalyticDetailsStatePatchUserPermissionToAnalyticError = (
  state: RootState,
): boolean => {
  const { patchUserPermissionToAnalyticError } = state.analyticDetailsV2State
  return patchUserPermissionToAnalyticError
}

export const selectAnalyticDetailsStateIsLoadingPutUserPermissionToAnalytic = (
  state: RootState,
): boolean => {
  const { isLoadingPutUserPermissionToAnalytic } = state.analyticDetailsV2State
  return isLoadingPutUserPermissionToAnalytic
}
export const selectAnalyticDetailsStatePutUserPermissionToAnalyticSuccess = (
  state: RootState,
): boolean => {
  const { putUserPermissionToAnalyticSuccess } = state.analyticDetailsV2State
  return putUserPermissionToAnalyticSuccess
}

export const selectAnalyticDetailsStatePutUserPermissionToAnalyticError = (
  state: RootState,
): boolean => {
  const { putUserPermissionToAnalyticError } = state.analyticDetailsV2State
  return putUserPermissionToAnalyticError
}

export const selectAnalyticDetailsStateIsLoadingDeleteUserToShareReport = (
  state: RootState,
): boolean => {
  const { isLoadingDeleteUserToShareReport } = state.analyticDetailsV2State
  return isLoadingDeleteUserToShareReport
}
export const selectAnalyticDetailsStateDeleteUserToShareReportSuccess = (
  state: RootState,
): boolean => {
  const { deleteUserToShareReportSuccess } = state.analyticDetailsV2State
  return deleteUserToShareReportSuccess
}

export const selectAnalyticDetailsStateDeleteUserToShareReportError = (
  state: RootState,
): boolean => {
  const { deleteUserToShareReportError } = state.analyticDetailsV2State
  return deleteUserToShareReportError
}

export const selectAnalyticDetailsStateIsLoadingPatchReportByIdChangeOwner = (
  state: RootState,
): boolean => {
  const { isLoadingPatchReportByIdChangeOwner } = state.analyticDetailsV2State
  return isLoadingPatchReportByIdChangeOwner
}
export const selectAnalyticDetailsStatePatchReportByIdChangeOwnerSuccess = (
  state: RootState,
): boolean => {
  const { patchReportByIdChangeOwnerSuccess } = state.analyticDetailsV2State
  return patchReportByIdChangeOwnerSuccess
}

export const selectAnalyticDetailsStatePatchReportByIdChangeOwnerError = (
  state: RootState,
): boolean => {
  const { patchReportByIdChangeOwnerError } = state.analyticDetailsV2State
  return patchReportByIdChangeOwnerError
}

export const selectAnalyticDetailsStateBooksInputsMenuOpen = (
  state: RootState,
): boolean => {
  const { booksInputsMenuOpen } = state.analyticDetailsV2State
  return booksInputsMenuOpen
}

export const selectAnalyticDetailsStateIsLoadingNewConstellationGetBookReco = (
  state: RootState,
): boolean => {
  const { isLoadingNewConstellationGetBookReco } = state.analyticDetailsV2State
  return isLoadingNewConstellationGetBookReco
}

export const selectAnalyticDetailsStateAvailableModules = (
  state: RootState,
): AnalyticAvailableModulesInterface => {
  const { availableModules } = state.analyticDetailsV2State
  return availableModules
}

export const selectAnalyticDetailsStateIsLoadingGetAvailableModules = (
  state: RootState,
): boolean => {
  const { isLoadingGetAvailableModules } = state.analyticDetailsV2State
  return isLoadingGetAvailableModules
}

export const selectAnalyticDetailsStateNeedToRefreshReport = (
  state: RootState,
): boolean => {
  const { needToRefreshReport } = state.analyticDetailsV2State
  return needToRefreshReport
}

export const selectAnalyticDetailsStateShowOrganizeMenu = (
  state: RootState,
): boolean => {
  const { showOrganizeMenu } = state.analyticDetailsV2State
  return showOrganizeMenu
}

export const selectAnalyticDetailsStateOutputIdToScroll = (
  state: RootState,
): string | null => {
  const { outputIdToScroll } = state.analyticDetailsV2State
  return outputIdToScroll
}

export const selectAnalyticDetailsStateDatasetModulesPreview = (
  state: RootState,
): DatasetModuleInterface[] | null => {
  const { datasetModulesPreview } = state.analyticDetailsV2State
  return datasetModulesPreview
}

export const selectAnalyticDetailsStateWorkMirrorBookDetailsCardValues = (
  state: RootState,
): {
  isLoadingBookDetailsGetBookReco: boolean
  bookDetailsBookReco: BookInterface[]
  isLoadingGetBookDetails: boolean
  isLoadingGetEditions: boolean
  isLoadingGetB2BBookDetails: boolean
  bookDetailsSelected: WorkMirrorBookInterface | null
  bookDetailsSelectedEditions: BookInterface[]
  bookDetailsSelectedB2BInfo: BookDetailResponseInterface
} => {
  const {
    isLoadingBookDetailsGetBookReco,
    bookDetailsBookReco,
    isLoadingGetBookDetails,
    isLoadingGetEditions,
    isLoadingGetB2BBookDetails,
    bookDetailsSelected,
    bookDetailsSelectedEditions,
    bookDetailsSelectedB2BInfo,
  } = state.analyticDetailsV2State
  return {
    isLoadingBookDetailsGetBookReco,
    bookDetailsBookReco,
    isLoadingGetBookDetails,
    isLoadingGetEditions,
    isLoadingGetB2BBookDetails,
    bookDetailsSelected,
    bookDetailsSelectedEditions,
    bookDetailsSelectedB2BInfo,
  }
}

export const selectAnalyticDetailsStateAnalyticDetailsPermission = (
  state: RootState,
): AnalyticsPermissionEnum => {
  const { analyticDetails } = state.analyticDetailsV2State

  try {
    return analyticDetails.permission
  } catch (e) {
    return AnalyticsPermissionEnum.READ
  }
}
