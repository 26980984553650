import React from 'react'
import GlSideBar from '../../../share/GlSideBar'
import GlLoader from '../../../share/GlLoader'
import AudiencesResultsBookDetailsHeader from './AudiencesResultsBookDetailsHeader'
import AudiencesResultsBookDetailsSummary from './AudiencesResultsBookDetailsSummary'
import AudiencesResultsComments from './AudiencesResultsComments'
import RanksBookDetailsConstellation from './AudiencesResultsBookWorksMirrors'
import { BookDetailInterface } from '../../../../core/interface/rank/book-detail.interface'
import AudiencesResultsBookDetailsProvider from './AudiencesResultsBookDetailsProvider'
import { TrendV2Interface } from '../../../../core/interface/trend-v2.interface'
import { AnalyticInputInterface } from '../../../../core/interface/analytics/analytic-input.interface'

const AudiencesResultsBookDetails = ({
  bookSelected,
  isLoadingBookDetails,
  isLoadingBookDetailsWorkMirror,
  bookDetails,
  bookDetailsWorkMirror,
  hasOverlay = true,
  widthOverlay = 506,
  handleBookSelected,
}: {
  bookSelected: TrendV2Interface | null
  isLoadingBookDetails: boolean
  isLoadingBookDetailsWorkMirror: boolean
  bookDetails: BookDetailInterface | null
  bookDetailsWorkMirror: AnalyticInputInterface | null
  hasOverlay?: boolean
  widthOverlay?: number
  handleBookSelected: (book: TrendV2Interface | null) => void
}) => {
  const hasComments: boolean =
    (bookDetails?.additionalData?.stats?.comments || 0) > 0

  return (
    <GlSideBar
      open={!!bookSelected}
      position="right"
      hasOverlay={hasOverlay}
      width={widthOverlay}
    >
      <div
        style={{
          width: widthOverlay,
        }}
      >
        {(!isLoadingBookDetails && bookDetails && (
          <div className="flex flex-col h-[2000px]">
            <div>
              <AudiencesResultsBookDetailsHeader
                bookSelected={bookSelected}
                bookData={bookDetails.data}
                handleBookSelected={handleBookSelected}
              />
              <div className="mx-5 mt-4 mb-2">
                <AudiencesResultsBookDetailsSummary
                  description={bookDetails.data.description}
                />
                <div
                  className={`mt-[20px] ${
                    hasComments ? 'border-[#E3E3E3] border' : ''
                  }`}
                />
              </div>

              {(hasComments && (
                <AudiencesResultsComments
                  comments={bookDetails.comments}
                  nbComments={bookDetails.additionalData.stats.comments}
                />
              )) ||
                null}
            </div>

            <RanksBookDetailsConstellation
              isLoadingBookDetailsWorkMirror={isLoadingBookDetailsWorkMirror}
              bookDetailsWorkMirror={bookDetailsWorkMirror}
            />

            {(bookDetails?.data?.provider && (
              <AudiencesResultsBookDetailsProvider
                provider={bookDetails?.data?.provider}
              />
            )) ||
              null}
          </div>
        )) || <GlLoader />}
      </div>
    </GlSideBar>
  )
}

export default AudiencesResultsBookDetails
