import { createAsyncThunk } from '@reduxjs/toolkit'
import { getTopRequestSavedService } from '../../core/api/services/bookmetrie/top-request/get-top-requests-saved.service'
import { deleteTopRequestSavedService } from '../../core/api/services/bookmetrie/top-request/delete-top-requests-saved.service'
import { putTopRequestSavedService } from '../../core/api/services/bookmetrie/top-request/put-top-requests-saved.service'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'
import { getTopRequestSavedNextService } from '../../core/api/services/bookmetrie/top-request/get-top-requests-saved-next.service'
import { RootState } from '../store'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'

export const audiencesSearchGetTopRequestSavedNextAction = createAsyncThunk(
  'audiences-search/get-top-request-saved-next-action',
  async (
    { nextApiPath }: { nextApiPath: string },
    { dispatch, getState },
  ): Promise<PaginationResponseInterface<TopRequestSavedInterface>> => {
    try {
      const { audiencesSearchState } = getState() as RootState
      const { cancelTokenTopRequestSavedNext } = audiencesSearchState

      const { data } = await getTopRequestSavedNextService(
        nextApiPath,
        cancelTokenTopRequestSavedNext?.token,
      )

      if (data.next) {
        void dispatch(
          audiencesSearchGetTopRequestSavedNextAction({
            nextApiPath: data.next,
          }),
        )
      }
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const audiencesSearchGetTopRequestSavedAction = createAsyncThunk(
  'audiences-search/get-top-request-saved-action',
  async (_, { dispatch }): Promise<any> => {
    try {
      const { data } = await getTopRequestSavedService()
      if (data.next) {
        void dispatch(
          audiencesSearchGetTopRequestSavedNextAction({
            nextApiPath: data.next,
          }),
        )
      }
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const audiencesSearchPutTopRequestSavedAction = createAsyncThunk(
  'audiences-search/put-top-request-saved-action',
  async ({
    requestId,
    request,
  }: {
    requestId: string
    request: Partial<TopRequestSavedInterface>
  }): Promise<any> => {
    try {
      const { data } = await putTopRequestSavedService(requestId, request)
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const audiencesSearchDeleteTopRequestSavedAction = createAsyncThunk(
  'audiences-search/delete-top-request-saved-action',
  async ({ requestId }: { requestId: string }): Promise<any> => {
    try {
      const { data } = await deleteTopRequestSavedService(requestId)
      return data
    } catch (error) {
      throw new Error()
    }
  },
)
