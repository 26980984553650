import React, { useCallback, useEffect, useRef, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import Masonry from 'masonry-layout'
import { SetColorConfig } from '../../../../../core/config/SetColorConfig'
import { AnalyticOutputInterface } from '../../../../../core/interface/analytics/analytic-output.interface'
import { ReactComponent as SharedIcon } from '../../../../../assets/icons/share-icon-white-16.icon.svg'
import { ReactComponent as PersonnalisedIcon } from '../../../../../assets/icons/personnalised-icon.svg'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import {
  selectAnalyticDetailsStateAnalyticDetails,
  selectAnalyticDetailsStateAnalyticDetailsOutputs,
  selectAnalyticDetailsStateNeedToRefreshReport,
  selectAnalyticDetailsStateOutputIdToScroll,
  selectAnalyticDetailsStateShowOrganizeMenu,
  selectAnalyticDetailsStateWorkMirrorBookDetailsCardValues,
} from '../../../../../store/getters/analytics-details/analytic-details-v2.getters'
import {
  CompareGroupType,
  StudyObjectType,
} from '../configs/StudiesObjectsCompareGroupTypesConfig'
import {
  CompareGroupValueType,
  StudyObjectValueType,
} from '../../../../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import { analyticsDetailsReadershipProfileStateGetPreviewValue } from '../../../../../store/actions/analytics-details/analytics-details-readership-profile-state.actions'
import {
  selectAnalyticDetailsReadershipProfileStateCompareGroupBooks,
  selectAnalyticDetailsReadershipProfileStateCompareGroupType,
  selectAnalyticDetailsReadershipProfileStateCompareGroupValue,
  selectAnalyticDetailsReadershipProfileStateIsLoadingReadershipProfileInput,
  selectAnalyticDetailsReadershipProfileStateStudyObjectBooks,
  selectAnalyticDetailsReadershipProfileStateStudyObjectType,
  selectAnalyticDetailsReadershipProfileStateStudyObjectValue,
} from '../../../../../store/getters/analytics-details/analytics-details-readership-profile-state.gettters'
import RecapInputTag from '../inputs/recap/RecapInputTag'
import GlLoader from '../../../../share/GlLoader'
import OutputTypeSwitch from '../outputs/OutputTypeSwitch'
import WorkMirrorBookDetailsCard from '../../work-mirror/WorkMirrorBookDetailsCard'
import {
  analyticDetailsV2StateGetBookDetailsDataById,
  analyticDetailsV2StatePutOutputsOrderAction,
  analyticDetailsV2StatePutVisualThemeAction,
  analyticsDetailsV2StateDeleteDatasetModulesAction,
  analyticsDetailsV2StateDeleteImageOutputAction,
  analyticsDetailsV2StateDeleteStudyObjectOutputAction,
  analyticsDetailsV2StateDeleteTextBoxesOutputAction,
  analyticsDetailsV2StateDeleteTitleOutputAction,
  analyticsDetailV2StateGetReportByIdAction,
} from '../../../../../store/actions/analytics-details/analytic-details-v2.actions'
import GlSideBar from '../../../../share/GlSideBar'
import { BookInterface } from '../../../../../core/interface/book.interface'
import {
  buildRelativePath,
  PathConfig,
} from '../../../../../core/config/PathConfig'
import { selectWebAppStateReadershipProfileGenerating } from '../../../../../store/getters/web-app.getters'
import { AnalyticsStatusEnum } from '../../../../../core/enums/analytics-status.enum'
import AnalyticReadershipProfileGeneratingCard from './AnalyticReadershipProfileGeneratingCard'
import { setReadershipProfileGenerating } from '../../../../../store/reducers/web-app.reducer'
import OrganizeMenuView from './organize-menu/OrganizeMenuView'
import AddModuleView from './add-module/AddModuleView'
import { analyticsDetailV2StateSetShowOrganizeMenu } from '../../../../../store/reducers/analytics-details/analytic-details-v2.reducer'
import { AnalyticOutputTypeEnum } from '../../../../../core/enums/analytic-output-type.enum'
import ValidationDeleteOuputDialogReadershipProfile from '../dialogs/ValidationDeleteOuputDialogReadershipProfile'
import { AnalyticModuleReplace } from '../../../../../core/interface/analytics/analytic-module-replace.interface'
import { AnalyticVisualThemeEnum } from '../../../../../core/enums/analytic-visual-theme.enum'
import { AnalyticsPermissionEnum } from '../../../../../core/enums/analytics-permission.enum'

interface OutputToDeleteInterface {
  outputId: string
  outputType: AnalyticOutputTypeEnum
}

const ModifiedDateStyle = styled.div`
  color: #666;
  text-align: right;
  font-variant-numeric: lining-nums proportional-nums;
  align-self: stretch;
  font-family: 'Fira Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const AnalyticDetailsReadershipProfileDetailsView = ({
  handleShareAnalytics,
}: {
  handleShareAnalytics: () => void
}) => {
  const { t } = useTranslation()
  const [deleteOuputDialog, setDeleteOutputDialog] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [outputToDelete, setOutputToDelete] =
    useState<OutputToDeleteInterface>()

  const [showBookCard, setShowBookCard] = useState(true)
  const [showAddModuleView, setShowAddModuleView] = useState(false)
  const [moduleToReplace, setModuleToReplace] =
    useState<AnalyticModuleReplace>()
  const masonry = useRef<Masonry>()

  const analyticsDetails = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetails,
  )
  const analyticsColorConfig =
    SetColorConfig.find(
      (colorConfig) =>
        colorConfig.id === analyticsDetails.inputs[0].visual_theme,
    ) || SetColorConfig[0]

  const isLoadingReadershipProfileInput = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateIsLoadingReadershipProfileInput,
  )

  const outputs = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetailsOutputs,
  )

  const studyObjectType = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateStudyObjectType,
  )
  const studyObjectValue = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateStudyObjectValue,
  )
  const studyObjectBooks = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateStudyObjectBooks,
  )

  const compareGroupType = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateCompareGroupType,
  )
  const compareGroupValue = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateCompareGroupValue,
  )
  const compareGroupBooks = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateCompareGroupBooks,
  )

  const {
    isLoadingBookDetailsGetBookReco,
    bookDetailsBookReco,
    isLoadingGetBookDetails,
    isLoadingGetEditions,
    isLoadingGetB2BBookDetails,
    bookDetailsSelected,
    bookDetailsSelectedEditions,
    bookDetailsSelectedB2BInfo,
  } = useAppSelector(selectAnalyticDetailsStateWorkMirrorBookDetailsCardValues)

  const needToRefreshReport = useAppSelector(
    selectAnalyticDetailsStateNeedToRefreshReport,
  )

  const outputIdToScroll = useAppSelector(
    selectAnalyticDetailsStateOutputIdToScroll,
  )

  const showOrganizeMenu = useAppSelector(
    selectAnalyticDetailsStateShowOrganizeMenu,
  )

  const setShowOrganizeMenu = (value: boolean) => {
    void dispatch(analyticsDetailV2StateSetShowOrganizeMenu(value))
  }

  const progress = useAppSelector(selectWebAppStateReadershipProfileGenerating)

  useEffect(() => {
    const elem = document.querySelector('.js-masonry')
    const masonryOptions: Masonry.Options = {
      itemSelector: '.grid-item',
      columnWidth: '.grid-sizer',
      percentPosition: true,
      resize: false,
      transitionDuration: 0,
    }
    if (elem) {
      masonry.current = new Masonry(elem, masonryOptions)
    }
  }, [])

  const refreshLayout = () => {
    masonry.current?.reloadItems && masonry.current.reloadItems()
    masonry.current?.layout && masonry.current.layout()
  }

  useEffect(() => {
    if (masonry.current && outputs.length > 0) {
      refreshLayout()
    }
  }, [showOrganizeMenu, outputs])

  useEffect(() => {
    if (analyticsDetails) {
      void dispatch(
        analyticsDetailsReadershipProfileStateGetPreviewValue({
          analyticsDetails,
        }),
      )
    }
  }, [analyticsDetails])

  function handleOrderChange(outputs: AnalyticOutputInterface[]) {
    void dispatch(
      analyticDetailsV2StatePutOutputsOrderAction({
        outputs,
      }),
    )
  }

  function handleVisualThemeEdit(visualTheme: AnalyticVisualThemeEnum) {
    void dispatch(
      analyticDetailsV2StatePutVisualThemeAction({
        visualTheme,
      }),
    )
  }

  useEffect(() => {
    const reportProgress = progress.find(
      (report) => report.data.id === analyticsDetails.id,
    )

    if (
      reportProgress &&
      reportProgress.data.status === AnalyticsStatusEnum.GENERATED
    ) {
      dispatch(
        setReadershipProfileGenerating(
          progress.filter((report) => report.data.id !== analyticsDetails.id),
        ),
      )
      void dispatch(
        analyticsDetailV2StateGetReportByIdAction({
          reportId: analyticsDetails.id,
        }),
      )
    }
  }, [progress])

  useEffect(() => {
    if (analyticsDetails && needToRefreshReport) {
      void dispatch(
        analyticsDetailV2StateGetReportByIdAction({
          reportId: analyticsDetails.id,
        }),
      )
    }
  }, [needToRefreshReport])

  const scrollToTreemap = useCallback((id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({
        block: 'start',
      })
    }
  }, [])

  const scrollToNewOutput = useCallback((id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({
        block: 'start',
      })
    }
  }, [])

  useEffect(() => {
    if (outputIdToScroll) {
      setTimeout(() => {
        scrollToNewOutput(outputIdToScroll)
      }, 100)
    }
  }, [outputIdToScroll])

  const handleCloseBookDetails = useCallback(() => {
    void dispatch(analyticDetailsV2StateGetBookDetailsDataById({ eanId: null }))
  }, [bookDetailsSelected])

  const handleOpenBookDetails = useCallback(
    (eanId: number | null, showBookDetail: boolean) => {
      setShowBookCard(showBookDetail)
      void dispatch(analyticDetailsV2StateGetBookDetailsDataById({ eanId }))
    },
    [
      bookDetailsSelected,
      bookDetailsSelectedEditions,
      bookDetailsSelectedB2BInfo,
    ],
  )

  useEffect(() => {
    if (!showAddModuleView) {
      setModuleToReplace(undefined)
    } else if (bookDetailsSelected) {
      handleCloseBookDetails()
    }
  }, [showAddModuleView])

  const handleOnClickShareAnalytics = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    handleShareAnalytics()
  }

  const handleOnClickPersonnalize = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    setShowOrganizeMenu(!showOrganizeMenu)
  }

  const goBackToAnalytics = () => {
    navigate(buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.ANALYTICS]))
  }

  const handleAnalyticsDeleteValidation = () => {
    setDeleteOutputDialog(false)
    switch (outputToDelete?.outputType) {
      case AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET:
        void dispatch(
          analyticsDetailsV2StateDeleteDatasetModulesAction({
            datasetModuleId: outputToDelete.outputId,
          }),
        )
        break
      case AnalyticOutputTypeEnum.READERSHIP_PROFILE_IMAGE:
        void dispatch(
          analyticsDetailsV2StateDeleteImageOutputAction({
            outputId: outputToDelete.outputId,
          }),
        )
        break
      case AnalyticOutputTypeEnum.READERSHIP_PROFILE_TEXTBOX:
        void dispatch(
          analyticsDetailsV2StateDeleteTextBoxesOutputAction({
            outputId: outputToDelete.outputId,
          }),
        )
        break
      case AnalyticOutputTypeEnum.READERSHIP_PROFILE_TITLE:
        void dispatch(
          analyticsDetailsV2StateDeleteTitleOutputAction({
            outputId: outputToDelete.outputId,
          }),
        )
        break
      case AnalyticOutputTypeEnum.READERSHIP_PROFILE_STUDY_OBJECT:
        void dispatch(
          analyticsDetailsV2StateDeleteStudyObjectOutputAction({
            outputId: outputToDelete.outputId,
          }),
        )
        break
      default:
        break
    }
  }

  const handleAnalyticsDeleteOutput = (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => {
    setDeleteOutputDialog(true)
    setOutputToDelete({
      outputId,
      outputType,
    })
  }

  const handleChangeDatasetOutput = (
    moduleToReplace: AnalyticModuleReplace,
  ) => {
    setModuleToReplace(moduleToReplace)
    setShowAddModuleView(true)
  }

  return (
    <>
      <div className="flex flex-col gap-4 flex-auto px-8 py-4 relative">
        {showAddModuleView && (
          <AddModuleView
            setShowAddModuleView={setShowAddModuleView}
            moduleToReplace={moduleToReplace}
            analyticsSetColor={analyticsColorConfig.value}
          />
        )}
        <div className="relative rounded-md bg-white flex flex-row justify-center items-center p-2  ">
          {(isLoadingReadershipProfileInput && (
            <div className="flex flex-row items-center justify-center m-[-10px]">
              <GlLoader width={32} height={32} />
            </div>
          )) || (
            <>
              {analyticsDetails.status === AnalyticsStatusEnum.GENERATED &&
                analyticsDetails.permission ===
                  AnalyticsPermissionEnum.EDIT && (
                  <button
                    type="button"
                    className="absolute left-2 flex w-8 h-8 items-center justify-center rounded bg-[#F7F7F7] hover:bg-[#E3E3E3]"
                    onClick={handleOnClickPersonnalize}
                  >
                    <PersonnalisedIcon className="w-5 h-5" />
                  </button>
                )}
              <div className="flex flex-row gap-2 items-center justify-center ">
                <span>{t('iStudyTheReadersOf')}</span>
                {(studyObjectType && studyObjectValue && (
                  <RecapInputTag<StudyObjectType, StudyObjectValueType>
                    type={studyObjectType}
                    value={studyObjectValue}
                    color={analyticsColorConfig.color1}
                    booksSelected={studyObjectBooks}
                  />
                )) ||
                  null}
                <span>{t('inComparisonWithThoseOf')}</span>
                {(compareGroupType && compareGroupValue && (
                  <RecapInputTag<CompareGroupType, CompareGroupValueType>
                    type={compareGroupType}
                    value={compareGroupValue}
                    color={analyticsColorConfig.color2}
                    booksSelected={compareGroupBooks}
                  />
                )) ||
                  null}
              </div>
              {analyticsDetails.status === AnalyticsStatusEnum.GENERATED &&
                analyticsDetails.permission ===
                  AnalyticsPermissionEnum.EDIT && (
                  <button
                    type="button"
                    className="absolute right-2 flex w-8 h-8 items-center justify-center rounded bg-[#F7F7F7] hover:bg-[#E3E3E3]"
                    onClick={(event) => {
                      handleOnClickShareAnalytics(event)
                    }}
                  >
                    <SharedIcon className="w-5 h-5" />
                  </button>
                )}
            </>
          )}
        </div>
        <div className="flex gap-4">
          {showOrganizeMenu && (
            <OrganizeMenuView
              analyticsColorConfig={analyticsColorConfig}
              outputs={outputs}
              setShowAddModuleView={setShowAddModuleView}
              handleOrderChange={handleOrderChange}
              handleVisualThemeEdit={handleVisualThemeEdit}
            />
          )}
          <PerfectScrollbar
            options={{ suppressScrollX: true }}
            style={{
              height: 'calc(100vh - 142px)',
            }}
            className="flex flex-col rounded-xl bg-white py-4 px-8 gap-[32px] w-full"
          >
            <ModifiedDateStyle>
              {`${t('ReportReaderShipProfileDate')} ${dayjs(
                analyticsDetails.modified,
              ).format('DD MMMM	YYYY')}`}
            </ModifiedDateStyle>
            <div>
              <div className="grid js-masonry">
                <div className="grid-sizer" />
                <div className="gutter-sizer" />
                {outputs.map((output: AnalyticOutputInterface) => (
                  <OutputTypeSwitch
                    key={`output-${output.id}`}
                    output={output}
                    scrollToTreemap={scrollToTreemap}
                    eanSelected={
                      bookDetailsSelected && bookDetailsSelected.id_ean
                    }
                    handleOpenBookDetails={handleOpenBookDetails}
                    editMode={
                      analyticsDetails.permission ===
                      AnalyticsPermissionEnum.EDIT
                    }
                    isOrganizeMenuOpen={showOrganizeMenu}
                    handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
                    handleChangeDatasetOutput={handleChangeDatasetOutput}
                    analyticsColorConfig={analyticsColorConfig}
                    outputIdToScroll={outputIdToScroll || undefined}
                    refreshLayout={refreshLayout}
                  />
                ))}
              </div>
            </div>
            {analyticsDetails.status === AnalyticsStatusEnum.GENERATING && (
              <AnalyticReadershipProfileGeneratingCard
                goBackToAnalytics={goBackToAnalytics}
              />
            )}
          </PerfectScrollbar>
        </div>
      </div>
      {bookDetailsSelected && (
        <div className="absolute top-[48px] ">
          <GlSideBar
            open
            position="right"
            hasOverlay
            width={506}
            scrollable={false}
            height="h-[calc(100%-48px)]"
          >
            <WorkMirrorBookDetailsCard
              isLoadingBookDetailsGetBookReco={isLoadingBookDetailsGetBookReco}
              bookDetailsBookReco={bookDetailsBookReco}
              isLoadingGetBookDetails={isLoadingGetBookDetails}
              isLoadingGetEditions={isLoadingGetEditions}
              isLoadingGetB2BBookDetails={isLoadingGetB2BBookDetails}
              openListCard={false}
              bookDetails={bookDetailsSelected}
              bookDetailsSelectedEditions={
                showBookCard
                  ? bookDetailsSelectedEditions.filter(
                      (edition: BookInterface) =>
                        edition.id_ean === bookDetailsSelected.id_ean,
                    )
                  : bookDetailsSelectedEditions
              }
              bookDetailsSelectedB2BInfo={bookDetailsSelectedB2BInfo}
              handleCloseBookDetails={handleCloseBookDetails}
            />
          </GlSideBar>
        </div>
      )}
      <ValidationDeleteOuputDialogReadershipProfile
        open={deleteOuputDialog}
        handleCloseDialog={() => setDeleteOutputDialog(false)}
        handleAnalyticsDeleteValidation={handleAnalyticsDeleteValidation}
      />
    </>
  )
}

export default AnalyticDetailsReadershipProfileDetailsView
