import { useTranslation } from 'react-i18next'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import { DatasetModuleInterface } from '../../../../../../core/interface/analytics/dataset-module.interface'
import { AnalyticOutputLayoutSizeEnum } from '../../../../../../core/enums/analytic-output-layout-size.enum'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'
import { AnalyticOutputTypeEnum } from '../../../../../../core/enums/analytic-output-type.enum'
import FullTabular from './FullTabular'
import AnalyticsOutputWrapper from '../AnalyticsOutputWrapper'
import { SizableHeightAnalyticOutput } from '../../../../../../core/enums/analytic-output-layout-full-height.enum'

const AnalyticsOutputTabular = ({
  display,
  title,
  subTitle,
  dataset,
  valuesConfigurations,
  eanSelected,
  handleOpenBookDetails,
  typeDataset,
  datasetModule,
  isPreview,
  handleAnalyticsDeleteOutput,
  handleAnalyticsChangeOutput,
  isScreenSmall,
  isOrganizeMenuOpen,
}: {
  display: AnalyticOutputLayoutSizeEnum
  title: string
  subTitle?: string
  dataset: DatasetInterface[]
  valuesConfigurations: ValueConfigurationsInterface[]
  eanSelected?: number | null
  handleOpenBookDetails?: (
    eanId: number | null,
    showBookDetail: boolean,
  ) => void
  typeDataset: DatasetTypeEnum
  datasetModule: DatasetModuleInterface
  isPreview: boolean
  handleAnalyticsDeleteOutput?: (
    outputType: AnalyticOutputTypeEnum,
    outputId: string,
  ) => void
  handleAnalyticsChangeOutput?: () => void
  isScreenSmall?: boolean
  isOrganizeMenuOpen?: boolean
}) => {
  const { t } = useTranslation()
  let infoBody: string[] = []

  switch (typeDataset) {
    case DatasetTypeEnum.WORKS_MIRROR:
      infoBody = [t('InfoBulles.workMirror.1'), t('InfoBulles.workMirror.2')]
      break
    case DatasetTypeEnum.AUTHORS_MIRROR:
      infoBody = [
        t('InfoBulles.authorMirror.1'),
        t('InfoBulles.authorMirror.2'),
      ]
      break
    case DatasetTypeEnum.MOST_FREQUENT:
      infoBody = [
        t('InfoBulles.mostFrequentTabular.1'),
        t('InfoBulles.mostFrequentTabular.2'),
      ]
      break
    case DatasetTypeEnum.OVERREPRESENTED:
      infoBody = [
        t('InfoBulles.overrepresentedTabular.1'),
        t('InfoBulles.overrepresentedTabular.2'),
      ]
      break
    default:
      break
  }

  const isMiror =
    typeDataset === DatasetTypeEnum.WORKS_MIRROR ||
    typeDataset === DatasetTypeEnum.AUTHORS_MIRROR
  return (
    <AnalyticsOutputWrapper
      outputId={datasetModule.id}
      display={display}
      title={title}
      subTitle={subTitle}
      infoBody={infoBody}
      datasetModule={datasetModule}
      isPreview={isPreview}
      outputType={AnalyticOutputTypeEnum.READERSHIP_PROFILE_DATASET}
      handleAnalyticsDeleteOutput={handleAnalyticsDeleteOutput}
      handleAnalyticsChangeOutput={handleAnalyticsChangeOutput}
      type={SizableHeightAnalyticOutput.TABULAR}
      shrink={(isPreview && isScreenSmall) || isOrganizeMenuOpen}
      customStyle={{
        ...(!isMiror &&
          !isPreview && {
            height: 'auto',
          }),
      }}
    >
      <FullTabular
        dataset={dataset}
        valuesConfigurations={valuesConfigurations}
        typeDataset={typeDataset}
        eanSelected={eanSelected}
        handleOpenBookDetails={handleOpenBookDetails}
        typeModule={datasetModule.type}
        limitToTenLines={
          typeDataset === DatasetTypeEnum.OVERREPRESENTED ||
          typeDataset === DatasetTypeEnum.MOST_FREQUENT
        }
      />
    </AnalyticsOutputWrapper>
  )
}

export default AnalyticsOutputTabular
