import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import BackGroundAnalyticsDashboard from '../../assets/icons/background-analytic-dashboard.svg'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import {
  selectWebAppStateMySelf,
  selectWebAppStateMySelfHasAnalyticsAccess,
  selectWebAppStateMySelfHasReadershipProfileAnalyticsAccess,
  selectWebAppStateMySelfHasReadershipProfileAnalyticsConsumerAccess,
  selectWebAppStateMySelfHasUniversAnalyticsAccess,
  selectWebAppStateMySelfHasWorksMirrorsAnalyticsAccess,
} from '../../store/getters/web-app.getters'
import { analyticsDashboardGetInternalReports } from '../../store/actions/analytics-dashboard.actions'
import GlLoader from '../../components/share/GlLoader'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import {
  selectAnalyticDashboardInternalReports,
  selectIsLoadingInternalReports,
} from '../../store/getters/analytics-dashboard.getters'
import GlSlideToggle from '../../components/share/GlSlideToggle'
import GlToggleIconButton from '../../components/share/GlToggleIconButton'
import GlToggleButton from '../../components/share/GlToggleButton'
import { ReactComponent as CardViewIcon } from '../../assets/icons/app/card-view.icon.svg'
import { ReactComponent as TableViewIcon } from '../../assets/icons/app/table-view.icon.svg'
import { ReactComponent as WorkMirrorIcon } from '../../assets/icons/app/work-mirror.icon.svg'
import { ReactComponent as UniversIcon } from '../../assets/icons/app/univers.icon.svg'
import { ReactComponent as ReadershipProfilIcon } from '../../assets/icons/readership-profil-card.icon.svg'
import AnalyticsDashboardCardView from '../../core/config/analytics-dashboard/card-view/AnalyticsDashboardCardView'
import AnalyticsDashboardListView from '../../core/config/analytics-dashboard/table-view/AnalyticsDashboardListView'
import GlDialog from '../../components/share/dialog/GlDialog'
import AnalyticsDashboardAddAnalyticsDialog from '../../components/analytics-dashboard/AnalyticsDashboardAddAnalyticsDialog'

export enum SwitchViewAnalyticsEnum {
  CARDS = 'CARDS',
  LIST = 'LIST',
}
const AnalyticsDashboardLayout = styled.div`
  padding: 32px;
`

export enum AnalyticsTypeDashboardEnum {
  ALL = 'all',
  WORK_MIRROR = 'work_mirror',
  READERSHIP_PROFILE = 'readership_profile',
  UNIVERS = 'univers',
}

const AnalyticsDashboard = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [displayOnlyMyAnalytics, setDisplayOnlyMyAnalytics] =
    useState<boolean>(false)

  const isLoadingInternalReports = useAppSelector(
    selectIsLoadingInternalReports,
  )
  const internalReports = useAppSelector(selectAnalyticDashboardInternalReports)

  const mySelf = useAppSelector(selectWebAppStateMySelf)

  const hasAnalyticsAccess = useAppSelector(
    selectWebAppStateMySelfHasAnalyticsAccess,
  )
  const hasReadershipProfileAnalyticsAccess = useAppSelector(
    selectWebAppStateMySelfHasReadershipProfileAnalyticsAccess,
  )
  const hasReadershipProfileAnalyticsConsumerAccess = useAppSelector(
    selectWebAppStateMySelfHasReadershipProfileAnalyticsConsumerAccess,
  )
  const hasWorkMirrorAnalyticsAccess = useAppSelector(
    selectWebAppStateMySelfHasWorksMirrorsAnalyticsAccess,
  )
  const hasUniversAnalyticsAccess = useAppSelector(
    selectWebAppStateMySelfHasUniversAnalyticsAccess,
  )

  useEffect(() => {
    if (mySelf && !hasAnalyticsAccess) {
      dispatch(setShowSnackBar(t('webApp.notAccessToFeature')))
      navigate(buildRelativePath([PathConfig.BOOKMETRIE]))
    } else {
      void dispatch(analyticsDashboardGetInternalReports())
    }
  }, [mySelf, hasAnalyticsAccess])

  const reloadPage = () => {
    void dispatch(analyticsDashboardGetInternalReports())
  }

  const analyticsTypesOptions = [
    {
      label: 'all',
      value: AnalyticsTypeDashboardEnum.ALL,
      icon: <CardViewIcon className="w-[18px] h-[18px]" />,
      enable: hasAnalyticsAccess,
    },
    {
      label: 'worksMirrors',
      value: AnalyticsTypeDashboardEnum.WORK_MIRROR,
      icon: <WorkMirrorIcon className="w-[18px] h-[18px]" />,
      enable: hasWorkMirrorAnalyticsAccess,
    },
    {
      label: 'readership_profile',
      value: AnalyticsTypeDashboardEnum.READERSHIP_PROFILE,
      icon: <ReadershipProfilIcon className="w-[18px] h-[18px]" />,
      enable: hasReadershipProfileAnalyticsAccess,
    },
    {
      label: 'univers',
      value: AnalyticsTypeDashboardEnum.UNIVERS,
      icon: <UniversIcon className="w-[18px] h-[18px]" />,
      enable: hasUniversAnalyticsAccess,
    },
  ]

  const [addNewAnalyticsDialog, setAddNewAnalyticsDialog] =
    useState<boolean>(false)

  const [analyticsTypeSelected, setAnalyticsTypeSelected] =
    useState<AnalyticsTypeDashboardEnum>(AnalyticsTypeDashboardEnum.ALL)
  const handleOnSelectAnalyticType = (value: AnalyticsTypeDashboardEnum) => {
    setAnalyticsTypeSelected(value)
  }

  const [viewTypeSelected, setViewTypeSelected] =
    useState<SwitchViewAnalyticsEnum>(SwitchViewAnalyticsEnum.CARDS)
  const handleOnSelectViewType = (value: SwitchViewAnalyticsEnum) => {
    setViewTypeSelected(value)
  }

  const handleCreateNewAnalytics = () => setAddNewAnalyticsDialog(true)

  return (
    <>
      <AnalyticsDashboardLayout className="flex-auto flex flex-col">
        <div
          className="bg-white flex flex-col flex-auto"
          style={{
            borderRadius: 8,
            background: '#FFF',
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
            backgroundImage: internalReports?.count
              ? 'none'
              : `url('${BackGroundAnalyticsDashboard}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '30% 340px',
          }}
        >
          <div
            className="bg-white flex flex-row justify-between items-center"
            style={{
              margin: 16,
            }}
          >
            <GlSlideToggle
              label={t('webApp.reports.dashboard.myAnalyticsOnly')}
              handleOnToggle={() =>
                setDisplayOnlyMyAnalytics(!displayOnlyMyAnalytics)
              }
            />
            <GlToggleButton<AnalyticsTypeDashboardEnum>
              options={analyticsTypesOptions}
              optionSelected={analyticsTypeSelected}
              handleOnSelect={handleOnSelectAnalyticType}
            />
            <GlToggleIconButton<SwitchViewAnalyticsEnum>
              options={[
                {
                  icon: <CardViewIcon className="w-[18px] h-[18px]" />,
                  value: SwitchViewAnalyticsEnum.CARDS,
                },
                {
                  icon: <TableViewIcon className="w-[18px] h-[18px]" />,
                  value: SwitchViewAnalyticsEnum.LIST,
                },
              ]}
              handleOnSelect={handleOnSelectViewType}
              optionSelected={viewTypeSelected}
            />
          </div>
          {(isLoadingInternalReports && (
            <div className="bg-white flex-auto flex flex-col items-center justify-center m-4">
              <GlLoader />
            </div>
          )) || (
            <div className="relative flex-auto flex flex-col">
              {(viewTypeSelected === SwitchViewAnalyticsEnum.CARDS && (
                <PerfectScrollbar className="flex-auto mx-4">
                  <AnalyticsDashboardCardView
                    analytics={internalReports}
                    analyticsTypeSelected={analyticsTypeSelected}
                    displayOnlyMyAnalytics={displayOnlyMyAnalytics}
                    handleCreateNewAnalytics={handleCreateNewAnalytics}
                    handleReloadAction={reloadPage}
                  />
                </PerfectScrollbar>
              )) ||
                null}
              {(viewTypeSelected === SwitchViewAnalyticsEnum.LIST && (
                <AnalyticsDashboardListView
                  analytics={internalReports}
                  analyticsTypeSelected={analyticsTypeSelected}
                  displayOnlyMyAnalytics={displayOnlyMyAnalytics}
                  handleCreateNewAnalytics={handleCreateNewAnalytics}
                  handleReloadAction={reloadPage}
                />
              )) ||
                null}
            </div>
          )}
        </div>
      </AnalyticsDashboardLayout>
      <GlDialog
        open={addNewAnalyticsDialog}
        onClose={() => setAddNewAnalyticsDialog(false)}
      >
        <AnalyticsDashboardAddAnalyticsDialog
          hasWorkMirrorsAnalyticsAccess={hasWorkMirrorAnalyticsAccess}
          hasReadershipProfileAnalyticsAccess={
            hasReadershipProfileAnalyticsAccess
          }
          hasUniversAnalyticsAccess={hasUniversAnalyticsAccess}
          handleCloseDialog={() => setAddNewAnalyticsDialog(false)}
        />
      </GlDialog>
    </>
  )
}
export default AnalyticsDashboard
