import { Action, combineReducers } from 'redux'
import { configureStore, ThunkAction } from '@reduxjs/toolkit'

import { trendsReducer } from './reducers/trends.reducer'
import { signInReducer } from './reducers/sign-in.reducer'
import { webAppReducer } from './reducers/web-app.reducer'
import { analyticsDashboardReducer } from './reducers/analytics-dashboard.reducer'
import { dashboardReducer } from './reducers/dashboard.reducer'
import { analyticsDetailsReducer } from './reducers/analytics-details.reducer'
import { shareAnalyticDetailsReducer } from './reducers/share-analytic-details.reducer'
import { forgotPasswordReducer } from './reducers/forgot-password.reducer'
import { changePasswordReducer } from './reducers/change-password.reducer'
import { settingsReducer } from './reducers/settings.reducer'
import { dynamicsSearchReducer } from './reducers/dynamics-search.reducer'
import { dynamicsResultsReducer } from './reducers/dynamics-results.reducer'
import { invitationReducer } from './reducers/invitation.reducer'
import { analyticDetailsV2Reducer } from './reducers/analytics-details/analytic-details-v2.reducer'
import { newReadershipProfileReducer } from './reducers/analytics-details/new-analytics-readership-profile-state.reducer'
import { analyticsDetailsReadershipProfileReducer } from './reducers/analytics-details/analytics-details-readership-profile.reducer'

const store = configureStore({
  reducer: combineReducers({
    webAppState: webAppReducer,
    dashboardState: dashboardReducer,
    audiencesSearchState: dynamicsSearchReducer,
    audiencesResultsState: dynamicsResultsReducer,
    signInState: signInReducer,
    forgotPasswordState: forgotPasswordReducer,
    changePasswordState: changePasswordReducer,
    ranksState: trendsReducer,
    reportsDashboardState: analyticsDashboardReducer,
    reportDetailsState: analyticsDetailsReducer,
    shareAnalyticDetailsState: shareAnalyticDetailsReducer,
    settingsState: settingsReducer,
    invitationState: invitationReducer,
    // New code
    analyticDetailsV2State: analyticDetailsV2Reducer,
    newAnalyticsReadershipProfileState: newReadershipProfileReducer,
    analyticsDetailsReadershipProfileState:
      analyticsDetailsReadershipProfileReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: true,
    }),
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
