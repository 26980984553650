import '../../../../styles/_shared.scss'
import '../../../../styles/pages/List.scss'
import styled from '@emotion/styled'
import AudiencesResultsTableV2 from './table/AudiencesResultsTableV2'
import { BookDetailInterface } from '../../../../core/interface/rank/book-detail.interface'
import { TrendV2Interface } from '../../../../core/interface/trend-v2.interface'
import { TrendsItemFilterActionInterface } from '../../../../core/interface/audiences/trends-item-filter-action.interface'
import { SortDirectionEnum } from '../../../../core/enums/sort-direction.enum'
import { TagFilterEnum } from '../../../../core/enums/tag-filter.enum'
import AudiencesResultsBookDetails from '../book-details/AudiencesResultsBookDetails'
import { AnalyticInputInterface } from '../../../../core/interface/analytics/analytic-input.interface'

const LayoutStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 234px);
  display: flex;
  flex-direction: column;
`

const AudiencesResultsViewModeTable = ({
  trends,
  isLoadingTrends,
  bookDetails,
  bookDetailsWorkMirror,
  handleSortHeaderSelected,
  handleSetShowSnackBar,
  groupSelected,
  sortHeaderSelected,
  sortsSelected,
  tagSelected,
  handleBookSelected,
  bookSelected,
  searchValue,
  isLoadingBookDetails,
  isLoadingBookDetailsWorkMirror,
}: {
  trends: TrendV2Interface[]
  isLoadingTrends: boolean
  bookDetails: BookDetailInterface | null
  bookDetailsWorkMirror: AnalyticInputInterface | null
  handleSortHeaderSelected: (filter: string, direction: number) => void
  handleBookSelected: (book: TrendV2Interface | null) => void
  handleSetShowSnackBar: () => void
  groupSelected: TrendsItemFilterActionInterface | null
  sortHeaderSelected: { columnKey: string; sortDirection: SortDirectionEnum }
  sortsSelected: { columnKey: string; sortDirection: SortDirectionEnum }[]
  tagSelected: TagFilterEnum
  bookSelected: TrendV2Interface | null
  searchValue: string
  isLoadingBookDetails: boolean
  isLoadingBookDetailsWorkMirror: boolean
}) => {
  return (
    <div className="relative h-full flex flex-row">
      <LayoutStyle>
        <AudiencesResultsTableV2
          isLoadingTrends={isLoadingTrends}
          trends={trends}
          handleSortHeaderSelected={handleSortHeaderSelected}
          handleBookSelected={handleBookSelected}
          handleSetShowSnackBar={handleSetShowSnackBar}
          groupSelected={groupSelected}
          sortHeaderSelected={sortHeaderSelected}
          sortsSelected={sortsSelected}
          tagSelected={tagSelected}
          bookSelected={bookSelected}
          searchValue={searchValue}
        />
      </LayoutStyle>
      <AudiencesResultsBookDetails
        bookDetails={bookDetails}
        handleBookSelected={handleBookSelected}
        bookSelected={bookSelected}
        bookDetailsWorkMirror={bookDetailsWorkMirror}
        isLoadingBookDetails={isLoadingBookDetails}
        isLoadingBookDetailsWorkMirror={isLoadingBookDetailsWorkMirror}
      />
    </div>
  )
}

export default AudiencesResultsViewModeTable
