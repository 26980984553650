import styled from '@emotion/styled'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { nanoid } from 'nanoid'

const AuthorLabel = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #595959;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const TooltipContainer = styled.div`
  border: 1px solid #252525;
  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #252525 transparent;
  }
`

const START_OTHER = 2
interface Props {
  authors: string[]
}
const AudiencesResultsCellAuthors = ({ authors }: Props) => {
  const { t } = useTranslation()
  const [displayOtherAuthors, setDisplayOtherAuthors] = useState<boolean>(false)

  return (
    <div className="relative">
      {(authors.length && <AuthorLabel>{authors[0]}</AuthorLabel>) || (
        <AuthorLabel>
          <span>
            {t('webApp.ranks.table.cell.authors', {
              count: 0,
            })}
          </span>
        </AuthorLabel>
      )}
      {authors.length > START_OTHER && (
        <>
          <AuthorLabel>
            <span>{authors[1]}</span>
            {(authors.length > START_OTHER && (
              <span
                onMouseEnter={() => setDisplayOtherAuthors(true)}
                onMouseLeave={() => setDisplayOtherAuthors(false)}
                className="hover:text-[#252525] cursor-pointer"
              >
                {t('webApp.ranks.table.cell.authors', {
                  count: authors.length - START_OTHER,
                })}
              </span>
            )) ||
              ''}
          </AuthorLabel>
          {(displayOtherAuthors && (
            <TooltipContainer className="rounded-lg absolute right-0 bg-white shadow-3xl p-2 z-30 flex flex-col gap-2">
              {authors.slice(START_OTHER).map((author: string) => (
                <AuthorLabel key={nanoid(5)}>{author}</AuthorLabel>
              ))}
            </TooltipContainer>
          )) ||
            ''}
        </>
      )}
    </div>
  )
}

export default AudiencesResultsCellAuthors
