import styled from '@emotion/styled'

const DialogTitleStyle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  font-weight: 500;
  line-height: 32px; /* 160% */
`

const GlDialogTitle = ({ label }: { label: string }) => {
  return <DialogTitleStyle className="flex flex-row">{label}</DialogTitleStyle>
}

export default GlDialogTitle
