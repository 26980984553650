import { AudiencesResultsViewModeEnum } from '../../../store/interfaces/dynamics-results.state.interface'
import AudiencesResultsViewModeBills from './view-modes/AudiencesResultsViewModeBills'
import AudiencesResultsViewModeTable from './view-modes/AudiencesResultsViewModeTable'
import AudiencesResultsViewModeCovers from './view-modes/AudiencesResultsViewModeCovers'
import AudiencesResultsViewModeCards from './view-modes/AudiencesResultsViewModeCards'
import { TrendV2Interface } from '../../../core/interface/trend-v2.interface'
import { BookDetailInterface } from '../../../core/interface/rank/book-detail.interface'
import { TrendsItemFilterActionInterface } from '../../../core/interface/audiences/trends-item-filter-action.interface'
import { SortDirectionEnum } from '../../../core/enums/sort-direction.enum'
import { TagFilterEnum } from '../../../core/enums/tag-filter.enum'
import { AnalyticInputInterface } from '../../../core/interface/analytics/analytic-input.interface'

const AudiencesResultsBody = ({
  isLoadingTrends,
  viewMode,
  trends,
  handleSortHeaderSelected,
  handleSetShowSnackBar,
  groupSelected,
  sortHeaderSelected,
  sortsSelected,
  bookDetails,
  bookDetailsWorkMirror,
  tagSelected,
  handleBookSelected,
  bookSelected,
  searchValue,
  isLoadingBookDetails,
  isLoadingBookDetailsWorkMirror,
}: {
  isLoadingTrends: boolean
  viewMode: AudiencesResultsViewModeEnum
  trends: TrendV2Interface[]
  bookDetails: BookDetailInterface | null
  bookDetailsWorkMirror: AnalyticInputInterface | null
  handleSortHeaderSelected: (filter: string, direction: number) => void
  handleSetShowSnackBar: () => void
  groupSelected: TrendsItemFilterActionInterface | null
  sortHeaderSelected: { columnKey: string; sortDirection: SortDirectionEnum }
  sortsSelected: { columnKey: string; sortDirection: SortDirectionEnum }[]
  tagSelected: TagFilterEnum
  handleBookSelected: (book: TrendV2Interface | null) => void
  bookSelected: TrendV2Interface | null
  searchValue: string
  isLoadingBookDetails: boolean
  isLoadingBookDetailsWorkMirror: boolean
}) => {
  switch (viewMode) {
    case AudiencesResultsViewModeEnum.BILLS:
      return (
        <AudiencesResultsViewModeBills
          trends={trends}
          handleBookSelected={handleBookSelected}
          bookDetailsWorkMirror={bookDetailsWorkMirror}
          bookSelected={bookSelected}
          isLoadingBookDetails={isLoadingBookDetails}
          isLoadingBookDetailsWorkMirror={isLoadingBookDetailsWorkMirror}
          bookDetails={bookDetails}
        />
      )
    case AudiencesResultsViewModeEnum.CARD:
      return (
        <AudiencesResultsViewModeCards
          trends={trends}
          handleBookSelected={handleBookSelected}
          bookDetailsWorkMirror={bookDetailsWorkMirror}
          bookSelected={bookSelected}
          isLoadingBookDetails={isLoadingBookDetails}
          isLoadingBookDetailsWorkMirror={isLoadingBookDetailsWorkMirror}
          bookDetails={bookDetails}
        />
      )
    case AudiencesResultsViewModeEnum.COVER:
      return <AudiencesResultsViewModeCovers />
    case AudiencesResultsViewModeEnum.TABLE:
      return (
        <AudiencesResultsViewModeTable
          trends={trends}
          isLoadingTrends={isLoadingTrends}
          bookDetailsWorkMirror={bookDetailsWorkMirror}
          bookDetails={bookDetails}
          handleSortHeaderSelected={handleSortHeaderSelected}
          handleSetShowSnackBar={handleSetShowSnackBar}
          groupSelected={groupSelected}
          sortHeaderSelected={sortHeaderSelected}
          sortsSelected={sortsSelected}
          tagSelected={tagSelected}
          handleBookSelected={handleBookSelected}
          bookSelected={bookSelected}
          searchValue={searchValue}
          isLoadingBookDetails={isLoadingBookDetails}
          isLoadingBookDetailsWorkMirror={isLoadingBookDetailsWorkMirror}
        />
      )
    default:
      return <div>Pas de mode vue sélectionné</div>
  }
}

export default AudiencesResultsBody
