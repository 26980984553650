import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { AnalyticsDetailsStateInterface } from '../interfaces/analytics-details.state.interface'
import {
  analyticsDetailsStateGetB2BBookByEanId,
  analyticsDetailsStateGetBookReco,
  analyticsDetailsStateGetEditionsByEanIdAction,
  getReportDetailsBookDetailsByEanId,
  getWMReportBookReco,
  patchUserPermissionToShareReport,
  putPermissionReportByIdUserById,
  reportDetailsStateGetReportByIdAction,
  reportDetailsStatePatchReportByIdChangeOwnerAction,
  reportDetailsStatePostNewReport,
  reportDetailsStatePutReportDetailsByIdAction,
} from '../actions/analytics-details.actions'
import { ReportInterface } from '../../core/interface/report/report.interface'
import { WM_REPORT_SIZE_MAX_BOOK_SELECTION } from '../../core/config/reports/report-details.config'
import { ReportInputInterface } from '../../core/interface/report/report-input.interface'
import { ReportInputTypeEnum } from '../../core/enums/report-input-type.enum'
import { ReportOutputInterface } from '../../core/interface/report/report-output.interface'
import { ReportOutputTypeEnum } from '../../core/enums/report-output-type.enum'
import { WorkMirrorBookInterface } from '../../core/interface/report/work-mirror-book.interface'
import { BookInterface } from '../../core/interface/book.interface'
import { ReportTypeEnum } from '../../core/enums/report-type.enum'
import { getReportBaseCreation } from '../../core/config/reports/new-report.config'
import { PermissionEnum } from '../../core/enums/permission.enum'
import { BookDetailResponseInterface } from '../../core/interface/rank/book-detail.interface'

const initialState: AnalyticsDetailsStateInterface = {
  isLoading: false,
  isLoadingReloadOutput: false,
  isLoadingSaveReport: false,
  cancelTokenSaveNewReport: null,
  cancelTokenUpdateReport: null,
  reportDetails: null,
  reportDetailsError: false,
  reportName: '',
  booksInput: Array(WM_REPORT_SIZE_MAX_BOOK_SELECTION).fill(null),
  booksOutputConstellation: [],
  booksOutputDetails: [],

  bookDetailsEanId: null,
  isLoadingBookDetails: false,
  cancelTokenGetBookDetails: null,
  bookDetailsOutputSelected: null,

  // NEW
  isLoadingMirrorWorkDetailsB2bBook: false,
  mirrorWorkDetailsB2bBook: null,
  cancelTokenGetB2BBookByEanId: null,

  isLoadingMirrorWorkDetailsEditions: false,
  mirrorWorkDetailsEditions: [],
  cancelTokenGetEditionsByEanId: null,

  isLoadingMirrorWorkDetailsBookReco: false,
  mirrorWorkDetailsBookReco: [],
  cancelTokenGetBookReco: null,
}

export const reportDetailsSlice = createSlice({
  name: 'reportDetailsState',
  initialState,
  reducers: {
    reinitializeReportDetails: (
      state: AnalyticsDetailsStateInterface,
    ): AnalyticsDetailsStateInterface => ({
      ...state,
      ...initialState,
    }),
    setReportDetailsReportName: (
      state: AnalyticsDetailsStateInterface,
      action: PayloadAction<string>,
    ): AnalyticsDetailsStateInterface => {
      return {
        ...state,
        reportName: action.payload,
      }
    },
    setBookSelected: (
      state: AnalyticsDetailsStateInterface,
      action: PayloadAction<Array<BookInterface | null>>,
    ): AnalyticsDetailsStateInterface => {
      return {
        ...state,
        booksInput: action.payload,
      }
    },
    setReportDetailsBookDetailsEanId: (
      state: AnalyticsDetailsStateInterface,
      action: PayloadAction<number | null>,
    ): AnalyticsDetailsStateInterface => {
      return {
        ...state,
        bookDetailsEanId:
          state.bookDetailsEanId === action.payload ? null : action.payload,
      }
    },
    setReportDetailsReportToCreate: (
      state: AnalyticsDetailsStateInterface,
      action: PayloadAction<ReportTypeEnum>,
    ): AnalyticsDetailsStateInterface => {
      const reportType = action.payload
      const newReport = getReportBaseCreation(reportType) as ReportInterface

      return {
        ...state,
        reportDetails: {
          ...newReport,
          permission: PermissionEnum.EDIT,
        },
        reportName: newReport.name,
        booksOutputConstellation: [],
        booksOutputDetails: [],
        booksInput: Array(WM_REPORT_SIZE_MAX_BOOK_SELECTION)
          .fill(null)
          .map((value) => value),
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        reportDetailsStateGetReportByIdAction.pending,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoading: true,
          reportDetails: null,
          reportDetailsError: false,
          booksInput: Array(WM_REPORT_SIZE_MAX_BOOK_SELECTION)
            .fill(null)
            .map((value) => value),
          reportName: '',
          booksOutputConstellation: [],
          booksOutputDetails: [],
        }),
      )
      .addCase(
        reportDetailsStateGetReportByIdAction.fulfilled,
        (
          state: AnalyticsDetailsStateInterface,
          action: PayloadAction<ReportInterface>,
        ): AnalyticsDetailsStateInterface => {
          const { inputs, name } = action.payload
          let newState = {
            ...state,
            reportDetails: action.payload,
            reportName: name,
          }

          const input = inputs.find(
            (input: ReportInputInterface) =>
              input.type === ReportInputTypeEnum.BOOKS,
          )
          if (input) {
            const { books, outputs } = input

            const booksInput = Array(WM_REPORT_SIZE_MAX_BOOK_SELECTION)
              .fill(null)
              .map((_, index: number) => (books && books[index]) || null)

            const outputWorkMirror = outputs.find(
              (output: ReportOutputInterface) =>
                output.type === ReportOutputTypeEnum.WORK_MIRROR,
            )

            const booksOutput =
              (outputWorkMirror &&
                outputWorkMirror.work_mirror_books?.map(
                  (workMirrorBook: WorkMirrorBookInterface, index: number) => ({
                    ...workMirrorBook?.book,
                    distance: workMirrorBook.distance,
                    popularity: workMirrorBook.popularity || 0,
                    position: index + 1,
                    show_in_table: workMirrorBook.show_in_table,
                    edge_to_center: workMirrorBook.edge_to_center,
                    edges: workMirrorBook.edges,
                  }),
                )) ||
              []

            const uniqEdges = new Set()

            booksOutput.forEach((value: BookInterface) => {
              value.edges?.forEach((edge) => uniqEdges.add(edge))
            })

            newState = {
              ...newState,
              reportDetails: action.payload,
              reportDetailsError: false,
              booksInput,
              booksOutputConstellation: booksOutput
                .filter(
                  (book) => book.edge_to_center || uniqEdges.has(book.id_ean),
                )
                .map((book: BookInterface, index: number) => ({
                  ...book,
                  position: index + 1,
                  popularity: book.popularity || 0,
                  distance: book.distance || 0,
                })),
              booksOutputDetails: booksOutput
                .filter((book) => book.show_in_table)
                .map((book: BookInterface, index: number) => ({
                  ...book,
                  position: index + 1,
                  popularity: book.popularity || 0,
                  distance: book.distance || 0,
                })),
              reportName: name,
            }
          }

          return {
            ...state,
            ...newState,
            isLoading: false,
          }
        },
      )
      .addCase(
        reportDetailsStateGetReportByIdAction.rejected,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoading: false,
          reportDetails: null,
          reportDetailsError: true,
          booksInput: Array(WM_REPORT_SIZE_MAX_BOOK_SELECTION).fill(null),
          booksOutputConstellation: [],
          booksOutputDetails: [],
        }),
      )
      .addCase(
        putPermissionReportByIdUserById.pending,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoading: true,
        }),
      )
      .addCase(
        putPermissionReportByIdUserById.fulfilled,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoading: false,
        }),
      )
      .addCase(
        putPermissionReportByIdUserById.rejected,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoading: false,
        }),
      )
      .addCase(
        patchUserPermissionToShareReport.pending,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoading: true,
        }),
      )
      .addCase(
        patchUserPermissionToShareReport.fulfilled,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoading: false,
        }),
      )
      .addCase(
        patchUserPermissionToShareReport.rejected,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoading: false,
        }),
      )
      .addCase(
        getWMReportBookReco.pending,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoadingReloadOutput: true,
          booksOutputConstellation: [],
          booksOutputDetails: [],
          bookDetailsEanId: null,
        }),
      )
      .addCase(
        getWMReportBookReco.fulfilled,
        (
          state: AnalyticsDetailsStateInterface,
          action: PayloadAction<BookInterface[]>,
        ): AnalyticsDetailsStateInterface => {
          const uniqEdges = new Set()

          action.payload.forEach((value: BookInterface) => {
            value.edges?.forEach((edge) => uniqEdges.add(edge))
          })

          return {
            ...state,
            isLoadingReloadOutput: false,
            booksOutputConstellation: action.payload
              .filter(
                (book) => book.edge_to_center || uniqEdges.has(book.id_ean),
              )
              .map((book: BookInterface, index: number) => ({
                ...book,
                position: index + 1,
                popularity: book.popularity || 0,
                distance: book.distance || 0,
              })),
            booksOutputDetails: action.payload
              .filter((book) => book.show_in_table)
              .map((book: BookInterface, index: number) => ({
                ...book,
                position: index + 1,
                popularity: book.popularity || 0,
                distance: book.distance || 0,
              })),
          }
        },
      )
      .addCase(
        getWMReportBookReco.rejected,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoadingReloadOutput: false,
          booksOutputConstellation: [],
          booksOutputDetails: [],
        }),
      )
      .addCase(
        getReportDetailsBookDetailsByEanId.pending,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => {
          const { cancelTokenGetBookDetails } = state
          cancelTokenGetBookDetails && cancelTokenGetBookDetails.cancel()

          return {
            ...state,
            isLoadingBookDetails: true,
            bookDetailsOutputSelected: null,
            cancelTokenGetBookDetails: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        getReportDetailsBookDetailsByEanId.fulfilled,
        (
          state: AnalyticsDetailsStateInterface,
          action: PayloadAction<any>,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoadingBookDetails: false,
          bookDetailsOutputSelected: action.payload,
        }),
      )
      .addCase(
        getReportDetailsBookDetailsByEanId.rejected,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => {
          return {
            ...state,
            isLoadingBookDetails: false,
            bookDetailsOutputSelected: null,
          }
        },
      )
      .addCase(
        reportDetailsStatePatchReportByIdChangeOwnerAction.pending,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
        }),
      )
      .addCase(
        reportDetailsStatePatchReportByIdChangeOwnerAction.fulfilled,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
        }),
      )
      .addCase(
        reportDetailsStatePatchReportByIdChangeOwnerAction.rejected,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
        }),
      )
      // NEW
      .addCase(
        analyticsDetailsStateGetEditionsByEanIdAction.pending,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => {
          const { cancelTokenGetEditionsByEanId } = state
          if (cancelTokenGetEditionsByEanId) {
            cancelTokenGetEditionsByEanId.cancel()
          }

          return {
            ...state,
            isLoadingMirrorWorkDetailsEditions: true,
            mirrorWorkDetailsEditions: [],
            cancelTokenGetEditionsByEanId: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        analyticsDetailsStateGetEditionsByEanIdAction.fulfilled,
        (
          state: AnalyticsDetailsStateInterface,
          action: PayloadAction<BookInterface[]>,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoadingMirrorWorkDetailsEditions: false,
          mirrorWorkDetailsEditions: action.payload,
        }),
      )
      .addCase(
        analyticsDetailsStateGetEditionsByEanIdAction.rejected,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          mirrorWorkDetailsEditions: [],
          isLoadingMirrorWorkDetailsEditions: false,
        }),
      )
      .addCase(
        analyticsDetailsStateGetB2BBookByEanId.pending,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => {
          const { cancelTokenGetB2BBookByEanId } = state
          if (cancelTokenGetB2BBookByEanId) {
            cancelTokenGetB2BBookByEanId.cancel()
          }

          return {
            ...state,
            isLoadingMirrorWorkDetailsB2bBook: true,
            mirrorWorkDetailsB2bBook: null,
            cancelTokenGetB2BBookByEanId: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        analyticsDetailsStateGetB2BBookByEanId.fulfilled,
        (
          state: AnalyticsDetailsStateInterface,
          action: PayloadAction<BookDetailResponseInterface>,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoadingMirrorWorkDetailsB2bBook: false,
          mirrorWorkDetailsB2bBook: action.payload,
        }),
      )
      .addCase(
        analyticsDetailsStateGetB2BBookByEanId.rejected,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoadingMirrorWorkDetailsB2bBook: false,
          mirrorWorkDetailsB2bBook: null,
        }),
      )
      .addCase(
        analyticsDetailsStateGetBookReco.pending,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => {
          const { cancelTokenGetBookReco } = state
          if (cancelTokenGetBookReco) {
            cancelTokenGetBookReco.cancel()
          }

          return {
            ...state,
            isLoadingMirrorWorkDetailsBookReco: true,
            mirrorWorkDetailsBookReco: [],
            cancelTokenGetBookReco: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        analyticsDetailsStateGetBookReco.fulfilled,
        (
          state: AnalyticsDetailsStateInterface,
          action: PayloadAction<BookInterface[]>,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoadingMirrorWorkDetailsBookReco: false,
          mirrorWorkDetailsBookReco: action.payload,
        }),
      )
      .addCase(
        analyticsDetailsStateGetBookReco.rejected,
        (
          state: AnalyticsDetailsStateInterface,
        ): AnalyticsDetailsStateInterface => ({
          ...state,
          isLoadingMirrorWorkDetailsBookReco: false,
          mirrorWorkDetailsBookReco: [],
        }),
      )
      .addCase(
        reportDetailsStatePostNewReport.pending,
        (state: AnalyticsDetailsStateInterface) => {
          const { cancelTokenSaveNewReport } = state
          cancelTokenSaveNewReport && cancelTokenSaveNewReport.cancel()
          return {
            ...state,
            isLoadingSaveReport: true,
            cancelTokenSaveNewReport: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        reportDetailsStatePostNewReport.fulfilled,
        (state: AnalyticsDetailsStateInterface) => ({
          ...state,
          isLoadingSaveReport: false,
        }),
      )
      .addCase(
        reportDetailsStatePostNewReport.rejected,
        (state: AnalyticsDetailsStateInterface) => ({
          ...state,
          isLoadingSaveReport: false,
        }),
      )
      .addCase(
        reportDetailsStatePutReportDetailsByIdAction.pending,
        (state: AnalyticsDetailsStateInterface) => {
          const { cancelTokenUpdateReport } = state
          cancelTokenUpdateReport && cancelTokenUpdateReport.cancel()
          return {
            ...state,
            isLoadingSaveReport: true,
            cancelTokenUpdateReport: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        reportDetailsStatePutReportDetailsByIdAction.fulfilled,
        (state: AnalyticsDetailsStateInterface) => ({
          ...state,
          isLoadingSaveReport: false,
        }),
      )
      .addCase(
        reportDetailsStatePutReportDetailsByIdAction.rejected,
        (state: AnalyticsDetailsStateInterface) => ({
          ...state,
          isLoadingSaveReport: false,
        }),
      )
  },
})

export const {
  reinitializeReportDetails,
  setBookSelected,
  setReportDetailsReportName,
  setReportDetailsBookDetailsEanId,
  setReportDetailsReportToCreate,
} = reportDetailsSlice.actions

export const analyticsDetailsReducer = reportDetailsSlice.reducer
