import GlCellHeader from '../../../../components/share/table/GlCellHeader'
import { SortDirectionEnum } from '../../../enums/sort-direction.enum'
import GlCellHeaderV3 from '../../../../components/share/table/GlCellHeaderV3'

const TableAnalyticsHeader = () => (
  <div className="flex-none flex flex-row">
    <GlCellHeaderV3
      className="min-w-[112px] max-w-[112px]"
      label=""
      selected={false}
      sortValue={SortDirectionEnum.EMPTY}
    />
    <GlCellHeaderV3
      className="min-w-[260px]  flex-1"
      label="Nom"
      selected={false}
      sortValue={SortDirectionEnum.EMPTY}
    />
    <GlCellHeaderV3
      className="min-w-[120px] max-w-[260px]"
      label="Dernière modification"
      selected={false}
      sortValue={SortDirectionEnum.EMPTY}
    />
    <GlCellHeaderV3
      className="min-w-[120px] max-w-[260px]"
      label="Créée le"
      selected={false}
      sortValue={SortDirectionEnum.EMPTY}
    />

    <GlCellHeaderV3
      className="min-w-[120px] max-w-[260px]"
      label="Auteur de l'analytics"
      selected={false}
      sortValue={SortDirectionEnum.EMPTY}
    />
    <GlCellHeaderV3
      className="min-w-[48px] max-w-[48px]"
      label=""
      selected={false}
      sortValue={SortDirectionEnum.EMPTY}
    />
  </div>
)

export default TableAnalyticsHeader
