import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { getReportByIdService } from '../../core/api/services/bookmetrie/analytics/get-report-by-id.service'
import { putPermissionReportToUserService } from '../../core/api/services/bookmetrie/share/put-permission-report-to-user.service'
import { PermissionEnum } from '../../core/enums/permission.enum'
import { deleteUserToShareReportService } from '../../core/api/services/bookmetrie/share/delete-user-to-share-report.service'
import { patchUserPermissionToShareReportService } from '../../core/api/services/bookmetrie/share/patch-user-permission-to-share-report.service'
import { BookInterface } from '../../core/interface/book.interface'
import getBookRecoService from '../../core/api/services/bookmetrie/get-book-reco.service'
import { WM_REPORT_SIZE_BOOKS_OUTPUT } from '../../core/config/reports/report-details.config'
import { RootState } from '../store'
import { getB2bBookDetailsByEanIdService } from '../../core/api/services/others/get-b2b-book-details-by-ean-id.service'
import { getBookByIdEditionsService } from '../../core/api/services/bookmetrie/get-book-by-id-editions.service'
import { setReportDetailsBookDetailsEanId } from '../reducers/analytics-details.reducer'
import { putReportByIdService } from '../../core/api/services/bookmetrie/analytics/put-report-by-id.service'
import { PutReportModel } from '../../core/model/put-report.model'
import { patchReportChangeOwnerService } from '../../core/api/services/bookmetrie/share/patch-report-change-owner.service'
import postNewReportService from '../../core/api/services/bookmetrie/analytics/post-new-report.service'
import { NewReportInterface } from '../../core/interface/report/new-report.interface'
import { PostReportModel } from '../../core/model/post-report.model'
import {
  ANALYTICS_DETAILS_GET_B2B_BOOK_BY_EAN_ID_ACTION,
  ANALYTICS_DETAILS_GET_BOOK_RECO_ACTION,
  ANALYTICS_DETAILS_GET_EDITIONS_BY_EAN_ID_ACTION,
} from '../prefix-actions.config'

export const reportDetailsStateGetReportByIdAction = createAsyncThunk(
  'report-details/get-report-details-by-id',
  async ({ reportId }: { reportId: string }): Promise<any> => {
    try {
      const { data } = await getReportByIdService(reportId)
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const reportDetailsStatePutReportDetailsByIdAction = createAsyncThunk(
  'report-details/put-report-details-by-id',
  async (
    { report }: { report: PutReportModel },
    { getState },
  ): Promise<any> => {
    try {
      const { reportDetailsState } = getState() as RootState
      const { cancelTokenUpdateReport } = reportDetailsState

      const { data } = await putReportByIdService(
        report,
        cancelTokenUpdateReport?.token,
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const getWMReportBookReco = createAsyncThunk(
  'report-details/get-book-reco',
  async ({ books }: { books: Array<BookInterface | null> }): Promise<any> => {
    try {
      const eanIds = books
        .filter((book: BookInterface | null) => book !== null)
        .map((book: BookInterface | null) => book && book.id_ean) as number[]
      if (eanIds.length) {
        const { data } = await getBookRecoService({
          eanIds,
          nbrecos: WM_REPORT_SIZE_BOOKS_OUTPUT,
        })
        return data
      }
      return []
    } catch (error) {
      throw new Error()
    }
  },
)

export const putPermissionReportByIdUserById = createAsyncThunk(
  'report-details/put-permission-report-by-id-user-by-id',
  async ({
    reportId,
    userId,
    permission,
  }: {
    reportId: string
    userId: string
    permission: PermissionEnum
  }): Promise<any> => {
    try {
      const { data } = await putPermissionReportToUserService(
        reportId,
        userId,
        permission,
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const deleteUserToShareReport = createAsyncThunk(
  'report-details/delete-user-to-share-report',
  async ({
    reportId,
    userId,
  }: {
    reportId: string
    userId: string
  }): Promise<any> => {
    try {
      const { data } = await deleteUserToShareReportService(reportId, userId)
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const patchUserPermissionToShareReport = createAsyncThunk(
  'report-details/patch-user-permission-to-share-report',
  async ({
    reportId,
    newData,
  }: {
    reportId: string
    newData: {
      users: string[]
      permission: PermissionEnum
      message: string
    }
  }): Promise<any> => {
    try {
      const { data } = await patchUserPermissionToShareReportService(
        reportId,
        newData,
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const reportDetailsStatePatchReportByIdChangeOwnerAction =
  createAsyncThunk(
    'report-details/patch-report-by-id-change-owner',
    async ({
      reportId,
      ownerId,
    }: {
      reportId: string
      ownerId: string
    }): Promise<any> => {
      try {
        const { data } = await patchReportChangeOwnerService({
          reportId,
          ownerId,
        })
        return data
      } catch (error) {
        throw new Error()
      }
    },
  )
export const reportDetailsStatePostNewReport = createAsyncThunk(
  'report-details/post-new-report',
  async (
    { report }: { report: PostReportModel },
    { getState },
  ): Promise<any> => {
    try {
      const { reportDetailsState } = getState() as RootState
      const { cancelTokenSaveNewReport } = reportDetailsState

      const { data } = await postNewReportService(
        report as NewReportInterface,
        cancelTokenSaveNewReport?.token,
      )

      return data
    } catch (error) {
      throw new Error('')
    }
  },
)

// TODO To remove
export const getReportDetailsBookDetailsByEanId = createAsyncThunk(
  'report-details/get-book-detail-by-ean-id',
  async (eanId: number, { dispatch, getState }): Promise<any> => {
    try {
      const { bookDetailsEanId, cancelTokenGetBookDetails } = (
        getState() as RootState
      ).reportDetailsState

      if (bookDetailsEanId !== eanId) {
        dispatch(setReportDetailsBookDetailsEanId(eanId))

        const { data: editions } = await getBookByIdEditionsService(
          eanId,
          cancelTokenGetBookDetails?.token,
        )

        const { data: bookDetails } = await getB2bBookDetailsByEanIdService(
          eanId,
          cancelTokenGetBookDetails?.token,
        )

        const { data: booksReco } = await getBookRecoService(
          {
            eanIds: [eanId],
            nbrecos: 10,
          },
          cancelTokenGetBookDetails?.token,
        )
        bookDetails.editionOthers = editions || []

        bookDetails.booksReco = booksReco
        return bookDetails
      }
      dispatch(setReportDetailsBookDetailsEanId(null))
      return null
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error()
      }
      throw new Error()
    }
  },
)

export const analyticsDetailsStateGetB2BBookByEanId = createAsyncThunk(
  ANALYTICS_DETAILS_GET_B2B_BOOK_BY_EAN_ID_ACTION,
  async (eanId: number, { getState }): Promise<any> => {
    try {
      const { reportDetailsState } = getState() as RootState
      const { cancelTokenGetB2BBookByEanId } = reportDetailsState

      const { data } = await getB2bBookDetailsByEanIdService(
        eanId,
        cancelTokenGetB2BBookByEanId?.token,
      )

      return data
    } catch (error) {
      throw new Error('')
    }
  },
)

export const analyticsDetailsStateGetBookReco = createAsyncThunk(
  ANALYTICS_DETAILS_GET_BOOK_RECO_ACTION,
  async (eanId: number, { getState }): Promise<any> => {
    try {
      const { reportDetailsState } = getState() as RootState
      const { cancelTokenGetBookReco } = reportDetailsState
      const { data } = await getBookRecoService(
        {
          eanIds: [eanId],
          nbrecos: 10,
        },
        cancelTokenGetBookReco?.token,
      )

      return data
    } catch (error) {
      throw new Error('')
    }
  },
)

export const analyticsDetailsStateGetEditionsByEanIdAction = createAsyncThunk(
  ANALYTICS_DETAILS_GET_EDITIONS_BY_EAN_ID_ACTION,
  async (eanId: number, { getState }): Promise<any> => {
    try {
      const { reportDetailsState } = getState() as RootState
      const { cancelTokenGetEditionsByEanId } = reportDetailsState

      const { data } = await getBookByIdEditionsService(
        eanId,
        cancelTokenGetEditionsByEanId?.token,
      )

      return data
    } catch (error) {
      throw new Error('')
    }
  },
)
