import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as WorkMirrorIcon } from '../../assets/icons/app/work-mirror.icon.svg'
import { ReactComponent as ReadershipProfilIcon } from '../../assets/icons/readership-profil-card.icon.svg'
import { ReactComponent as UniversIcon } from '../../assets/icons/app/univers.icon.svg'
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/app/left-chevron.icon.svg'
import { ReactComponent as CrossIcon } from '../../assets/icons/app/cross.icon.svg'
import { ReactComponent as ReadershipProfileScreenShot } from '../../assets/images/readership-profile-screen-shot.svg'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import { AnalyticsTypeEnum } from '../../core/enums/analytics-type.enum'
import useReadershipProfileGetMySelfAvailableTokenHook from '../../core/hooks/useReadershipProfileGetMySelfAvailableTokenHook'
import GlSpinner from '../share/GlSpinner'
import { useAppDispatch } from '../../store/hooks'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'

const DialogTitle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #252525;
  font-variant-numeric: lining-nums proportional-nums;
  font-weight: 500;
  line-height: 32px; /* 160% */
`

const ButtonActionStyle = styled.button`
  background-color: #252525;
  color: white;
  width: 100%;
  height: 48px;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;

  :hover {
    background-color: #252525cc;
  }

  :active {
    background-color: #252525e6;
  }

  :disabled {
    background-color: #252525e6;
  }

  &:disabled.not-available {
    border: 1px solid #252525;
    background-color: white;
    color: #252525;
  }
`

const TitleCardStyle = styled.div`
  color: #3b3b3b;
  text-align: center;
  font-family: Vollkorn, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 150% */
  letter-spacing: 0.48px;
`

const ContentCardStyle = styled.div`
  color: #252525;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const ExplainStyle = styled.div`
  color: #252525;
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const AnalyticsDashboardAddAnalyticsDialog = ({
  hasWorkMirrorsAnalyticsAccess,
  hasUniversAnalyticsAccess,
  hasReadershipProfileAnalyticsAccess,
  handleCloseDialog,
}: {
  hasWorkMirrorsAnalyticsAccess: boolean
  hasUniversAnalyticsAccess: boolean
  hasReadershipProfileAnalyticsAccess: boolean
  handleCloseDialog: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [explainReadershipProfile, setExplainReadershipProfile] =
    useState<boolean>(false)
  const onClickNewAnalytics = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    navigate(
      buildRelativePath([
        PathConfig.BOOKMETRIE,
        PathConfig.ANALYTICS,
        `${PathConfig.NEW}?type=${AnalyticsTypeEnum.WORK_MIRROR}`,
      ]),
    )
  }
  const dispatch = useAppDispatch()

  const onClickReturnButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setExplainReadershipProfile(false)
  }
  const onClickKnowMoreButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    setExplainReadershipProfile(true)
  }
  const onClickCloseDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleCloseDialog()
  }

  const {
    state: { isLoadingMySelf, availableTokens },
  } = useReadershipProfileGetMySelfAvailableTokenHook()

  const addNewReaderShipProfile = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    if (!availableTokens) {
      dispatch(setShowSnackBar(t('notEnoughReadershipProfileToken')))
    }
    navigate(
      buildRelativePath([
        PathConfig.BOOKMETRIE,
        PathConfig.ANALYTICS,
        `${PathConfig.NEW}?type=${AnalyticsTypeEnum.READERSHIP_PROFILE}`,
      ]),
    )
  }

  return (
    <div
      className="flex flex-col gap-4"
      style={{
        width: 968,
        height: 660,
      }}
    >
      <DialogTitle className="relative">
        {(explainReadershipProfile && (
          <span>
            <button
              type="button"
              className="flex items-center justify-center gap-2"
              onClick={onClickReturnButton}
            >
              <LeftArrowIcon
                style={{
                  width: 20,
                  height: 20,
                }}
              />
              <span>{t('commons.buttons.back')}</span>
            </button>
          </span>
        )) || <span> {t('createNewAnalytics')}</span>}
        <button
          type="button"
          className="absolute top-2 right-2"
          onClick={onClickCloseDialog}
        >
          <CrossIcon
            style={{
              width: 20,
              height: 20,
            }}
          />
        </button>
      </DialogTitle>
      {(explainReadershipProfile && (
        <div className="flex flex-row gap-8 h-full">
          <div
            className="basis-1/2 flex items-end justify-center"
            style={{
              borderRadius: 8,
              background:
                'linear-gradient(180deg, #7BBFFF 0%, rgba(123, 191, 255, 0.10) 100%)',
            }}
          >
            <ReadershipProfileScreenShot />
          </div>
          <div className="basis-1/2 flex flex-col gap-8">
            <TitleCardStyle className="flex justify-start">
              <span>{t('ReadershipProfileWhatsThePoint')}</span>
            </TitleCardStyle>
            <ExplainStyle
              dangerouslySetInnerHTML={{
                __html: t('ReadershipProfileWhatsThePoint1'),
              }}
            />{' '}
            <ExplainStyle
              dangerouslySetInnerHTML={{
                __html: t('ReadershipProfileWhatsThePoint2'),
              }}
            />{' '}
            <ExplainStyle
              dangerouslySetInnerHTML={{
                __html: t('ReadershipProfileWhatsThePoint3'),
              }}
            />
          </div>
        </div>
      )) || (
        <div className="flex flex-row gap-4 h-full">
          <div className="flex-1 flex flex-col gap-2">
            <div
              className="flex-auto flex flex-col items-center justify-between"
              style={{
                borderRadius: 8,
                background:
                  'linear-gradient(180deg, #86DAAD 0%, rgba(134, 218, 173, 0.10) 100%)',
              }}
            >
              <div className="flex flex-col my-[32px] items-center justify-center gap-4 p-2">
                <WorkMirrorIcon className="w-12 h-12" />
                <TitleCardStyle>{t('worksMirrors')}</TitleCardStyle>
                <ContentCardStyle>{t('workMirrorExplain')}</ContentCardStyle>
              </div>
            </div>
            <ButtonActionStyle
              type="button"
              onClick={onClickNewAnalytics}
              className="flex items-center justify-center"
              disabled={!hasWorkMirrorsAnalyticsAccess}
            >
              {(hasWorkMirrorsAnalyticsAccess && (
                <span>{t('illimited')}</span>
              )) || <span>{t('unlockTheFeature')}</span>}
            </ButtonActionStyle>
          </div>
          <div className="flex-1 flex flex-col gap-2">
            <div
              className="flex-auto flex flex-col items-center justify-between"
              style={{
                borderRadius: 8,
                background:
                  'linear-gradient(180deg, #7BBFFF 0%, rgba(123, 191, 255, 0.10) 100%)',
              }}
            >
              <div className="flex flex-col my-[32px] items-center justify-center gap-4  p-2">
                <ReadershipProfilIcon className="w-12 h-12 text-[#3B3B3B]" />
                <TitleCardStyle>{t('readership_profile')}</TitleCardStyle>
                <ContentCardStyle>
                  {t('readershipProfileExplain')}
                </ContentCardStyle>
                <button
                  type="button"
                  className="flex items-center justify-center gap-1"
                  onClick={onClickKnowMoreButton}
                >
                  <span className="font-bold">{t('KnowMore')}</span>
                </button>
              </div>
            </div>
            <ButtonActionStyle
              type="button"
              className="flex items-center justify-center"
              onClick={addNewReaderShipProfile}
              disabled={!hasReadershipProfileAnalyticsAccess || isLoadingMySelf}
            >
              {(hasReadershipProfileAnalyticsAccess && isLoadingMySelf && (
                <div className="flex items-center justify-center w-full h-full">
                  <GlSpinner />
                </div>
              )) || (
                <span>
                  {t('createReadershipProfile', { count: availableTokens })}
                </span>
              )}
            </ButtonActionStyle>
          </div>
          <div className="flex-1 flex flex-col gap-2">
            <div
              className="flex-auto flex flex-col items-center justify-between"
              style={{
                borderRadius: 8,
                background:
                  'linear-gradient(180deg, #BEBEBE 0%, rgba(190, 190, 190, 0.00) 100%)',
              }}
            >
              <div className="flex flex-col my-[32px] items-center justify-center gap-4  p-2">
                <UniversIcon className="w-12 h-12" />
                <TitleCardStyle>{t('univers')}</TitleCardStyle>
                <ContentCardStyle>{t('ComingSoon')}</ContentCardStyle>
              </div>
            </div>
            <ButtonActionStyle
              className="not-available flex items-center justify-center"
              disabled
            >
              <span>{t('ComingSoon')}</span>
            </ButtonActionStyle>
          </div>
        </div>
      )}
    </div>
  )
}

export default AnalyticsDashboardAddAnalyticsDialog
