import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BottomArrowIcon } from '../../assets/icons/app/bottom-arrow-light.icon.svg'
import { ReactComponent as SelectedIcon } from '../../assets/icons/selected.icon.svg'
import { TagGroupInterface } from '../../core/interface/TagGroupInterface'

const ContainerStyle = styled.div`
  max-width: 250px;
  min-width: 200px;
`
const VerticalWheelStyle = styled.div`
  transform: ${({ selected }: { selected: number }) =>
    `translateY(${-selected * 32}px)`};
  -webkit-transition: transform 500ms ease-in-out;
  -moz-transition: transform 500ms ease-in-out;
  -o-transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
`
const BoxLabelStyle = styled.span`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #252525;
`

const ListStyle = styled.ul`
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 0 20px rgba(0, 0, 0, 0.1);
`
const ItemStyle = styled.li`
  &:hover {
    background-color: #f4f4f4;
  }
  &:active {
    background-color: #e3e3e3;
  }
`

const AudiencesSearchTagsMenu = ({
  tagGroups,
  tagGroupSelected,
  handleChangeSelected,
}: {
  tagGroups: TagGroupInterface[]
  tagGroupSelected: string
  handleChangeSelected: (tagGroup: string) => void
}) => {
  const { t } = useTranslation()
  const [selectionOpen, setSelectionOpen] = useState(false)
  const [mouseEnterPermission, setMouseEnterPermission] = useState(true)

  const onClickList = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    !selectionOpen && setSelectionOpen(true)
  }

  return (
    <ContainerStyle
      className="relative h-8 cursor-pointer grow"
      onMouseEnter={() => mouseEnterPermission && setSelectionOpen(true)}
      onMouseLeave={() => {
        setSelectionOpen(false)
        setMouseEnterPermission(true)
      }}
      onClick={onClickList}
    >
      <div className="w-full h-full border-b border-[#e3e3e3] flex justify-end items-center p-2">
        <BottomArrowIcon className="w-3.5 h-3.5 text-[#b2b2b2]" />
      </div>
      <VerticalWheelStyle
        style={{ opacity: selectionOpen ? 0 : 1 }}
        selected={tagGroups.findIndex(
          (tagGroupItem: TagGroupInterface) =>
            tagGroupItem.id === tagGroupSelected,
        )}
        className="flex-col w-full absolute top-0 left-0 px-2"
      >
        {tagGroups.map((tagGroup: TagGroupInterface) => (
          <div
            style={{ opacity: tagGroupSelected === tagGroup.id ? 1 : 0.65 }}
            className="flex h-8 self-center"
            key={nanoid(5)}
          >
            <BoxLabelStyle className="flex flex-row gap-2 items-center">
              {t(tagGroup.name)}
            </BoxLabelStyle>
          </div>
        ))}
      </VerticalWheelStyle>
      <ListStyle
        className="absolute top-0 left-0 bg-white  flex-col w-full"
        style={{ display: selectionOpen ? 'flex' : 'none' }}
      >
        {tagGroups.map((tagGroup: TagGroupInterface) => (
          <ItemStyle
            className="h-8 flex p-2 flex-row justify-between items-center"
            key={nanoid(5)}
            onClick={(event: React.MouseEvent) => {
              event.preventDefault()
              handleChangeSelected(tagGroup.id)
              setMouseEnterPermission(false)
              setSelectionOpen(false)
            }}
          >
            <BoxLabelStyle className="flex flex-row gap-2 items-center">
              {t(tagGroup.name)}
            </BoxLabelStyle>
            {tagGroup.id === tagGroupSelected && (
              <div className="w-4 h-4 bg-[#595959] rounded-full flex items-center justify-center">
                <SelectedIcon className="w-4 h-4" />
              </div>
            )}
          </ItemStyle>
        ))}
      </ListStyle>
    </ContainerStyle>
  )
}

export default AudiencesSearchTagsMenu
