import React, { Fragment } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import '../../../../../styles/components/Calendar.scss'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import { PeriodOptionsInterface } from '../../../../../core/interface/rank/period.interface'
import { DATA_START_DATE } from '../../../../../core/config/RankConfig'
import { PeriodTypeEnum } from '../../../../../core/enums/period-type.enum'
import { firstCharToUpperCaseHelper } from '../../../../../core/helpers/first-char-to-upper-case.helper'
import { ReactComponent as CalendarIcon } from '../../../../../assets/icons/app/calendar.icon.svg'
import { ReactComponent as LeftArrowIcon } from '../../../../../assets/icons/app/left-arrow.icon.svg'
import { ReactComponent as RightArrowIcon } from '../../../../../assets/icons/app/right-arrow.icon.svg'
import { ReactComponent as SelectedIcon } from '../../../../../assets/icons/selected.icon.svg'

import GlHDivider from '../../../../share/GlHDivider'

declare type StepGenerator = 'month' | 'quarter' | 'year'

const TitleSelectStyle = styled.div`
  text-align: center;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 11px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #b2b2b2;
  padding: 8px 12px;
`

interface OptionGeneratorInterface {
  stepGenerator: StepGenerator
  format: string
}
const optionsGenerator = ({
  stepGenerator,
  format,
}: OptionGeneratorInterface): PeriodOptionsInterface[] => {
  const options: PeriodOptionsInterface[] = []
  let isStart = true
  let current = dayjs().subtract(1, stepGenerator)

  while (current.isAfter(DATA_START_DATE)) {
    options.push({
      label: firstCharToUpperCaseHelper(current.format(format)),
      value: {
        start: current.startOf(stepGenerator).toISOString(),
        end: current.endOf(stepGenerator).toISOString(),
      },
      default: isStart,
      year: current.year(),
    })
    current = current.subtract(1, stepGenerator)
    isStart = false
  }
  return options
}

const generatorConfig: {
  [key: string]: OptionGeneratorInterface
} = {
  [PeriodTypeEnum.YEAR]: {
    stepGenerator: 'year',
    format: 'YYYY',
  },
  [PeriodTypeEnum.QUARTER]: {
    stepGenerator: 'quarter',
    format: '[trimestre] Q',
  },
  [PeriodTypeEnum.MONTH]: {
    stepGenerator: 'month',
    format: 'MMMM',
  },
}

const AudiencesResultsPeriodTypeMenu = ({
  periodTypeSelected,
  startDateSelected,
  endDateSelected,
  handleChangeQuery,
}: {
  periodTypeSelected: PeriodTypeEnum
  startDateSelected: string
  endDateSelected: string
  handleChangeQuery: (key: any, value: any) => void
}) => {
  const options =
    (periodTypeSelected &&
      optionsGenerator(generatorConfig[periodTypeSelected])) ||
    []
  const displayGroupLabel = (
    options: PeriodOptionsInterface[],
    index: number,
  ): boolean => {
    if (
      periodTypeSelected === PeriodTypeEnum.QUARTER ||
      periodTypeSelected === PeriodTypeEnum.MONTH
    ) {
      return index === 0 || options[index - 1].year !== options[index].year
    }

    return false
  }

  const getLabelValueSelected = () => {
    const valueSelected = options.find(
      (option: PeriodOptionsInterface) =>
        dayjs(startDateSelected).isSame(dayjs(option.value.start)) &&
        dayjs(endDateSelected).isSame(dayjs(option.value.end)),
    )

    if (periodTypeSelected !== PeriodTypeEnum.YEAR) {
      return `${valueSelected?.label} ${valueSelected?.year}`
    }

    return valueSelected?.year
  }

  const selectAfterOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const findIndex = options.findIndex(
      (option: PeriodOptionsInterface) =>
        dayjs(startDateSelected).isSame(dayjs(option.value.start)) &&
        dayjs(endDateSelected).isSame(dayjs(option.value.end)),
    )

    if (findIndex >= 0 && options[findIndex - 1]) {
      handleChangeQuery('date', options[findIndex - 1].value)
    }
  }

  const hasBefore = (): boolean => {
    const findIndex = options.findIndex(
      (option: PeriodOptionsInterface) =>
        dayjs(startDateSelected).isSame(dayjs(option.value.start)) &&
        dayjs(endDateSelected).isSame(dayjs(option.value.end)),
    )

    return findIndex >= 0 && !!options[findIndex + 1]
  }
  const hasAfter = (): boolean => {
    const findIndex = options.findIndex(
      (option: PeriodOptionsInterface) =>
        dayjs(startDateSelected).isSame(dayjs(option.value.start)) &&
        dayjs(endDateSelected).isSame(dayjs(option.value.end)),
    )

    return findIndex >= 0 && !!options[findIndex - 1]
  }
  const selectBeforeOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const findIndex = options.findIndex(
      (option: PeriodOptionsInterface) =>
        dayjs(startDateSelected).isSame(dayjs(option.value.start)) &&
        dayjs(endDateSelected).isSame(dayjs(option.value.end)),
    )
    if (findIndex >= 0 && options[findIndex + 1]) {
      handleChangeQuery('date', options[findIndex + 1].value)
    }
  }

  return (
    <div className="flex flex-col relative mb-2">
      <div className="flex flex-row justify-between items-center">
        <button
          type="button"
          className="disabled:text-[#e3e3e3] h-8 w-10 flex justify-center items-center hover:bg-[#f7f7f7]  active:bg-[#e3e3e3]"
          onClick={selectBeforeOption}
          disabled={!hasBefore()}
        >
          <LeftArrowIcon />
        </button>
        <div
          className={`
          w-full
          h-8
          flex
          flex-row
          gap-2
          justify-center
          items-center
          bg-white
        `}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault()
          }}
        >
          <CalendarIcon />
          <span>{getLabelValueSelected()}</span>
        </div>
        <button
          type="button"
          className="disabled:text-[#e3e3e3] h-8 w-10 flex justify-center items-center hover:bg-[#f7f7f7]  active:bg-[#e3e3e3]"
          onClick={selectAfterOption}
          disabled={!hasAfter()}
        >
          <RightArrowIcon />
        </button>
      </div>
      <div className="flex flex-col absolute top-[32px] w-full z-30 max-h-[196px]">
        <PerfectScrollbar>
          {options.map((option: PeriodOptionsInterface, index: number) => (
            <Fragment key={nanoid(5)}>
              {displayGroupLabel(options, index) && (
                <div
                  className={`
                    flex
                    flex-col
                    justify-center
                    bg-white 
                    w-full
                    ${index + 1 === options.length ? 'rounded-b-[4px]' : ''}
                `}
                >
                  <GlHDivider />
                  <TitleSelectStyle className="justify-center items-center">
                    <span>{option.year}</span>
                  </TitleSelectStyle>
                  <GlHDivider />
                </div>
              )}
              <div
                className={`
                  flex
                  flex-col
                  justify-center
                  bg-white 
                  w-full
                  ${index + 1 === options.length ? 'rounded-b-[4px]' : ''}
                `}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  event.preventDefault()
                  handleChangeQuery('date', option.value)
                }}
              >
                <div
                  className={`
                    flex
                    justify-between
                    h-7 
                    items-center
                    px-3
                    py-[4.5px]
                    ${
                      (dayjs(startDateSelected).isSame(
                        dayjs(option.value.start),
                      ) &&
                        dayjs(endDateSelected).isSame(
                          dayjs(option.value.end),
                        ) &&
                        'bg-[#e3e3e3]') ||
                      ''
                    }
                    hover:bg-[#f7f7f7]
                    active:bg-[#e3e3e3]
                  `}
                >
                  <div className="flex flex-row justify-center items-center text-center">
                    <div>{option.label}</div>
                  </div>
                  {dayjs(startDateSelected).isSame(dayjs(option.value.start)) &&
                    dayjs(endDateSelected).isSame(dayjs(option.value.end)) && (
                      <SelectedIcon />
                    )}
                </div>
              </div>
            </Fragment>
          ))}
        </PerfectScrollbar>
      </div>
    </div>
  )
}

export default AudiencesResultsPeriodTypeMenu
