import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource } from 'axios'
import {
  DynamicsResultsStateInterface,
  AudiencesResultsViewModeEnum,
} from '../interfaces/dynamics-results.state.interface'
import { SortDirectionEnum } from '../../core/enums/sort-direction.enum'
import {
  audiencesResultStatePostTopRequestSavedAction,
  audiencesResultsStateDeleteTopRequestSavedAction,
  audiencesResultsSateGetBookDetail,
  audiencesResultsStateGetBookDetailsConstellation,
  audiencesResultsStateGetTopRequestSavedAction,
  audiencesResultsStateGetTopsByCatalogId,
  audiencesResultsStatePutTopRequestSavedAction,
  audiencesResultStateGetBookDetailsData,
} from '../actions/dynamics-results.actions'
import { TrendV2Interface } from '../../core/interface/trend-v2.interface'
import { aggregateTrendsDataByColumnUtil } from '../../core/utils/aggregate-trends-data-by-column.util'
import {
  FILTER_KEY_AUTHORS,
  FILTER_KEY_EDITOR,
  FILTER_KEY_DELTA_RANK,
  FILTER_KEY_FORMAT,
  FILTER_KEY_SEGMENT1,
  FILTER_KEY_RANK,
  FILTER_KEY_SEGMENT2,
  FILTER_KEY_PUBLISHING_DATE,
} from '../../core/config/audiences/filters-items-audiences.config'
import { DateFilterTypeEnum } from '../../core/enums/date-filter-type.enum'
import { TrendsItemFilterActionInterface } from '../../core/interface/audiences/trends-item-filter-action.interface'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'
import { AnalyticInputInterface } from '../../core/interface/analytics/analytic-input.interface'

const initialState: DynamicsResultsStateInterface = {
  isLoading: false,
  viewMode: AudiencesResultsViewModeEnum.BILLS,
  // Catalogs
  cancelTokenGetCatalogs: null,
  catalogs: [],
  // Trends
  cancelTokenGetTrendsByCatalogId: null,
  isLoadingTrends: false,
  trends: [],
  // Data for actions
  trendsAggregateByColumn: null,
  // Filters Actions
  filtersActions: {
    [FILTER_KEY_DELTA_RANK]: [],
    [FILTER_KEY_AUTHORS]: [],
    [FILTER_KEY_EDITOR]: [],
    [FILTER_KEY_SEGMENT1]: [],
    [FILTER_KEY_SEGMENT2]: [],
    [FILTER_KEY_FORMAT]: [],
    [`${FILTER_KEY_PUBLISHING_DATE}_type`]: DateFilterTypeEnum.IN,
    [FILTER_KEY_PUBLISHING_DATE]: [null, null],
  },
  searchActions: '',
  sortHeaderSelected: {
    columnKey: FILTER_KEY_RANK,
    sortDirection: SortDirectionEnum.ASC,
  },
  sortsSelected: [],
  groupSelected: null,
  bookSelected: null,
  isLoadingBookDetails: false,
  bookDetails: null,
  cancelTokenBookDetails: null,
  isLoadingBookDetailsWorkMirror: false,
  cancelTokenBookDetailsWorkMirror: null,
  bookDetailsWorkMirror: null,
  isLoadingRequestsSaved: false,
  requestsSaved: { count: 0, next: null, previous: null, results: [] },
}

export const audiencesResultsSlice = createSlice({
  name: 'audiencesResultsState',
  initialState,
  reducers: {
    audiencesResultsSetViewMode: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<AudiencesResultsViewModeEnum>,
    ): DynamicsResultsStateInterface => ({
      ...state,
      viewMode: action.payload,
    }),
    audiencesResultsSetCancelTokenGetCatalogs: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<CancelTokenSource>,
    ): DynamicsResultsStateInterface => ({
      ...state,
      cancelTokenGetCatalogs: action.payload,
    }),
    reinitializeAudiencesResultsState: (
      state: DynamicsResultsStateInterface,
    ): DynamicsResultsStateInterface => ({
      ...state,
      ...initialState,
      viewMode: state.viewMode,
      catalogs: state.catalogs,
    }),
    audiencesResultsSetFiltersActions: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<any>,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        filtersActions: action.payload,
      }
    },
    audiencesResultsResetFilters: (
      state: DynamicsResultsStateInterface,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        filtersActions: initialState.filtersActions,
      }
    },
    audiencesResultsSetSearchActions: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<string>,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        searchActions: action.payload,
      }
    },
    audiencesResultsSetSortHeaderSelected: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<{
        columnKey: string
        sortDirection: SortDirectionEnum
      }>,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        sortHeaderSelected: action.payload,
      }
    },
    audiencesResultsSetGroupSelected: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<TrendsItemFilterActionInterface | null>,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        groupSelected: action.payload,
      }
    },
    audiencesResultsSetSortsSelected: (
      state: DynamicsResultsStateInterface,
      action: PayloadAction<any>,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        sortsSelected: action.payload,
      }
    },
    audiencesResultsResetAllFiltersActions: (
      state: DynamicsResultsStateInterface,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        sortsSelected: initialState.sortsSelected,
        groupSelected: initialState.groupSelected,
        filtersActions: initialState.filtersActions,
      }
    },
    audiencesResultsResetBookDetails: (
      state: DynamicsResultsStateInterface,
    ): DynamicsResultsStateInterface => {
      return {
        ...state,
        bookDetails: null,
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        audiencesResultsStateGetTopsByCatalogId.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => {
          const { cancelTokenGetTrendsByCatalogId } = state
          if (cancelTokenGetTrendsByCatalogId) {
            cancelTokenGetTrendsByCatalogId.cancel()
          }
          return {
            ...state,
            isLoadingTrends: true,
            cancelTokenGetTrendsByCatalogId: axios.CancelToken.source(),
            trends: [],
            trendsAggregateByColumn: null,
          }
        },
      )
      .addCase(
        audiencesResultsStateGetTopsByCatalogId.fulfilled,
        (
          state: DynamicsResultsStateInterface,
          action: PayloadAction<TrendV2Interface[]>,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingTrends: false,
          cancelTokenGetTrendsByCatalogId: null,
          trends: action.payload,
          trendsAggregateByColumn: aggregateTrendsDataByColumnUtil(
            action.payload,
          ),
        }),
      )
      .addCase(
        audiencesResultsStateGetTopsByCatalogId.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingTrends: false,
          cancelTokenGetTrendsByCatalogId: null,
          trends: [],
          trendsAggregateByColumn: null,
        }),
      )
      .addCase(
        audiencesResultStatePostTopRequestSavedAction.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: true,
        }),
      )
      .addCase(
        audiencesResultStatePostTopRequestSavedAction.fulfilled,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        audiencesResultStatePostTopRequestSavedAction.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        audiencesResultsStateGetTopRequestSavedAction.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: true,
          requestsSaved: initialState.requestsSaved,
        }),
      )
      .addCase(
        audiencesResultsStateGetTopRequestSavedAction.fulfilled,
        (
          state: DynamicsResultsStateInterface,
          action: PayloadAction<
            PaginationResponseInterface<TopRequestSavedInterface>
          >,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
          requestsSaved: action.payload,
        }),
      )
      .addCase(
        audiencesResultsStateGetTopRequestSavedAction.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
          requestsSaved: initialState.requestsSaved,
        }),
      )
      .addCase(
        audiencesResultsStatePutTopRequestSavedAction.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: true,
        }),
      )
      .addCase(
        audiencesResultsStatePutTopRequestSavedAction.fulfilled,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        audiencesResultsStatePutTopRequestSavedAction.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        audiencesResultsStateDeleteTopRequestSavedAction.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: true,
        }),
      )
      .addCase(
        audiencesResultsStateDeleteTopRequestSavedAction.fulfilled,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        audiencesResultsStateDeleteTopRequestSavedAction.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingRequestsSaved: false,
        }),
      )
      .addCase(
        audiencesResultsSateGetBookDetail.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => {
          const { cancelTokenBookDetails } = state
          if (cancelTokenBookDetails) {
            cancelTokenBookDetails.cancel()
          }
          return {
            ...state,
            isLoadingBookDetails: true,
            bookDetails: null,
            cancelTokenBookDetails: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        audiencesResultsSateGetBookDetail.fulfilled,
        (
          state: DynamicsResultsStateInterface,
          action: PayloadAction<any>,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingBookDetails: false,
          bookDetails: action.payload,
        }),
      )
      .addCase(
        audiencesResultsSateGetBookDetail.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingBookDetails: false,
          bookDetails: null,
        }),
      )
      .addCase(
        audiencesResultsStateGetBookDetailsConstellation.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => {
          const { cancelTokenBookDetailsWorkMirror } = state
          if (cancelTokenBookDetailsWorkMirror) {
            cancelTokenBookDetailsWorkMirror.cancel()
          }
          return {
            ...state,
            isLoadingBookDetailsWorkMirror: true,
            bookDetailsWorkMirror: null,
            cancelTokenBookDetailsWorkMirror: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        audiencesResultsStateGetBookDetailsConstellation.fulfilled,
        (
          state: DynamicsResultsStateInterface,
          action: PayloadAction<Partial<AnalyticInputInterface>>,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingBookDetailsWorkMirror: false,
          bookDetailsWorkMirror: action.payload,
        }),
      )
      .addCase(
        audiencesResultsStateGetBookDetailsConstellation.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          isLoadingBookDetailsWorkMirror: false,
          bookDetailsWorkMirror: null,
        }),
      )
      .addCase(
        audiencesResultStateGetBookDetailsData.pending,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          bookSelected: null,
        }),
      )
      .addCase(
        audiencesResultStateGetBookDetailsData.fulfilled,
        (
          state: DynamicsResultsStateInterface,
          action: PayloadAction<TrendV2Interface | null>,
        ): DynamicsResultsStateInterface => ({
          ...state,
          bookSelected: action.payload,
        }),
      )
      .addCase(
        audiencesResultStateGetBookDetailsData.rejected,
        (
          state: DynamicsResultsStateInterface,
        ): DynamicsResultsStateInterface => ({
          ...state,
          bookSelected: null,
        }),
      )
  },
})

export const {
  audiencesResultsSetViewMode,
  reinitializeAudiencesResultsState,
  audiencesResultsSetFiltersActions,
  audiencesResultsResetFilters,
  audiencesResultsSetSearchActions,
  audiencesResultsSetGroupSelected,
  audiencesResultsSetSortHeaderSelected,
  audiencesResultsSetSortsSelected,
  audiencesResultsResetAllFiltersActions,
} = audiencesResultsSlice.actions

export const dynamicsResultsReducer = audiencesResultsSlice.reducer
