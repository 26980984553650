export const WEB_APP_GET_CATALOGS_ACTION = 'web-app/get-catalogs-action'
export const WEB_APP_GET_HOME_TOP_CATALOGS_ACTION =
  'web-app/get-home-top-catalogs-action'
export const WEB_APP_GET_MY_SELF_ACTION = 'web-app/get-my-self-action'
export const WEB_APP_GET_TOP_TAG_GROUPS = 'web-app/get-top-tag-groups'
export const DASHBOARD_GET_TRENDS_BY_CATALOG_ID =
  'dashboard/get-trends-by-catalog-id'
export const DASHBOARD_GET_LAST_TOP_REQUEST_SAVE =
  'dashboard/get-last-top-request-saved'
export const DASHBOARD_GET_LAST_PRIVATE_REPORTS =
  'dashboard/get-last-private-reports'
export const INVITATION_GET_STATUS_BY_INVITATION_TOKEN =
  'invitation/get-status-by-invitation-token'
export const INVITATION_POST_ONBOARDING_INVITATION_ACTION =
  'invitation/post-onboarding-invitation-action'
export const ANALYTICS_DETAILS_GET_B2B_BOOK_BY_EAN_ID_ACTION =
  'analytics-details/get-b2b-book-by-ean-id'
export const ANALYTICS_DETAILS_GET_BOOK_RECO_ACTION =
  'analytics-details/get-book-reco'
export const ANALYTICS_DETAILS_GET_EDITIONS_BY_EAN_ID_ACTION =
  'analytics-details/get-editions-by-ean-id'
export const ANALYTICS_DETAILS_V2_STATE_GET_EDITIONS_BY_EAN_ID_ACTION =
  'analytics-details-v2/get-editions-by-ean-id'
export const ANALYTICS_DETAILS_V2_STATE_GET_BOOK_DETAIL_DATA_ACTION =
  'analytics-details-v2/get-book-detail-data'
export const ANALYTICS_DETAILS_V2_STATE_GET_BOOK_DETAIL_DATA_BY_EAN_ID_ACTION =
  'analytics-details-v2/get-book-detail-data-by-ean-id'
export const ANALYTICS_DETAILS_V2_STATE_GET_B2B_BOOK_BY_EAN_ID_ACTION =
  'analytics-details-v2/get-b2b-book-by-ean-id'
export const ANALYTICS_DETAILS_V2_STATE_POST_NEW_ANALYTIC_ACTION =
  'analytics-details-v2/post-new-analytic'
export const ANALYTICS_DETAILS_V2_STATE_BOOK_DETAILS_GET_BOOK_RECO_ACTION =
  'analytics-details-v2/book-details-get-book-reco'
export const ANALYTICS_DETAILS_V2_STATE_GET_REPORT_DETAILS_BY_ID_ACTION =
  'analytics-details-v2/get-report-details-by-id'
export const ANALYTICS_DETAILS_V2_STATE_NEW_ANALYTIC_ACTION =
  'analytics-details-v2/new-analytic'
export const ANALYTICS_DETAILS_V2_STATE_PUT_REPORT_DETAILS_BY_ID_ACTION =
  'analytics-details-v2/put-report-details-by-id'
export const ANALYTICS_DETAILS_V2_STATE_GET_BOOK_RECO_V2_ACTION =
  'analytics-details-v2/get-book-reco-v2'
export const ANALYTICS_DETAILS_V2_STATE_ADD_BOOK_INPUT_AND_GET_BOOK_RECO_V3_ACTION =
  'analytics-details-v2/add-book-input-and-get-book-reco-v3'
export const ANALYTICS_DETAILS_V2_STATE_NEW_CONSTELLATION_GET_BOOK_RECO_V3_ACTION =
  'analytics-details-v2/new-constellation-get-book-reco-v3'
export const ANALYTICS_DETAILS_V2_STATE_REMOVE_BOOK_INPUT_AND_GET_BOOK_RECO_V3_ACTION =
  'analytics-details-v2/remove-book-input-and-get-book-reco-v3'
export const ANALYTICS_DETAILS_V2_STATE_PUT_PERMISSIONS_BY_REPORT_ID_AND_USER_ID =
  'analytics-details-v2/put-permissions-by-report-id-and-user-id'
export const ANALYTICS_DETAILS_V2_STATE_DELETE_USER_TO_SHARE_REPORT_ACTION =
  'analytics-details-v2/delete-user-to-share-report'
export const ANALYTICS_DETAILS_V2_STATE_PATCH_REPORT_BY_ID_CHANGE_OWNER_ACTION =
  'analytics-details-v2/patch-user-by-id-change-owner'
export const ANALYTICS_DETAILS_V2_PATCH_USER_PERMISSION_TO_ANALYTIC_ACTION =
  'analytics-details-v2/patch-user-permission-to-analytic'
export const SHARE_ANALYTIC_DETAILS_GET_ANALYTIC_BY_SLUG_ID_ACTION =
  'share-analytic-details/get-analytic-by-slug-id'
export const SHARE_ANALYTICS_DETAILS_STATE_GET_BOOK_DETAIL_DATA_ACTION =
  'share-analytic-details/get-book-detail-data'
export const SHARE_ANALYTICS_DETAILS_STATE_GET_B2B_BOOK_BY_EAN_ID_ACTION =
  'share-analytics-details-v2/get-b2b-book-by-ean-id'
export const AUDIENCES_RESULTS_POST_TOP_REQUEST_SAVED_ACTION =
  'audiences-results/post-top-request-saved'

// Analytics Details New Readership Profile
export const NEW_ANALYTICS_READERSHIP_PROFILE_STATE_GET_AUTHORS_BY_QUERY_ACTION =
  'new-analytics-readership-profile/get-authors-by-query'
export const NEW_ANALYTICS_READERSHIP_PROFILE_STATE_SET_STUDY_OBJECT_AUTHOR_AND_GET_TITLES_ACTION =
  'new-analytics-readership-profile/set-study-object-value-author-and-get-titles'

export const NEW_ANALYTICS_READERSHIP_PROFILE_STATE_GET_PREVIEW_FOR_READERSHIP_PROFILE_ACTION =
  'new-analytics-readership-profile/get-preview-for-readership-profile'

export const NEW_ANALYTICS_READERSHIP_PROFILE_STATE_POST_PREVIEW_FOR_READERSHIP_PROFILE_ACTION =
  'new-analytics-readership-profile/post-preview-for-readership-profile'
export const NEW_ANALYTICS_READERSHIP_PROFILE_STATE_PUT_ANALYTICS_BY_ID_VALIDATE =
  'new-analytics-readership-profile/put-analytics-by-id-validate'
export const ANALYTICS_DASHBOARD_GET_INTERNAL_REPORTS =
  'analytics-dashboard/get-internal-reports'
export const ANALYTICS_DETAILS_READERSHIP_PROFILE_GET_STUDY_OBJECT =
  ' analytics-details/readership-profile/get-study-object'
export const WEBAPP_STATE_GET_READERSHIP_PROFILES_STATUS_ACTION =
  'webapp/get-readership-profile-status'
export const ANALYTICS_DETAILS_V2_STATE_PUT_DATASET_MODULES_ORDER =
  'analytics-details-v2/put-dataset-modules-order'
export const ANALYTICS_DETAILS_V2_STATE_PUT_OUTPUTS_ORDER =
  'analytics-details-v2/put-outputs-order'
export const ANALYTICS_DETAILS_V2_STATE_GET_AVAILABLE_MODULES =
  'analytics-details-v2/get-available-modules'
export const ANALYTICS_DETAILS_V2_STATE_PUT_VISUAL_THEME =
  'analytics-details-v2/put-visual-theme'

// ADD-MODULE
export const ANALYTICS_DETAILS_V2_STATE_POST_TITLE_OUTPUT =
  'analytics-details-v2/post-title-output'
export const ANALYTICS_DETAILS_V2_STATE_PUT_TITLE_OUTPUT =
  'analytics-details-v2/put-title-output'
export const ANALYTICS_DETAILS_V2_STATE_DELETE_TITLE_OUTPUT =
  'analytics-details-v2/delete-title-output'
export const ANALYTICS_DETAILS_V2_STATE_POST_TEXT_BOXES_OUTPUT =
  'analytics-details-v2/post-text-boxes-output'
export const ANALYTICS_DETAILS_V2_STATE_PUT_TEXT_BOXES_OUTPUT =
  'analytics-details-v2/put-text-boxes-output'
export const ANALYTICS_DETAILS_V2_STATE_DELETE_TEXT_BOXES_OUTPUT =
  'analytics-details-v2/delete-text-boxes-output'
export const ANALYTICS_DETAILS_V2_STATE_POST_IMAGE_OUTPUT =
  'analytics-details-v2/post-image-output'
export const ANALYTICS_DETAILS_V2_STATE_PUT_IMAGE_OUTPUT =
  'analytics-details-v2/put-image-output'
export const ANALYTICS_DETAILS_V2_STATE_DELETE_IMAGE_OUTPUT =
  'analytics-details-v2/delete-image-output'
export const ANALYTICS_DETAILS_V2_STATE_POST_STUDY_OBJECT_OUTPUT =
  'analytics-details-v2/post-study-object-output'
export const ANALYTICS_DETAILS_V2_STATE_DELETE_STUDY_OBJECT_OUTPUT =
  'analytics-details-v2/delete-study-object-output'
export const ANALYTICS_DETAILS_V2_STATE_POST_DATASET_MODULES =
  'analytics-details-v2/post-dataset-modules'
export const ANALYTICS_DETAILS_V2_STATE_REPLACE_DATASET_MODULES =
  'analytics-details-v2/replace-dataset-modules'
export const ANALYTICS_DETAILS_V2_STATE_GET_DATASET_MODULES_PREVIEW =
  'analytics-details-v2/get-dataset-modules-preview'
export const ANALYTICS_DETAILS_V2_STATE_PUT_DATASET_MODULES =
  'analytics-details-v2/put-dataset-modules'
export const ANALYTICS_DETAILS_V2_STATE_DELETE_DATASET_MODULES =
  'analytics-details-v2/delete-dataset-modules'
export const ANALYTICS_DETAILS_V2_STATE_PUT_PREVIEW =
  'analytics-details-v2/put-preview'
