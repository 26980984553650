import { useTranslation } from 'react-i18next'
import {
  CompareGroupType,
  GroupsCompareTypesConfig,
} from '../configs/StudiesObjectsCompareGroupTypesConfig'
import InputTypeChoice from './InputTypeChoice'
import { CompareGroupValueType } from '../../../../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import { SetColorConfig } from '../../../../../core/config/SetColorConfig'
import { BookInterface } from '../../../../../core/interface/book.interface'

const GroupCompareSelection = ({
  disable,
  compareGroupType,
  handleOnClickInputSelectionButton,
  compareGroupValue,
  handleSelectValue,
  booksSelected,
  handleUpdateBooksSelected,
  handleOpenBookDetails,
  eanSelected,
}: {
  disable: boolean
  compareGroupType: CompareGroupType
  compareGroupValue: CompareGroupValueType
  handleOnClickInputSelectionButton: (value: CompareGroupType) => void
  handleSelectValue: (
    value: CompareGroupValueType,
    booksToRemove?: BookInterface[],
  ) => void
  booksSelected: BookInterface[]
  handleUpdateBooksSelected: (books: BookInterface[]) => void
  handleOpenBookDetails: (eanId: number | null) => void
  eanSelected: number | null
}) => {
  const { t } = useTranslation()
  return (
    <InputTypeChoice<CompareGroupType, CompareGroupValueType>
      disable={disable}
      color={SetColorConfig[0].color2}
      keyValue="compare-group"
      title={t('inComparisonWithThoseOfNew')}
      subTitle={t('ComparisonGroup')}
      inputsTypeConfig={GroupsCompareTypesConfig}
      inputTypeSelected={compareGroupType}
      inputValue={compareGroupValue}
      handleOnClickInputSelectionButton={handleOnClickInputSelectionButton}
      handleSelectValue={handleSelectValue}
      booksSelected={booksSelected}
      handleUpdateBooksSelected={handleUpdateBooksSelected}
      handleOpenBookDetails={handleOpenBookDetails}
      eanSelected={eanSelected}
    />
  )
}
export default GroupCompareSelection
