import styled from '@emotion/styled'
import PerfectScrollbar from 'react-perfect-scrollbar'
import React from 'react'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import GlDialog from '../../share/dialog/GlDialog'
import { ReactComponent as SaveIcon } from '../../../assets/icons/app/save.icon.svg'
import { ReactComponent as AddIcon } from '../../../assets/icons/app/add.icon.svg'
import { AudiencesResultsDialogTypeEnum } from '../../../store/interfaces/dynamics-results.state.interface'
import AudiencesSearchTopRequestSaved from '../../dynamics-search/AudienceSearchTopRequestSaved'
import { TopRequestSavedInterface } from '../../../core/interface/rank/top-requests-saved.interface'
import GlLoader from '../../share/GlLoader'

const AudienceResultDialogTitleStyle = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #252525;
`

const AudienceResultDialogTextStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #ffffff;
`

const AudiencesResultsDialogSave = ({
  setDialogOpenType,
  requestsSaved,
  requestsSavedCount,
  handleOnClickTopRequestSaved,
  handleRenameTopRequestSaved,
  handleDeleteTopRequestSaved,
  isLoadingRequestsSaved,
}: {
  isLoadingRequestsSaved: boolean
  setDialogOpenType: (open: AudiencesResultsDialogTypeEnum | null) => void
  requestsSaved: TopRequestSavedInterface[]
  requestsSavedCount: number
  handleOnClickTopRequestSaved: (id: string) => void
  handleRenameTopRequestSaved: (id: string, name: string) => void
  handleDeleteTopRequestSaved: (id: string) => void
}) => {
  const { t } = useTranslation()

  const handleCloseDialog = () => setDialogOpenType(null)

  return (
    <GlDialog open onClose={handleCloseDialog}>
      <div className="p-2 h-[568px] w-[1168px] flex flex-col gap-5">
        <div className="flex flex-row items-center">
          <div className="flex items-center justify-center mr-2">
            <SaveIcon className="w-8 h-8" />
          </div>
          <AudienceResultDialogTitleStyle>
            {t('webApp.audiences.commons.searchSave', {
              count: requestsSavedCount,
            })}
          </AudienceResultDialogTitleStyle>
          <div className="box-border border border-[#e3e3e3] px-2 py-0.5 bg-[#F7F7F7] rounded-[100px] ml-2 ">
            {requestsSavedCount}
          </div>
        </div>
        <div className="relative flex flex-col flex-auto max-h-[432px]">
          {isLoadingRequestsSaved && (
            <div className="h-full w-full absolute z-10 flex items-center justify-center bg-[#25252533]">
              <GlLoader />
            </div>
          )}
          <PerfectScrollbar className="flex flex-col gap-0.5 pr-2">
            {requestsSaved.map(
              (request: TopRequestSavedInterface, index: number) => (
                <AudiencesSearchTopRequestSaved
                  key={nanoid(5)}
                  name={request.name || ''}
                  catalog={request.catalog}
                  tagGroup={request.tag_group}
                  tagGroupName={request.tag_group_name}
                  sexCategory={request.sex_category}
                  ageCategory={request.age_category}
                  id={request.id}
                  index={index}
                  endDate={request.end_date}
                  startDate={request.start_date}
                  periodType={request.period_type}
                  canEditOnClickOnBG={false}
                  setRefRequestName={() => null}
                  handleOnClickTopRequestSaved={handleOnClickTopRequestSaved}
                  handleRenameTopRequestSaved={handleRenameTopRequestSaved}
                  handleDeleteTopRequestSaved={handleDeleteTopRequestSaved}
                />
              ),
            )}
          </PerfectScrollbar>
        </div>
        <button
          type="button"
          className="flex items-center bg-[#595959] px-4 py-[14px] rounded w-full"
          onClick={() => setDialogOpenType(AudiencesResultsDialogTypeEnum.NEW)}
        >
          <div className="text-white mr-4 bg-[#595959] border-[#595959] text-[#FFFFFF]">
            <AddIcon className="w-5 h-5" />
          </div>
          <AudienceResultDialogTextStyle>
            {t('webApp.audiences.commons.saveCurrentRequest')}
          </AudienceResultDialogTextStyle>
        </button>
      </div>
    </GlDialog>
  )
}

export default AudiencesResultsDialogSave
