import { useEffect } from 'react'
import GlCellHeader from '../../../../share/table/GlCellHeader'
import WorkIcon from '../../../../../assets/icons/headCellIcon/work-icon.svg'
import ReleaseDateIcon from '../../../../../assets/icons/headCellIcon/date-icon.svg'
import SegmentIcon from '../../../../../assets/icons/headCellIcon/clil-icon.svg'
import FormatIcon from '../../../../../assets/icons/headCellIcon/format-icon.svg'
import AuthorIcon from '../../../../../assets/icons/headCellIcon/author-icon.svg'
import EditorIcon from '../../../../../assets/icons/headCellIcon/editor-icon.svg'
import { SortDirectionEnum } from '../../../../../core/enums/sort-direction.enum'
import {
  FILTER_KEY_AUTHORS,
  FILTER_KEY_DELTA_RANK,
  FILTER_KEY_EDITOR,
  FILTER_KEY_FORMAT,
  FILTER_KEY_PUBLISHING_DATE,
  FILTER_KEY_RANK,
  FILTER_KEY_SEGMENT1,
  FILTER_KEY_SEGMENT2,
  FILTER_KEY_TITLE,
} from '../../../../../core/config/audiences/filters-items-audiences.config'

const AudiencesResultsTableHeaderV2 = ({
  sortHeaderSelected,
  sortsSelected,
  handleSortHeaderSelected,
}: {
  sortHeaderSelected: { columnKey: string; sortDirection: SortDirectionEnum }
  sortsSelected: { columnKey: string; sortDirection: SortDirectionEnum }[]
  handleSortHeaderSelected: (filter: string, direction: number) => void
}) => {
  useEffect(() => {})
  return (
    <div
      style={{
        boxShadow: '0px 4px 24px 0px #b5b5b5',
      }}
      className="flex flex-row bg-white h-[34px] rounded-t-[4px] border-b-2 border-[#b2b2b2]"
    >
      <GlCellHeader
        selected={
          sortHeaderSelected &&
          sortHeaderSelected?.columnKey === FILTER_KEY_RANK &&
          !sortsSelected.length
        }
        selectedFromSortAction={sortsSelected.some(
          (value: { columnKey: string; sortDirection: SortDirectionEnum }) =>
            value.columnKey === FILTER_KEY_RANK,
        )}
        className="border-r-2 min-w-[62px] max-w-[62px]"
        hasSortButton={!sortsSelected.length}
        label="Rang"
        sortValue={
          (sortHeaderSelected &&
            sortHeaderSelected?.columnKey === FILTER_KEY_RANK &&
            sortHeaderSelected.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction) => {
          handleSortHeaderSelected(FILTER_KEY_RANK, direction)
        }}
      />
      <GlCellHeader
        selected={
          sortHeaderSelected &&
          sortHeaderSelected?.columnKey === FILTER_KEY_DELTA_RANK &&
          !sortsSelected.length
        }
        selectedFromSortAction={sortsSelected.some(
          (value: { columnKey: string; sortDirection: SortDirectionEnum }) =>
            value.columnKey === FILTER_KEY_DELTA_RANK,
        )}
        className="border-r-2 min-w-[82px] max-w-[82px]"
        hasSortButton={!sortsSelected.length}
        label="Progres."
        sortValue={
          (sortHeaderSelected &&
            sortHeaderSelected?.columnKey === FILTER_KEY_DELTA_RANK &&
            sortHeaderSelected.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction) =>
          handleSortHeaderSelected(FILTER_KEY_DELTA_RANK, direction)
        }
      />
      <GlCellHeader
        selected={
          sortHeaderSelected &&
          sortHeaderSelected?.columnKey === FILTER_KEY_TITLE &&
          !sortsSelected.length
        }
        selectedFromSortAction={sortsSelected.some(
          (value: { columnKey: string; sortDirection: SortDirectionEnum }) =>
            value.columnKey === FILTER_KEY_TITLE,
        )}
        className="border-r-2 min-w-[180px]  max-w-[400px]"
        hasSortButton={!sortsSelected.length}
        icon={WorkIcon}
        label="Titre"
        sortValue={
          (sortHeaderSelected &&
            sortHeaderSelected?.columnKey === FILTER_KEY_TITLE &&
            sortHeaderSelected.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction) =>
          handleSortHeaderSelected(FILTER_KEY_TITLE, direction)
        }
      />
      <GlCellHeader
        selected={
          sortHeaderSelected &&
          sortHeaderSelected?.columnKey === FILTER_KEY_AUTHORS &&
          !sortsSelected.length
        }
        selectedFromSortAction={sortsSelected.some(
          (value: { columnKey: string; sortDirection: SortDirectionEnum }) =>
            value.columnKey === FILTER_KEY_AUTHORS,
        )}
        className="border-r-2 max-w-[240px]"
        hasSortButton={!sortsSelected.length}
        icon={AuthorIcon}
        label="Auteur(s)"
        sortValue={
          (sortHeaderSelected &&
            sortHeaderSelected?.columnKey === FILTER_KEY_AUTHORS &&
            sortHeaderSelected.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction) =>
          handleSortHeaderSelected(FILTER_KEY_AUTHORS, direction)
        }
      />
      <GlCellHeader
        selected={
          sortHeaderSelected &&
          sortHeaderSelected?.columnKey === FILTER_KEY_PUBLISHING_DATE &&
          !sortsSelected.length
        }
        selectedFromSortAction={sortsSelected.some(
          (value: { columnKey: string; sortDirection: SortDirectionEnum }) =>
            value.columnKey === FILTER_KEY_PUBLISHING_DATE,
        )}
        className="border-r-2 min-w-[80px] max-w-[80px]"
        hasSortButton={!sortsSelected.length}
        icon={ReleaseDateIcon}
        label="Date"
        sortValue={
          (sortHeaderSelected &&
            sortHeaderSelected?.columnKey === FILTER_KEY_PUBLISHING_DATE &&
            sortHeaderSelected.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction) =>
          handleSortHeaderSelected(FILTER_KEY_PUBLISHING_DATE, direction)
        }
      />
      <GlCellHeader
        selected={
          sortHeaderSelected &&
          sortHeaderSelected?.columnKey === FILTER_KEY_EDITOR &&
          !sortsSelected.length
        }
        selectedFromSortAction={sortsSelected.some(
          (value: { columnKey: string; sortDirection: SortDirectionEnum }) =>
            value.columnKey === FILTER_KEY_EDITOR,
        )}
        className="border-r-2  min-w-[120px] max-w-[220px]"
        hasSortButton={!sortsSelected.length}
        icon={EditorIcon}
        label="Éditeur"
        sortValue={
          (sortHeaderSelected &&
            sortHeaderSelected?.columnKey === FILTER_KEY_EDITOR &&
            sortHeaderSelected.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction) =>
          handleSortHeaderSelected(FILTER_KEY_EDITOR, direction)
        }
      />
      <GlCellHeader
        selected={
          sortHeaderSelected &&
          sortHeaderSelected?.columnKey === FILTER_KEY_SEGMENT1 &&
          !sortsSelected.length
        }
        selectedFromSortAction={sortsSelected.some(
          (value: { columnKey: string; sortDirection: SortDirectionEnum }) =>
            value.columnKey === FILTER_KEY_SEGMENT1,
        )}
        className="border-r-2  max-w-[120px]"
        hasSortButton={!sortsSelected.length}
        icon={SegmentIcon}
        label="Catégorie 1"
        sortValue={
          (sortHeaderSelected &&
            sortHeaderSelected?.columnKey === FILTER_KEY_SEGMENT1 &&
            sortHeaderSelected.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction) =>
          handleSortHeaderSelected(FILTER_KEY_SEGMENT1, direction)
        }
      />
      <GlCellHeader
        selected={
          sortHeaderSelected &&
          sortHeaderSelected?.columnKey === FILTER_KEY_SEGMENT2 &&
          !sortsSelected.length
        }
        selectedFromSortAction={sortsSelected.some(
          (value: { columnKey: string; sortDirection: SortDirectionEnum }) =>
            value.columnKey === FILTER_KEY_SEGMENT2,
        )}
        className="border-r-2  max-w-[120px]"
        hasSortButton={!sortsSelected.length}
        icon={SegmentIcon}
        label="Catégorie 2"
        sortValue={
          (sortHeaderSelected &&
            sortHeaderSelected?.columnKey === FILTER_KEY_SEGMENT2 &&
            sortHeaderSelected.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction) => {
          handleSortHeaderSelected(FILTER_KEY_SEGMENT2, direction)
        }}
      />
      <GlCellHeader
        selected={
          sortHeaderSelected &&
          sortHeaderSelected?.columnKey === FILTER_KEY_FORMAT &&
          !sortsSelected.length
        }
        selectedFromSortAction={sortsSelected.some(
          (value: { columnKey: string; sortDirection: SortDirectionEnum }) =>
            value.columnKey === FILTER_KEY_FORMAT,
        )}
        className="border-r-2  max-w-[120px]"
        hasSortButton={!sortsSelected.length}
        icon={FormatIcon}
        label="Format"
        sortValue={
          (sortHeaderSelected &&
            sortHeaderSelected?.columnKey === FILTER_KEY_FORMAT &&
            sortHeaderSelected.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction) =>
          handleSortHeaderSelected(FILTER_KEY_FORMAT, direction)
        }
      />
    </div>
  )
}

export default AudiencesResultsTableHeaderV2
