import { useState } from 'react'
import { BookInterface } from '../../../../../../core/interface/book.interface'
import { TagStyle, EllipsisDivStyle } from './RecapInputStyles'
import { ReactComponent as SegmentIcon } from '../../../../../../assets/icons/app/segment.icon.svg'
import RecapToolTip from './RecapToolTip'
import { StudyObjectGroupCompareEnum } from '../../configs/StudiesObjectsCompareGroupTypesConfig'
import clilDropdownUtil, {
  ClilDropDownInterface,
} from '../../../../../../core/config/clil-dropdown.util'
import getClilGenealogy from '../../../../../../core/utils/get-clil-genealogy.util'

const RecapInputTagClils = ({
  value,
  color,
  menuPositionTop,
}: {
  value: string[]
  color: string
  menuPositionTop: boolean
}) => {
  const [showAllBooks, setShowAllBooks] = useState<boolean>(false)
  const [mousePosX, setMousePosX] = useState<number>(0)
  const [mousePosY, setMousePosY] = useState<number>(0)

  const clils = clilDropdownUtil()
  const clilsSelectedEnrich = getClilGenealogy(value)

  return (
    <div
      className="flex flex-row items-center gap-2"
      onMouseEnter={(event) => {
        setMousePosX(event.clientX)
        setMousePosY(event.clientY)
        setShowAllBooks(true)
      }}
      onMouseLeave={() => setShowAllBooks(false)}
    >
      {value.slice(0, 1).map((clilInput: string) => (
        <TagStyle
          color={color}
          className="flex flex-row items-center justify-center py-1 px-2 rounded gap-2"
          key={clilInput}
        >
          <SegmentIcon
            className="flex-none"
            style={{
              width: 20,
              height: 20,
            }}
          />
          <EllipsisDivStyle className="flex flex-auto">
            <span>
              {
                clils.find(
                  (clil: ClilDropDownInterface) => clil.id === clilInput,
                )?.label
              }
            </span>
          </EllipsisDivStyle>
        </TagStyle>
      ))}
      {value.length > 1 && (
        <TagStyle
          color={color}
          className="flex flex-row items-center justify-center py-1 px-2 rounded gap-2"
        >
          <span>+</span>
          <span>{value.length - 1}</span>
        </TagStyle>
      )}
      {showAllBooks && (
        <RecapToolTip
          type={StudyObjectGroupCompareEnum.CLIL}
          objectsSelected={clilsSelectedEnrich}
          menuPositionTop={menuPositionTop}
          mousePosX={mousePosX}
          mousePosY={mousePosY}
          numberObjects={value.length}
        />
      )}
    </div>
  )
}

export default RecapInputTagClils
