import dayjs from 'dayjs'
import styled from '@emotion/styled'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReportLightResponseInterface } from '../../../interface/report/report-light-response.interface'
import GlCellBodyV3 from '../../../../components/share/table/GlCellBodyV3'
import { getFullNameUtil } from '../../../utils/get-full-name.util'
import { ReactComponent as WMPlainIcon } from '../../../../assets/icons/app/work-mirror-full.icon.svg'
import { ReactComponent as ReadershipProfilIcon } from '../../../../assets/icons/readership-profil-card.icon.svg'
import { ReportInputTypeEnum } from '../../../enums/report-input-type.enum'
import { BookInterface } from '../../../interface/book.interface'
import { buildRelativePath, PathConfig } from '../../PathConfig'
import AnalyticsDashboardMoreActionMenu from '../../../../components/analytics-dashboard/more-actions/AnalyticsDashboardMoreActionMenu'
import { useAppSelector } from '../../../../store/hooks'
import { selectWebAppStateCompanyUserId } from '../../../../store/getters/web-app.getters'
import { AnalyticsTypeEnum } from '../../../enums/analytics-type.enum'

const MenuButtonStyle = styled.button`
  color: #252525;
  z-index: 3;
  border-radius: 4px;
  // border: 1px solid #25252533;
  :hover {
    background: #25252533;
  }

  :active {
    background: #25252566;
  }
`

const LineStyle = styled.div`
  :hover {
    background: #f4f4f433;
  }

  :active {
    background: #f4f4f466;
  }
  .more-action-btn {
    visibility: hidden;
  }

  &:hover .more-action-btn {
    visibility: visible;
  }
`
const TableAnalyticsHeader = ({
  isFirst = false,
  analytic,
  handleReloadAction,
}: {
  isFirst?: boolean
  analytic: ReportLightResponseInterface
  handleReloadAction?: () => void
}) => {
  const navigate = useNavigate()
  const companyUserId = useAppSelector(selectWebAppStateCompanyUserId)

  const getInputBook = (): any => {
    const input = analytic.inputs.find(
      (input: any) => input.type === ReportInputTypeEnum.BOOKS,
    )
    if (input) {
      return input.books
    }

    return []
  }

  const onClickMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
  }

  const onClickLine = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    navigate(
      buildRelativePath([
        PathConfig.BOOKMETRIE,
        PathConfig.ANALYTICS,
        analytic.id,
      ]),
    )
  }

  return (
    <LineStyle className="flex flex flex-row" onClick={onClickLine}>
      <GlCellBodyV3 className="min-w-[112px] max-w-[112px]">
        <div
          className="relative flex flex-row w-full rounded"
          style={{
            backgroundColor:
              analytic.type === AnalyticsTypeEnum.WORK_MIRROR
                ? '#86daad'
                : analytic.type === AnalyticsTypeEnum.READERSHIP_PROFILE
                ? '#7bbfff'
                : '#a3a6ff',
            height: 48,
            width: 96,
          }}
        >
          <div
            className="absolute flex items-center justify-center w-full h-full"
            style={{
              zIndex: 1,
            }}
          >
            {(analytic.type === AnalyticsTypeEnum.WORK_MIRROR && (
              <WMPlainIcon style={{ color: 'white', height: 32, width: 32 }} />
            )) ||
              null}
            {(analytic.type === AnalyticsTypeEnum.READERSHIP_PROFILE && (
              <ReadershipProfilIcon
                style={{ color: 'white', height: 32, width: 32 }}
              />
            )) ||
              null}
          </div>
          {getInputBook().map((book: BookInterface, index: number) => (
            <img
              key={`book-miniature-cover-${analytic.id}-${book.id_ean}-${
                index + 1
              }`}
              src={book.imgs}
              alt="Cover book"
              style={{
                width: 96 / getInputBook().length,
                height: 48,
                objectFit: 'cover',
                zIndex: 2,
              }}
            />
          ))}
        </div>
      </GlCellBodyV3>
      <GlCellBodyV3
        className="min-w-[260px]  flex-1"
        labelValue={analytic.name}
        main
      />
      <GlCellBodyV3
        className="min-w-[120px] max-w-[260px]"
        labelValue={dayjs(analytic.modified).format('DD/MM/YYYY')}
      />
      <GlCellBodyV3
        className="min-w-[120px] max-w-[260px]"
        labelValue={dayjs(analytic.created).format('DD/MM/YYYY')}
      />
      <GlCellBodyV3
        className="min-w-[120px] max-w-[260px]"
        labelValue={getFullNameUtil(analytic?.owner as any)}
      />
      <GlCellBodyV3 className="min-w-[48px] max-w-[48px]">
        <div className="relative">
          <AnalyticsDashboardMoreActionMenu
            menuPositionTop={isFirst}
            className="mr-4 more-action-btn"
            analytic={analytic}
            isOwner={analytic.owner?.id === companyUserId}
            handleReloadAction={handleReloadAction}
          />
        </div>
      </GlCellBodyV3>
    </LineStyle>
  )
}
export default TableAnalyticsHeader
