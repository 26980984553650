import axios from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DynamicsSearchStateInterface } from '../interfaces/dynamics-search.state.interface'
import {
  audiencesSearchDeleteTopRequestSavedAction,
  audiencesSearchGetTopRequestSavedAction,
  audiencesSearchGetTopRequestSavedNextAction,
  audiencesSearchPutTopRequestSavedAction,
} from '../actions/dynamics-search.actions'
import { PaginationResponseInterface } from '../../core/interface/pagination-response.interface'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'

const initialState: DynamicsSearchStateInterface = {
  isComputedLoading: false,
  catalogs: [],
  isLoadingTopRequestSaved: false,
  topRequestSaved: { count: 0, next: null, previous: null, results: [] },
  isLoadingTopRequestSavedNext: false,
  cancelTokenTopRequestSavedNext: null,
}

export const audiencesSearchSlice = createSlice({
  name: 'audiencesSearchState',
  initialState,
  reducers: {
    reinitializeAudiencesSearchState: (
      state: DynamicsSearchStateInterface,
    ): DynamicsSearchStateInterface => ({
      ...state,
      ...initialState,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(
        audiencesSearchGetTopRequestSavedAction.pending,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: true,
          topRequestSaved: state.topRequestSaved || {
            count: 0,
            next: null,
            previous: null,
            results: [],
          },
        }),
      )
      .addCase(
        audiencesSearchGetTopRequestSavedAction.fulfilled,
        (
          state: DynamicsSearchStateInterface,
          action: PayloadAction<any>,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
          topRequestSaved: action.payload,
        }),
      )
      .addCase(
        audiencesSearchGetTopRequestSavedAction.rejected,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
          topRequestSaved: {
            count: 0,
            next: null,
            previous: null,
            results: [],
          },
        }),
      )
      .addCase(
        audiencesSearchGetTopRequestSavedNextAction.pending,
        (state: DynamicsSearchStateInterface): DynamicsSearchStateInterface => {
          const { cancelTokenTopRequestSavedNext } = state
          if (cancelTokenTopRequestSavedNext) {
            cancelTokenTopRequestSavedNext.cancel()
          }
          return {
            ...state,
            isLoadingTopRequestSavedNext: true,
            cancelTokenTopRequestSavedNext: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        audiencesSearchGetTopRequestSavedNextAction.fulfilled,
        (
          state: DynamicsSearchStateInterface,
          action: PayloadAction<
            PaginationResponseInterface<TopRequestSavedInterface>
          >,
        ): DynamicsSearchStateInterface => {
          return {
            ...state,
            isLoadingTopRequestSavedNext: false,
            topRequestSaved: {
              count: state.topRequestSaved.count,
              next: null,
              previous: null,
              results: [
                ...state.topRequestSaved.results,
                ...action.payload.results,
              ],
            },
          }
        },
      )
      .addCase(
        audiencesSearchGetTopRequestSavedNextAction.rejected,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSavedNext: false,
        }),
      )
      .addCase(
        audiencesSearchPutTopRequestSavedAction.pending,
        (
          state: DynamicsSearchStateInterface,
          { meta },
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: true,
          topRequestSaved: {
            ...state.topRequestSaved,
            results: state.topRequestSaved.results.map((entry) =>
              entry.id === meta.arg.requestId
                ? { ...entry, name: meta.arg.request.name }
                : entry,
            ),
          },
        }),
      )
      .addCase(
        audiencesSearchPutTopRequestSavedAction.fulfilled,
        (
          state: DynamicsSearchStateInterface,
          action: PayloadAction<any>,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
          topRequestSaved: {
            ...state.topRequestSaved,
            results: state.topRequestSaved.results.map((entry) =>
              entry.id === action.payload.id
                ? { ...entry, ...action.payload }
                : entry,
            ),
          },
        }),
      )
      .addCase(
        audiencesSearchPutTopRequestSavedAction.rejected,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
          topRequestSaved: {
            count: 0,
            next: null,
            previous: null,
            results: [],
          },
        }),
      )
      .addCase(
        audiencesSearchDeleteTopRequestSavedAction.pending,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: true,
        }),
      )
      .addCase(
        audiencesSearchDeleteTopRequestSavedAction.fulfilled,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
        }),
      )
      .addCase(
        audiencesSearchDeleteTopRequestSavedAction.rejected,
        (
          state: DynamicsSearchStateInterface,
        ): DynamicsSearchStateInterface => ({
          ...state,
          isLoadingTopRequestSaved: false,
        }),
      )
  },
})

export const { reinitializeAudiencesSearchState } = audiencesSearchSlice.actions

export const dynamicsSearchReducer = audiencesSearchSlice.reducer
