import styled from '@emotion/styled'
import React, { ReactElement, useEffect, useState } from 'react'
import { ReactComponent as BottomArrowIcon } from '../../../../assets/icons/app/bottom-arrow-light.icon.svg'

const DropDownContainerStyle = styled.div`
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.28px;
  color: #ffffff;
  border-bottom: 1px solid #595959;

  &:hover {
    border-bottom: 1px solid #ffffff;
  }
`

const AudiencesResultsFilterDropDown = ({
  label,
  icon,
  children,
  menuKey,
  menuOpen,
  handleMenuOpen,
}: {
  label: string
  icon: ReactElement
  children: ReactElement
  menuKey: string
  menuOpen: string | null
  handleMenuOpen: (value: string | null) => void
}) => {
  const [needClose, setNeedClose] = useState<boolean>(false)
  const [hasHover, setHasHover] = useState<boolean>(false)

  let timer: NodeJS.Timeout | null = null
  const handleTimeOut = () => {
    timer = setTimeout(() => {
      if (menuOpen === menuKey) {
        handleMenuOpen(null)
      }
      setNeedClose(false)
    }, 300)
  }

  useEffect(() => {
    if (needClose && !hasHover) {
      handleTimeOut()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needClose, hasHover, timer])

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    handleMenuOpen(menuKey)
    setHasHover(true)
  }

  const handleMouseLeave = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    setHasHover(false)
    setNeedClose(true)
  }

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DropDownContainerStyle className="z-10 flex flex-col rounded-t relative w-auto p-2 hover:bg-[#595959]">
        <div className="z-10 flex flex-row justify-between items-center w-full gap-2">
          <span>{icon}</span>
          <span>{label}</span>
          <BottomArrowIcon
            className="w-[14px] h-[14px]"
            style={{
              color: menuKey === menuOpen ? '#ffffff' : '#595959',
            }}
          />
        </div>
        {menuKey === menuOpen && (
          <div
            className="absolute bg-white top-10 left-0 rounded min-w-[220px] w-auto max-w-[420px] shadow-2xl"
            style={{
              zIndex: 50,
            }}
          >
            {children}
          </div>
        )}
      </DropDownContainerStyle>
    </div>
  )
}

export default AudiencesResultsFilterDropDown
