import React from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { ReactComponent as CrossIcon } from '../../../../assets/icons/app/cross.icon.svg'
import GlImageBook from '../../../share/images/GlImageBook'
import { clilReferencesConfig } from '../../../../core/config/clil-references.config'
import ClilReferencesMappingConfig from '../../../../core/config/clil-refs-mapping.config'
import GlBadgeClilV2 from '../../../share/badge/GlBadgeClilV2'
import { TrendV2Interface } from '../../../../core/interface/trend-v2.interface'

const LabelStyle = styled.div`
  color: white;
  display: flex;
  height: 24px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
`

const TitleStyle = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-family: Vollkorn, serif;
  font-style: italic;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #ffffff;
  user-select: text;
  /* Book Title/T1 */
  font-size: 16px;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
  padding-right: 4px;
  max-width: 340px;
`

const SubTitleStyle = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #e9e9e9;
  user-select: text;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  line-height: 20px; /* 142.857% */
`

interface Props {
  bookSelected: TrendV2Interface | null
  bookData: any
  addBottomSpace?: boolean
  handleCloseBookDetails?: () => void
  handleBookSelected: (book: TrendV2Interface | null) => void
}

// TODO REWORK
const AudiencesResultsBookDetailsHeader = ({
  bookSelected,
  bookData,
  addBottomSpace = false,
  handleCloseBookDetails = undefined,
  handleBookSelected,
}: Props) => {
  const getBookCoverByClil = (value: any) => {
    const clil1Level =
      (value?.clil1 && clilReferencesConfig[value.clil1]) ||
      clilReferencesConfig['0000']
    const cover = ClilReferencesMappingConfig[clil1Level['1'].id]
    return cover.cover
  }

  const cover =
    (bookData.image &&
      `https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/${bookData.image}-300`) ||
    bookData?.imgs ||
    getBookCoverByClil(bookData?.clil1 || bookData?.clilObj?.id)

  return (
    <div
      className="flex flex-col relative gap-4 py-4"
      style={{
        height: 152,
        background: 'rgba(37, 37, 37, 0.72)',
        backdropFilter: 'blur(70px)',
      }}
    >
      <button
        type="button"
        className="absolute right-4 top-2 opacity-90 hover:opacity-60 hover:opacity-100 p-2 z-10"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault()
          if (handleCloseBookDetails) {
            handleCloseBookDetails()
          } else {
            handleBookSelected(null)
          }
        }}
      >
        <CrossIcon className="text-white w-4 h-4" />
      </button>
      <div
        className={`relative flex flex-col px-4 gap-4  ${
          addBottomSpace ? 'pb-8' : ''
        }`}
      >
        <div className="flex flex-row gap-4 items-start">
          {/* <img
            src={cover || BookSecondaryReportIcon}
            className="h-[100px] w-[70px]"
            alt="cover"
          /> */}
          <GlImageBook
            pathUrl={cover}
            color={bookData?.clilObj?.color}
            height={120}
            width={88}
          />
          <div className="flex flex-col items-start justify-between h-full">
            <div className="flex flex-col">
              <TitleStyle className="flex text-white text-lg">
                {bookData.title}
              </TitleStyle>
              <SubTitleStyle className="mb-1">{bookData.authors}</SubTitleStyle>
            </div>

            <div className="flex flex-col gap-2">
              <GlBadgeClilV2 id={bookData.clilObj.id} fontColor="white" />
              <div className="flex flex-row gap-2">
                <LabelStyle>{bookData.edition}</LabelStyle>
                {(bookSelected?.publishing_date && (
                  <LabelStyle>
                    {dayjs(bookSelected?.publishing_date).format('DD/MM/YYYY')}
                  </LabelStyle>
                )) ||
                  null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AudiencesResultsBookDetailsHeader
