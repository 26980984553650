import React from 'react'
import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import { DateFilterTypeEnum } from '../../../../core/enums/date-filter-type.enum'
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/app/bottom-arrow-light.icon.svg'
import { ReactComponent as SelectedIcon } from '../../../../assets/icons/selected.icon.svg'
import GlHDivider from '../../../share/GlHDivider'

const MenuStyle = styled.div`
  max-height: 100%;
  width: 256px;
`

const HeaderAccordionStyle = styled.div`
  color: #595959;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  display: flex;
  flex-direction: row;
  max-height: 100%;
  width: 100%;
  height: 28px;
  padding: 6px 8px 6px 12px;
  background: ${({ open }: { open: boolean }) =>
    open ? '#f7f7f7' : '#ffffff'};

  &:hover {
    background: #f7f7f7;
  }
  &:active {
    background: #e3e3e3;
  }
  .icon-header {
    transform: ${({ open }: { open: boolean }) =>
      (open && 'rotate(0)') || 'rotate(180deg)'};
  }
`

const LabelStyle = styled.span`
  color: #252525;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
`

const ItemStyle = styled.span`
  padding: 6px 12px 6px 24px;
  &:hover {
    background: #f7f7f7;
  }
  &:active {
    background: #e3e3e3;
  }
`

const BodyAccordionStyle = styled.div`
  display: ${({ open }: { open: boolean }) => (open ? 'flex' : 'none')};
`

interface Props {
  yearsOptions: any
  yearsOptionsSelected: any
  yearTypeSelected: DateFilterTypeEnum | null
  handleYearTypeSelection: (value: DateFilterTypeEnum | null) => void
  handleChangeListFilterSelected: (
    newList: Array<string | number | null>,
  ) => void
}

const AudiencesResultsYearFiltersActionsV2 = ({
  yearsOptions,
  yearsOptionsSelected,
  yearTypeSelected,
  handleYearTypeSelection,
  handleChangeListFilterSelected,
}: Props) => {
  const { t } = useTranslation()
  return (
    <MenuStyle className="flex flex-col bg-white text-black rounded">
      <div>
        <HeaderAccordionStyle
          className="rounded-t items-center justify-between"
          open={yearTypeSelected === DateFilterTypeEnum.IN}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault()
            handleYearTypeSelection(DateFilterTypeEnum.IN)
          }}
        >
          <div className="flex flex-row gap-1">
            <span>{t('webApp.audiences.topsResults.filter.in')}</span>
            {(yearTypeSelected === DateFilterTypeEnum.IN &&
              yearsOptionsSelected[0] && (
                <LabelStyle>{yearsOptionsSelected[0]}</LabelStyle>
              )) ||
              null}
          </div>
          <ChevronIcon className="icon-header w-4 h-4" />
        </HeaderAccordionStyle>
        <GlHDivider />
        <BodyAccordionStyle
          className="flex flex-col"
          open={yearTypeSelected === DateFilterTypeEnum.IN}
        >
          {yearsOptions.map((yearOption: any) => (
            <ItemStyle
              key={nanoid(5)}
              className="flex flex-row items-center justify-between"
              onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                event.preventDefault()
                handleChangeListFilterSelected([
                  yearOption.value === yearsOptionsSelected[0]
                    ? null
                    : yearOption.value,
                  null,
                ])
              }}
            >
              <LabelStyle>{yearOption.label}</LabelStyle>
              {(yearOption.value === yearsOptionsSelected[0] && (
                <SelectedIcon className="w-4 h-4" />
              )) ||
                null}
            </ItemStyle>
          ))}
        </BodyAccordionStyle>
      </div>
      <div>
        <GlHDivider />
        <HeaderAccordionStyle
          className="rounded-t items-center justify-between"
          open={yearTypeSelected === DateFilterTypeEnum.BEFORE}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault()
            handleYearTypeSelection(DateFilterTypeEnum.BEFORE)
          }}
        >
          <div className="flex flex-row gap-1">
            <span>{t('webApp.audiences.topsResults.filter.before')}</span>
            {(yearTypeSelected === DateFilterTypeEnum.BEFORE &&
              yearsOptionsSelected[0] && (
                <LabelStyle>{yearsOptionsSelected[0]}</LabelStyle>
              )) ||
              null}
          </div>

          <ChevronIcon className="icon-header w-4 h-4" />
        </HeaderAccordionStyle>
        <GlHDivider />
        <BodyAccordionStyle
          className="flex flex-col"
          open={yearTypeSelected === DateFilterTypeEnum.BEFORE}
        >
          {yearsOptions.map((yearOption: any) => (
            <ItemStyle
              key={nanoid(5)}
              className="flex flex-row items-center justify-between"
              onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                event.preventDefault()
                handleChangeListFilterSelected([
                  yearOption.value === yearsOptionsSelected[0]
                    ? null
                    : yearOption.value,
                  null,
                ])
              }}
            >
              <LabelStyle>{yearOption.label}</LabelStyle>
              {(yearOption.value === yearsOptionsSelected[0] && (
                <SelectedIcon className="w-4 h-4" />
              )) ||
                null}
            </ItemStyle>
          ))}
        </BodyAccordionStyle>
      </div>
      <div>
        <GlHDivider />
        <HeaderAccordionStyle
          className="rounded-t items-center justify-between"
          open={yearTypeSelected === DateFilterTypeEnum.AFTER}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault()
            handleYearTypeSelection(DateFilterTypeEnum.AFTER)
          }}
        >
          <div className="flex flex-row gap-1">
            <span>{t('webApp.audiences.topsResults.filter.after')}</span>
            {(yearTypeSelected === DateFilterTypeEnum.AFTER &&
              yearsOptionsSelected[0] && (
                <LabelStyle>{yearsOptionsSelected[0]}</LabelStyle>
              )) ||
              null}
          </div>
          <ChevronIcon className="icon-header w-4 h-4" />
        </HeaderAccordionStyle>
        <GlHDivider />
        <BodyAccordionStyle
          className="flex flex-col"
          open={yearTypeSelected === DateFilterTypeEnum.AFTER}
        >
          {yearsOptions.map((yearOption: any) => (
            <ItemStyle
              key={nanoid(5)}
              className="flex flex-row items-center justify-between"
              onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                event.preventDefault()
                handleChangeListFilterSelected([
                  yearOption.value === yearsOptionsSelected[0]
                    ? null
                    : yearOption.value,
                  null,
                ])
              }}
            >
              <LabelStyle>{yearOption.label}</LabelStyle>
              {((yearOption.value === yearsOptionsSelected[0] ||
                yearOption.value === yearsOptionsSelected[1]) && (
                <SelectedIcon className="w-4 h-4" />
              )) ||
                null}
            </ItemStyle>
          ))}
        </BodyAccordionStyle>
      </div>
    </MenuStyle>
  )
}

export default AudiencesResultsYearFiltersActionsV2
