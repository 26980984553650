import styled from '@emotion/styled'
import React, { useState, useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { AgeFilterEnum } from '../../../../core/enums/age-filter.enum'
import { GenderFilterEnum } from '../../../../core/enums/gender-filter.enum'
import { ReactComponent as LittleArrowBottomArrowIcon } from '../../../../assets/icons/app/bottom.icon.svg'
import {
  RequestFiltersAgeAudiencesConfig,
  RequestFiltersGenderAudiencesConfig,
} from '../../../../core/config/audiences/request-filters-users-audiences.config'
import GlReadersMenu from '../../../share/menu/GlReadersMenu'
import { ReactComponent as WarningIcon } from '../../../../assets/icons/app/warning.icon.svg'

const ReaderFilterContainerStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #252525;
  width: 232px;
  height: 32px;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 4px;
`

const LabelStyle = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${({ disabled = false }: { disabled?: boolean }) =>
    !disabled ? '#252525' : '#b2b2b2'};
  padding: 8px;
`

const DisableCardStyle = styled.div`
  border-radius: 4px;
  background: rgba(116, 163, 255, 0.2);
  width: 100%;
  padding: 5px 6px;
  margin-top: 8px;
  color: #252525;
  font-family: Fira Sans, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`

const AudiencesResultsReadersFilters = ({
  disabled = false,
  noDisplayWarningDisableTooltip = false,
  ageSelected,
  genderSelected,
  handleChangeQuery,
  menuKey,
  menuOpen,
  handleMenuOpen,
}: {
  disabled?: boolean
  noDisplayWarningDisableTooltip?: boolean
  ageSelected: AgeFilterEnum
  genderSelected: GenderFilterEnum
  handleChangeQuery: (key: any, value: any) => void
  menuKey: string
  menuOpen: string | null
  handleMenuOpen: (value: string | null) => void
}) => {
  const { t } = useTranslation()
  const gender = RequestFiltersGenderAudiencesConfig[genderSelected]
  const age = RequestFiltersAgeAudiencesConfig[ageSelected]
  const users = [t(gender), t(age)].join(' ')

  let timer: NodeJS.Timeout | null = null
  const [needClose, setNeedClose] = useState<boolean>(false)
  const [hasHover, setHasHover] = useState<boolean>(false)

  const handleTimeOut = () => {
    timer = setTimeout(() => {
      if (menuOpen === menuKey) {
        handleMenuOpen(null)
      }
      setNeedClose(false)
    }, 300)
  }

  useEffect(() => {
    if (needClose && !hasHover) {
      handleTimeOut()
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needClose, hasHover, timer, menuOpen])

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    if (!disabled) {
      handleMenuOpen(menuKey)
      setHasHover(true)
    }
  }

  const handleMouseLeave = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault()
    if (!disabled) {
      setHasHover(false)
      setNeedClose(true)
    }
  }

  return (
    <ReaderFilterContainerStyle
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="flex flex-col relative bg-white rounded-top relative"
    >
      <LabelStyle
        className="w-full h-full flex flex-row justify-between items-center"
        disabled={disabled}
      >
        <span>{users}</span>
        <LittleArrowBottomArrowIcon className="text-[#b2b2b2]" />
      </LabelStyle>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <GlReadersMenu
          open={menuOpen === menuKey}
          ageSelected={ageSelected}
          genderSelected={genderSelected}
          handleChangeQuery={handleChangeQuery}
        />
      </div>
      {(disabled && !noDisplayWarningDisableTooltip && (
        <DisableCardStyle className="flex flex-row items-start justify-between gap-2">
          <div>
            <WarningIcon className="text-[#74a3ff] w-4 h-4" />
          </div>
          <div>{t('webApp.audiences.topRequest.readerDisabledInfo')}</div>
        </DisableCardStyle>
      )) ||
        null}
    </ReaderFilterContainerStyle>
  )
}

export default memo(AudiencesResultsReadersFilters)
