import {
  RequestFiltersGenderAudiencesType,
  RequestFiltersAgeAudiencesType,
} from '../../types/request-filters-users-audiences.type'
import { GenderFilterEnum } from '../../enums/gender-filter.enum'
import { AgeFilterEnum } from '../../enums/age-filter.enum'

export const RequestFiltersGenderAudiencesConfig: RequestFiltersGenderAudiencesType =
  {
    [GenderFilterEnum.SEX_CATEGORY_ALL]:
      'webApp.audiences.topRequest.gender.all',
    [GenderFilterEnum.SEX_CATEGORY_FEMALE]:
      'webApp.audiences.topRequest.gender.female',
    [GenderFilterEnum.SEX_CATEGORY_MALE]:
      'webApp.audiences.topRequest.gender.male',
  }

export const RequestFiltersAgeAudiencesConfig: RequestFiltersAgeAudiencesType =
  {
    [AgeFilterEnum.AGE_CATEGORY_ALL]: 'webApp.audiences.topRequest.age.all',
    [AgeFilterEnum.AGE_CATEGORY_15_19]: 'webApp.audiences.topRequest.age.15_19',
    [AgeFilterEnum.AGE_CATEGORY_20_24]: 'webApp.audiences.topRequest.age.20_24',
    [AgeFilterEnum.AGE_CATEGORY_25_34]: 'webApp.audiences.topRequest.age.25_34',
    [AgeFilterEnum.AGE_CATEGORY_35_44]: 'webApp.audiences.topRequest.age.35_44',
    [AgeFilterEnum.AGE_CATEGORY_45_59]: 'webApp.audiences.topRequest.age.45_59',
    [AgeFilterEnum.AGE_CATEGORY_60_MORE]:
      'webApp.audiences.topRequest.age.60_more',
  }
