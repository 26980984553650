import styled from '@emotion/styled'
import React from 'react'
import { DatasetInterface } from '../../../../../../../../core/interface/analytics/dataset.interface'
import { SourceEnum } from '../../../../../../../../core/interface/analytics/source.enum'
import { DatasetTypeEnum } from '../../../../../../../../core/enums/dataset-type.enum'
import GlPopularityPanel from '../../../../../../../share/GlPopularityPanel'
import GlBadgeClilIcon from '../../../../../../../share/badge/GlBadgeClilIcon'
import { TooltipRankStyle } from '../../../../ReadershipProfileStyles'

const TitleStyle = styled.span`
  color: #3b3b3b;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
`

const AuthorStyle = styled.span`
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

interface PayloadItem {
  dataKey: string
  name: string
  value: number
  payload: DatasetInterface
}

interface Props {
  payload: Array<PayloadItem>
  typeDataset: DatasetTypeEnum
}

const ScatterTooltip = ({ payload, typeDataset }: Props) => {
  const getTitle = () => {
    const { payload: dataset } = payload[0]
    switch (typeDataset) {
      case DatasetTypeEnum.WORKS_MIRROR:
        return (
          <div className="flex flex-col">
            <TitleStyle>{dataset[SourceEnum.WORK_TITLE]}</TitleStyle>
            <AuthorStyle>
              {dataset[SourceEnum.WORK_AUTHORS].length > 0 &&
                dataset[SourceEnum.WORK_AUTHORS].join(', ')}
            </AuthorStyle>
          </div>
        )
      case DatasetTypeEnum.OVERREPRESENTED:
      case DatasetTypeEnum.MOST_FREQUENT:
        return (
          <div className="flex flex-col">
            <TitleStyle>{dataset[SourceEnum.EAN_TITLE]}</TitleStyle>
            <AuthorStyle>
              {dataset[SourceEnum.EAN_AUTHORS].length > 0 &&
                dataset[SourceEnum.EAN_AUTHORS].join(', ')}
            </AuthorStyle>
          </div>
        )
      case DatasetTypeEnum.AUTHORS_MIRROR:
        return <AuthorStyle>{dataset[SourceEnum.AUTHOR_NAME]}</AuthorStyle>
      default:
        return null
    }
  }

  const getPopularity = () => {
    const { payload: dataset } = payload[0]
    switch (typeDataset) {
      case DatasetTypeEnum.WORKS_MIRROR:
        return (
          <GlPopularityPanel
            popularity={dataset[SourceEnum.WORK_POPULARITY]}
            percentage={`${dataset[SourceEnum.SCORE]}%`}
          />
        )
      case DatasetTypeEnum.OVERREPRESENTED:
        return (
          <GlPopularityPanel
            popularity={dataset[SourceEnum.EAN_POPULARITY]}
            labelPercentage="ratio"
            percentage={`${dataset[SourceEnum.RATIO]}%`}
          />
        )
      case DatasetTypeEnum.AUTHORS_MIRROR:
        return (
          <GlPopularityPanel
            popularity={dataset[SourceEnum.AUTHOR_POPULARITY]}
            percentage={`${dataset[SourceEnum.SCORE]}%`}
          />
        )
      case DatasetTypeEnum.MOST_FREQUENT:
        return (
          <GlPopularityPanel
            popularity={dataset[SourceEnum.EAN_POPULARITY] || 0}
            labelPercentage="adjusted_proportion_in_libraries"
            percentage={`${dataset[SourceEnum.PROPORTION_IN_LIBRARIES]}%`}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className="bg-white rounded p-2 shadow-lg gap-2 flex flex-col">
      {payload && payload[0] && (
        <>
          <div className="flex items-center gap-1">
            {typeDataset !== DatasetTypeEnum.AUTHORS_MIRROR && (
              <GlBadgeClilIcon id={String(payload[0].payload.clil1)} />
            )}
            <TooltipRankStyle>#{payload[0].payload.rank}</TooltipRankStyle>
          </div>
          {getTitle()}
          {getPopularity()}
        </>
      )}
    </div>
  )
}

export default ScatterTooltip
