import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  audiencesResultStatePostTopRequestSavedAction,
  audiencesResultsStateDeleteTopRequestSavedAction,
  audiencesResultsStateGetTopRequestSavedAction,
  audiencesResultsStateGetTopsByCatalogId,
  audiencesResultsStatePutTopRequestSavedAction,
  audiencesResultStateGetBookDetailsData,
} from '../../store/actions/dynamics-results.actions'
import AudiencesResultsHeader from '../../components/dynamics-results/headers/AudiencesResultsHeader'
import AudiencesResultsDialog from '../../components/dynamics-results/dialogs/AudiencesResultsDialog'
import {
  selectAudiencesResultsBookDetails,
  selectAudiencesResultsBookSelected,
  selectAudiencesResultsFiltersActions,
  selectAudiencesResultsGroupSelected,
  selectAudiencesResultsHasFiltersActions,
  selectAudiencesResultsIsLoadingBookDetails,
  selectAudiencesResultsIsLoadingTrends,
  selectAudiencesResultsSearchActions,
  selectAudiencesResultsSortHeaderSelected,
  selectAudiencesResultsSortsSelected,
  selectAudiencesResultsTrendsAggregateByColumn,
  selectAudiencesResultsTrendsFiltered,
  selectAudiencesResultsViewMode,
  selectAudiencesResultsIsLoadingRequestsSaved,
  selectAudiencesResultsRequestsSavedResult,
  selectAudiencesResultsRequestsSavedCount,
  selectAudiencesResultsBookDetailsWorkMirror,
  selectAudiencesResultsIsLoadingBookDetailsWorkMirror,
} from '../../store/getters/dynamics-results.getters'
import {
  audiencesResultsResetAllFiltersActions,
  audiencesResultsResetFilters,
  audiencesResultsSetFiltersActions,
  audiencesResultsSetGroupSelected,
  audiencesResultsSetSearchActions,
  audiencesResultsSetSortHeaderSelected,
  audiencesResultsSetSortsSelected,
  audiencesResultsSetViewMode,
  reinitializeAudiencesResultsState,
} from '../../store/reducers/dynamics-results.reducer'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import { PeriodTypeEnum } from '../../core/enums/period-type.enum'
import { GenderFilterEnum } from '../../core/enums/gender-filter.enum'
import { AgeFilterEnum } from '../../core/enums/age-filter.enum'
import { TagFilterEnum } from '../../core/enums/tag-filter.enum'
import AudiencesResultsBody from '../../components/dynamics-results/body/AudiencesResultsBody'
import { DateFilterTypeEnum } from '../../core/enums/date-filter-type.enum'
import { FILTER_KEY_PUBLISHING_DATE } from '../../core/config/audiences/filters-items-audiences.config'
import {
  buildPathWithParams,
  buildRelativePath,
  PathConfig,
} from '../../core/config/PathConfig'
import { getPeriodByPeriodTypeUtil } from '../../core/utils/get-period-by-period-type.util'
import { AudiencesResultsDialogTypeEnum } from '../../store/interfaces/dynamics-results.state.interface'
import GlSnackBarConfirm from '../../components/share/snackbar/GlSnackBarConfirm'
import { ReactComponent as SaveIcon } from '../../assets/icons/app/save.icon.svg'
import BackGroundAuthIcon from '../../assets/icons/app/background-auth.icon.svg'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'
import { AudienceRequestTypeEnum } from '../../core/enums/audiences/audience-request-type.enum'
import {
  selectWebAppStateCatalogs,
  selectWebAppStateMySelf,
  selectWebAppStateMySelfHasDynamicsAccess,
} from '../../store/getters/web-app.getters'
import AudiencesResultsViewNoData from '../../components/dynamics-results/body/view-modes/AudiencesResultsViewNoData'
import GlLoader from '../../components/share/GlLoader'
import { TrendV2Interface } from '../../core/interface/trend-v2.interface'
import { webAppGetCatalogsAction } from '../../store/actions/web-app.actions'

const AudiencesResultsLayout = styled.div`
  background-image: url('${BackGroundAuthIcon}');
  background-repeat: no-repeat;
  background-position: 90% 10%;
  width: 100%;
  // TODO a check
  height: calc(100% - 236px);
`
const AudiencesResultView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  // For begin query is only use in the component not store
  const [requestParams, setRequestParams] = useState<any>(null)
  const [needSaveRequest, setNeedSaveRequest] = useState<boolean>(false)
  const [dialogOpenType, setDialogOpenType] =
    useState<AudiencesResultsDialogTypeEnum | null>(null)

  const viewMode = useAppSelector(selectAudiencesResultsViewMode)

  const catalogs = useAppSelector(selectWebAppStateCatalogs)

  const isLoadingTrends = useAppSelector(selectAudiencesResultsIsLoadingTrends)
  const trendsFiltered = useAppSelector(selectAudiencesResultsTrendsFiltered)
  const trendsAggregateByColumn = useAppSelector(
    selectAudiencesResultsTrendsAggregateByColumn,
  )
  // Search actions
  const searchValue = useAppSelector(selectAudiencesResultsSearchActions)
  // Filters actions
  const hasFilters = useAppSelector(selectAudiencesResultsHasFiltersActions)
  const filtersActions = useAppSelector(selectAudiencesResultsFiltersActions)
  // Group Actions
  const groupSelected = useAppSelector(selectAudiencesResultsGroupSelected)
  // Sort Header
  const sortHeaderSelected = useAppSelector(
    selectAudiencesResultsSortHeaderSelected,
  )
  // Sorts Actions
  const sortsSelected = useAppSelector(selectAudiencesResultsSortsSelected)
  // BookSidebar
  const bookSelected = useAppSelector(selectAudiencesResultsBookSelected)
  const isLoadingBookDetails = useAppSelector(
    selectAudiencesResultsIsLoadingBookDetails,
  )
  const isLoadingBookDetailsWorkMirror = useAppSelector(
    selectAudiencesResultsIsLoadingBookDetailsWorkMirror,
  )
  const bookDetails = useAppSelector(selectAudiencesResultsBookDetails)
  const bookDetailsWorkMirror = useAppSelector(
    selectAudiencesResultsBookDetailsWorkMirror,
  )

  // Search Saved
  const requestsSaved = useAppSelector(
    selectAudiencesResultsRequestsSavedResult,
  )
  const requestsSavedCount = useAppSelector(
    selectAudiencesResultsRequestsSavedCount,
  )
  const isLoadingRequestsSaved = useAppSelector(
    selectAudiencesResultsIsLoadingRequestsSaved,
  )

  const getCatalogSelected = (): CatalogInterface | undefined =>
    catalogs.find((catalog) => catalog.id === requestParams?.catalogId)

  const mySelf = useAppSelector(selectWebAppStateMySelf)
  const hasDynamicsAccess = useAppSelector(
    selectWebAppStateMySelfHasDynamicsAccess,
  )

  useEffect(() => {
    if (catalogs.length === 0) {
      void dispatch(webAppGetCatalogsAction())
    }
  }, [])

  useEffect(() => {
    if (mySelf && !hasDynamicsAccess) {
      dispatch(setShowSnackBar(t('webApp.notAccessToFeature')))
      navigate(buildRelativePath([PathConfig.BOOKMETRIE]))
    }
  }, [mySelf, hasDynamicsAccess])

  useEffect(() => {
    if (hasDynamicsAccess) {
      const queryParams = {
        catalogId: searchParams.get('catalogId') as string,
        tag_group: searchParams.get('tag_group') as TagFilterEnum,
        tag_group_name: searchParams.get('tag_group_name') as string,
        period: searchParams.get('period') as PeriodTypeEnum,
        startDate: searchParams.get('startDate') as string,
        endDate: searchParams.get('endDate') as string,
        gender: searchParams.get('gender') as GenderFilterEnum,
        age: searchParams.get('age') as AgeFilterEnum,
        requestType: searchParams.get(
          'requestType',
        ) as AudienceRequestTypeEnum | null,
      }
      setRequestParams(queryParams)

      void dispatch(audiencesResultsStateGetTopsByCatalogId(queryParams))
        .then(({ payload }) => {
          setNeedSaveRequest(
            queryParams?.requestType === AudienceRequestTypeEnum.NEW &&
              payload &&
              payload.length,
          )
        })
        .catch(() => {})

      void dispatch(audiencesResultsStateGetTopRequestSavedAction())
    }

    return () => {
      dispatch(reinitializeAudiencesResultsState())
    }
  }, [
    hasDynamicsAccess,
    searchParams.get('catalogId'),
    searchParams.get('tag_group'),
    searchParams.get('period'),
    searchParams.get('startDate'),
    searchParams.get('endDate'),
    searchParams.get('gender'),
    searchParams.get('age'),
    searchParams.get('requestType'),
  ])

  const handleSortHeaderSelected = (filter: string, direction: number) => {
    dispatch(
      audiencesResultsSetSortHeaderSelected({
        columnKey: filter,
        sortDirection: direction,
      }),
    )
  }

  const handleBookSelected = (book: TrendV2Interface | null) => {
    void dispatch(
      audiencesResultStateGetBookDetailsData({
        book: bookSelected?.id_ean === book?.id_ean ? null : book,
      }),
    )
  }

  const handleSetShowSnackBar = () => {
    dispatch(setShowSnackBar(t('snackbarMessage.copied')))
  }

  const handleSearchValue = (value: string) => {
    dispatch(audiencesResultsSetSearchActions(value))
  }

  const handleViewMode = (viewMode: any) => {
    dispatch(audiencesResultsSetViewMode(viewMode))
  }

  const handleChangeListFilterSelected = (
    columnKey: string,
    newList: Array<string | number | null>,
  ) => {
    dispatch(
      audiencesResultsSetFiltersActions({
        ...filtersActions,
        [columnKey]: newList,
      }),
    )
  }

  const handleYearTypeSelection = (value: DateFilterTypeEnum | null) => {
    dispatch(
      audiencesResultsSetFiltersActions({
        ...filtersActions,
        [`${FILTER_KEY_PUBLISHING_DATE}_type`]: value,
        [FILTER_KEY_PUBLISHING_DATE]: [null, null],
      }),
    )
  }

  const handleRemoveItemFilter = (columnKey: string, valueToDelete: string) => {
    dispatch(
      audiencesResultsSetFiltersActions({
        ...filtersActions,
        [columnKey]:
          (columnKey === FILTER_KEY_PUBLISHING_DATE && [null, null]) ||
          (filtersActions[columnKey] as Array<string | number | null>).filter(
            (value: any) => value !== valueToDelete,
          ),
      }),
    )
  }

  const handleSaveTopRequest = (requestName: string) => {
    dispatch(
      audiencesResultStatePostTopRequestSavedAction({
        request: {
          name: requestName,
          catalog_id: requestParams.catalogId,
          start_date: requestParams.startDate,
          end_date: requestParams.endDate,
          period_type: requestParams.period,
          tag_group: requestParams.tag_group,
          age_category: requestParams.age,
          sex_category: requestParams.gender,
        },
      }),
    )
      .then(() => {
        setDialogOpenType(null)
        navigate(
          buildPathWithParams(
            buildRelativePath([
              PathConfig.BOOKMETRIE,
              PathConfig.DYNAMICS,
              PathConfig.RESULTS,
            ]),
            {
              ...requestParams,
              requestType: AudienceRequestTypeEnum.REDIRECT,
            },
          ),
        )
      })
      .catch(() => dispatch(setShowSnackBar(t('snackbarMessage.errorMessage'))))
  }

  // TODO remove function or understand why here
  const handleOnClickTopRequestSaved = () => {
    return null
  }

  const handleRenameTopRequestSaved = (id: string, name: string) => {
    const request = requestsSaved.find((requestSaved) => requestSaved.id === id)
    if (request) {
      dispatch(
        audiencesResultsStatePutTopRequestSavedAction({
          requestId: id,
          request: {
            name,
            catalog_id: request.catalog_id,
            start_date: request.start_date,
            end_date: request.end_date,
            period_type: request.period_type,
            tag_group: request.tag_group,
            age_category: request.age_category,
            sex_category: request.sex_category,
          },
        }),
      )
        .then(() => {
          void dispatch(audiencesResultsStateGetTopRequestSavedAction())
        })
        .catch(() =>
          dispatch(setShowSnackBar(t('snackbarMessage.errorMessage'))),
        )
    } else {
      dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
    }
  }

  const handleDeleteTopRequestSaved = (id: string) => {
    dispatch(
      audiencesResultsStateDeleteTopRequestSavedAction({ requestId: id }),
    )
      .then(() => {
        void dispatch(audiencesResultsStateGetTopRequestSavedAction())
      })
      .catch(() => dispatch(setShowSnackBar(t('snackbarMessage.errorMessage'))))
  }

  const handleChangeQuery = (key: any, value: any) => {
    if (key === 'period') {
      requestParams[key] = value
      const { start, end } = getPeriodByPeriodTypeUtil(value)
      requestParams.startDate = start
      requestParams.endDate = end
      if (value === PeriodTypeEnum.DAY || value === PeriodTypeEnum.WEEK) {
        requestParams.gender = GenderFilterEnum.SEX_CATEGORY_ALL
        requestParams.age = AgeFilterEnum.AGE_CATEGORY_ALL
      }
    } else if (key === 'date') {
      const { start, end } = value
      requestParams.startDate = start
      requestParams.endDate = end
    } else {
      requestParams[key] = value
    }
    requestParams['requestType'] = AudienceRequestTypeEnum.REDIRECT
    navigate(
      buildPathWithParams(
        buildRelativePath([
          PathConfig.BOOKMETRIE,
          PathConfig.DYNAMICS,
          PathConfig.RESULTS,
        ]),
        requestParams,
      ),
    )
  }

  const handleResetFilter = () => {
    dispatch(audiencesResultsResetFilters())
  }

  const handleGroupSelected = (value: any) => {
    dispatch(
      audiencesResultsSetGroupSelected(groupSelected === value ? null : value),
    )
  }
  const handleSortsSelected = (value: any) => {
    dispatch(audiencesResultsSetSortsSelected(value))
  }

  const handleResetAllFilterActions = () => {
    dispatch(audiencesResultsResetAllFiltersActions())
  }

  const handleOpenDialog = () => {
    if (needSaveRequest) {
      setDialogOpenType(AudiencesResultsDialogTypeEnum.NEW)
    } else {
      setDialogOpenType(AudiencesResultsDialogTypeEnum.SAVE)
    }
  }

  return (
    <AudiencesResultsLayout className="flex flex-col w-full h-full">
      <AudiencesResultsHeader
        catalogIdSelected={requestParams?.catalogId}
        ageSelected={requestParams?.age}
        genderSelected={requestParams?.gender}
        tagGroupSelected={requestParams?.tag_group}
        periodTypeSelected={requestParams?.period}
        startDateSelected={requestParams?.startDate}
        endDateSelected={requestParams?.endDate}
        catalogs={catalogs}
        viewMode={viewMode}
        handleViewMode={handleViewMode}
        trendsAggregateByColumn={trendsAggregateByColumn}
        filtersActions={filtersActions}
        handleChangeListFilterSelected={handleChangeListFilterSelected}
        handleYearTypeSelection={handleYearTypeSelection}
        hasFilters={hasFilters}
        handleRemoveItemFilter={handleRemoveItemFilter}
        handleResetFilter={handleResetFilter}
        groupSelected={groupSelected}
        handleGroupSelected={handleGroupSelected}
        handleChangeQuery={handleChangeQuery}
        searchValue={searchValue}
        handleSearchValue={handleSearchValue}
        sortsSelected={sortsSelected}
        handleSortsSelected={handleSortsSelected}
        handleResetAllFilterActions={handleResetAllFilterActions}
        handleOpenDialog={handleOpenDialog}
        needSaveRequest={needSaveRequest}
      />
      {(isLoadingTrends && (
        <div className="w-full h-full flex justify-center items-center">
          <GlLoader />
        </div>
      )) ||
        (trendsFiltered && trendsFiltered.length && (
          <AudiencesResultsBody
            isLoadingTrends={isLoadingTrends}
            viewMode={viewMode}
            trends={trendsFiltered}
            groupSelected={groupSelected}
            bookDetails={bookDetails}
            bookDetailsWorkMirror={bookDetailsWorkMirror}
            handleSortHeaderSelected={handleSortHeaderSelected}
            handleBookSelected={handleBookSelected}
            handleSetShowSnackBar={handleSetShowSnackBar}
            sortHeaderSelected={sortHeaderSelected}
            sortsSelected={sortsSelected}
            tagSelected={requestParams?.tag}
            bookSelected={bookSelected}
            searchValue={searchValue}
            isLoadingBookDetails={isLoadingBookDetails}
            isLoadingBookDetailsWorkMirror={isLoadingBookDetailsWorkMirror}
          />
        )) || <AudiencesResultsViewNoData />}

      <AudiencesResultsDialog
        isLoadingRequestsSaved={isLoadingRequestsSaved}
        setDialogOpenType={setDialogOpenType}
        dialogOpenType={dialogOpenType}
        requestsSaved={requestsSaved}
        requestsSavedCount={requestsSavedCount}
        requestParams={requestParams}
        currentCatalog={getCatalogSelected()}
        handleSaveTopRequest={handleSaveTopRequest}
        handleOnClickTopRequestSaved={handleOnClickTopRequestSaved}
        handleRenameTopRequestSaved={handleRenameTopRequestSaved}
        handleDeleteTopRequestSaved={handleDeleteTopRequestSaved}
      />
      <GlSnackBarConfirm
        needOpen={needSaveRequest}
        handleValidated={() =>
          setDialogOpenType(AudiencesResultsDialogTypeEnum.NEW)
        }
        handleCanceled={() => null}
        duration={10000}
      >
        <div className="flex flex-row gap-2">
          <SaveIcon />
          <span>{t('snackbarMessage.wantRegisterRequest')}</span>
        </div>
      </GlSnackBarConfirm>
    </AudiencesResultsLayout>
  )
}

export default AudiencesResultView
