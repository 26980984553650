import React, { useState } from 'react'
import styled from '@emotion/styled'
import { nanoid } from 'nanoid'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { arrayMoveImmutable } from 'array-move'
import { useTranslation } from 'react-i18next'
import { GroupItemsAudiencesConfig } from '../../../../core/config/audiences/group-items-audiences.config'
import { TrendsItemFilterActionInterface } from '../../../../core/interface/audiences/trends-item-filter-action.interface'
import { SortsItemsAudiencesConfig } from '../../../../core/config/audiences/sorts-items-audiences.config'
import GlHDivider from '../../../share/GlHDivider'
import { SortDirectionEnum } from '../../../../core/enums/sort-direction.enum'
import { ReactComponent as SelectedIcon } from '../../../../assets/icons/selected.icon.svg'
import SortButtons from '../../../share/buttons/SortButtons'
import { StrictModeDroppable } from '../../../trends/filters/actions/MenuSortsAction'
import { ReactComponent as SortIcon } from '../../../../assets/icons/app/sort.icon.svg'
import { ReactComponent as CrossIcon } from '../../../../assets/icons/app/cross.icon.svg'
import { ReactComponent as DragDropIcon } from '../../../../assets/icons/app/drag-drop.icon.svg'
import useClickOutsideHook from '../../../../core/hooks/useClickOutsideOld.hook'

const ButtonItemStyle = styled.button`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
`

const HasCursorStyle = styled.div`
  height: 8px;
  width: 8px;
  background: white;
  border-radius: 6px;
  border: 2px solid #252525;
  position: absolute;
  top: -4px;
  right: -4px;
`

const AudiencesResultsSortButton = ({
  sortsSelected,
  handleSortsSelected,
}: {
  sortsSelected: { columnKey: string; sortDirection: SortDirectionEnum }[]
  handleSortsSelected: (
    sorts: { columnKey: string; sortDirection: SortDirectionEnum }[],
  ) => void
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const handleClose = () => setOpen(false)

  const ref = useClickOutsideHook(handleClose)

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return

    const newList = arrayMoveImmutable(
      sortsSelected,
      result.source.index,
      result.destination.index,
    )
    handleSortsSelected(newList)
  }

  const handleOnChangeDirection = (
    index: number,
    sortDirection: SortDirectionEnum,
  ) => {
    const newList = sortsSelected.map(
      (
        value: { columnKey: string; sortDirection: SortDirectionEnum },
        index1: number,
      ) => {
        return {
          ...value,
          sortDirection: index === index1 ? sortDirection : value.sortDirection,
        }
      },
    )
    handleSortsSelected(newList)
  }

  return (
    <div ref={ref} className="relative">
      <button
        type="button"
        className={`
          border
          border-[#595959]
          w-8
          h-8 
          flex
          items-center
          justify-center
          rounded
          ${(open && 'bg-white text-[#595959]') || 'bg-[#595959] text-white'}
        `}
        onClick={() => setOpen(!open)}
      >
        <SortIcon className="w-[18px] h-[18px]" />
        {!!sortsSelected.length && <HasCursorStyle />}
      </button>
      {open && (
        <div className="absolute top-10 right-0 bg-white z-40 rounded w-[220px] shadow-2xl">
          {(sortsSelected.length && (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <StrictModeDroppable droppableId="droppable">
                {(provided) => (
                  <div
                    className="flex flex-col gap-1 p-1"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {sortsSelected.map(
                      (
                        item: {
                          columnKey: string
                          sortDirection: SortDirectionEnum
                        },
                        index: number,
                      ) => (
                        <Draggable
                          key={`sortable-${item.columnKey}`}
                          draggableId={`sortable-${item.columnKey}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="
                            flex
                            flex-row
                            gap-1
                            items-center
                            text-[#b2b2b2]
                            border-[#b2b2b2]
                            "
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div
                                className="
                            flex
                            flex-row
                            border
                            rounded
                            p-2
                            items-center
                            gap-2
                            h-6
                            hover:border-[#595959]
                            hover:bg-[#f4f4f4]
                          "
                              >
                                <div
                                  className="icon-drag cursor-grab active:cursor-grabbing"
                                  {...provided.dragHandleProps}
                                >
                                  <DragDropIcon className="hover:text-[#595959]" />
                                </div>

                                <div className="text-[#595959]">
                                  {t(
                                    `webApp.audiences.results.filters.columns.${item.columnKey}`,
                                  )}
                                </div>
                                <button
                                  type="button"
                                  onClick={(
                                    event: React.MouseEvent<HTMLButtonElement>,
                                  ) => {
                                    event.stopPropagation()
                                    event.preventDefault()
                                    const newList = sortsSelected.filter(
                                      (value: {
                                        columnKey: string
                                        sortDirection: SortDirectionEnum
                                      }) => value.columnKey !== item.columnKey,
                                    )
                                    handleSortsSelected(newList)
                                  }}
                                >
                                  <CrossIcon className="hover:text-[#595959] w-3 h-3" />
                                </button>
                              </div>
                              <SortButtons
                                handleChangeDirection={(sortDirection) =>
                                  handleOnChangeDirection(index, sortDirection)
                                }
                                sortDirection={item.sortDirection}
                              />
                            </div>
                          )}
                        </Draggable>
                      ),
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          )) ||
            ''}
          <GlHDivider />
          <GlHDivider />
          {SortsItemsAudiencesConfig.map(
            (item: TrendsItemFilterActionInterface, index: number) => (
              <ButtonItemStyle
                key={nanoid(5)}
                type="button"
                className={`
                  flex
                  flex-row
                  justify-between
                  items-center
                  gap-2
                  px-3
                  py-[4.5px]
                  w-full
                  active:bg-[#e3e3e3]
                  hover:bg-[#f7f7f7]
                  ${
                    (index + 1 >= GroupItemsAudiencesConfig.length &&
                      'rounded-b-[4px]') ||
                    ''
                  }
                    ${
                      (sortsSelected.find(
                        (value: {
                          columnKey: string
                          sortDirection: SortDirectionEnum
                        }) => value.columnKey === item.key,
                      ) &&
                        'bg-[#e3e3e3]') ||
                      ''
                    }
                `}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation()
                  event.preventDefault()
                  if (
                    sortsSelected.some(
                      (value: {
                        columnKey: string
                        sortDirection: SortDirectionEnum
                      }) => value.columnKey === item.key,
                    )
                  ) {
                    handleSortsSelected(
                      sortsSelected.filter(
                        (value: {
                          columnKey: string
                          sortDirection: SortDirectionEnum
                        }) => value.columnKey !== item.key,
                      ),
                    )
                  } else {
                    const newItem = {
                      columnKey: item.key,
                      sortDirection: SortDirectionEnum.ASC,
                    }

                    handleSortsSelected([...sortsSelected, newItem])
                  }
                }}
              >
                <div className="flex flex-row justify-between items-center gap-2">
                  <span>{item.icon}</span>
                  <span> {t(item.name)}</span>
                </div>
                {sortsSelected.find(
                  (value: {
                    columnKey: string
                    sortDirection: SortDirectionEnum
                  }) => value.columnKey === item.key,
                ) && <SelectedIcon />}
              </ButtonItemStyle>
            ),
          )}
        </div>
      )}
    </div>
  )
}

export default AudiencesResultsSortButton
