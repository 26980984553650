import React from 'react'
import { useTranslation } from 'react-i18next'
import SegmentIcon from '../../../../../assets/icons/segment-icon-active.svg'
import { SortDirectionEnum } from '../../../../../core/enums/sort-direction.enum'
import {
  WM_COLUMN_KEY_AUTHORS,
  WM_COLUMN_KEY_POSITION,
  WM_COLUMN_KEY_SEGMENT1,
  WM_COLUMN_KEY_TITLE,
} from '../../../../../core/config/reports/wm-report-table.config'
import GlCellHeaderV2 from '../../../../share/table/GlCellHeaderV2'

const AnalyticDetailsTableHeader = ({
  sortDetail,
  handleChangeSort,
}: {
  sortDetail: {
    key: string
    sortDirection: SortDirectionEnum
  }
  handleChangeSort: (value: {
    key: string
    sortDirection: SortDirectionEnum
  }) => void
}) => {
  const { t } = useTranslation()

  if (sortDetail.sortDirection === SortDirectionEnum.EMPTY) {
    handleChangeSort({
      key: WM_COLUMN_KEY_POSITION,
      sortDirection: SortDirectionEnum.ASC,
    })
  }
  return (
    <div className="flex flex-row bg-white h-[34px] bg-[#f7f7f7] m-2 rounded-[4px]">
      <GlCellHeaderV2
        hasSortButton={false}
        className=" rounded-l-[4px] min-w-[80px] max-w-[80px]"
        selected={sortDetail && sortDetail.key === WM_COLUMN_KEY_POSITION}
        label="Prox."
        sortValue={
          (sortDetail.key === WM_COLUMN_KEY_POSITION &&
            sortDetail.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction: SortDirectionEnum) =>
          handleChangeSort({
            key: WM_COLUMN_KEY_POSITION,
            sortDirection: direction,
          })
        }
      />
      <GlCellHeaderV2
        hasSortButton={false}
        className="min-w-[238px] max-w-[238px]"
        selected={sortDetail && sortDetail.key === WM_COLUMN_KEY_TITLE}
        label={t('webApp.audiences.results.filters.columns.title')}
        sortValue={
          (sortDetail.key === WM_COLUMN_KEY_TITLE &&
            sortDetail.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction: SortDirectionEnum) =>
          handleChangeSort({
            key: WM_COLUMN_KEY_TITLE,
            sortDirection: direction,
          })
        }
      />
      <GlCellHeaderV2
        hasSortButton={false}
        className="min-w-[128px] max-w-[128px]"
        selected={sortDetail && sortDetail.key === WM_COLUMN_KEY_AUTHORS}
        label={t('webApp.audiences.results.filters.columns.author')}
        sortValue={
          (sortDetail.key === WM_COLUMN_KEY_AUTHORS &&
            sortDetail.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction: SortDirectionEnum) =>
          handleChangeSort({
            key: WM_COLUMN_KEY_AUTHORS,
            sortDirection: direction,
          })
        }
      />
      <GlCellHeaderV2
        icon={SegmentIcon}
        hasSortButton={false}
        className="min-w-[32px] max-w-[32px]"
        selected={sortDetail && sortDetail.key === WM_COLUMN_KEY_SEGMENT1}
        label=""
        sortValue={
          (sortDetail.key === WM_COLUMN_KEY_SEGMENT1 &&
            sortDetail.sortDirection) ||
          SortDirectionEnum.EMPTY
        }
        handleDirectionSelected={(direction: SortDirectionEnum) =>
          handleChangeSort({
            key: WM_COLUMN_KEY_SEGMENT1,
            sortDirection: direction,
          })
        }
      />
    </div>
  )
}

export default AnalyticDetailsTableHeader
