import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { cloneDeep } from 'lodash'
import { AnalyticV2StateInterface } from '../../interfaces/analytics-details/analytic-v2.state.interface'
import {
  analyticDetailsV2StateAddBookInputAndGetBookRecoV3Action,
  analyticDetailsV2StateBookDetailsGetBookRecoAction,
  analyticDetailsV2StateDeleteUserToShareReport,
  analyticDetailsV2StateGetB2BBookByEanId,
  analyticDetailsV2StateGetBookDetailsData,
  analyticDetailsV2StateGetBookDetailsDataById,
  analyticDetailsV2StateGetEditionsByEanIdAction,
  analyticDetailsV2StateNewConstellationGetBookRecoV3Action,
  analyticDetailsV2StatePatchReportByIdChangeOwnerAction,
  analyticDetailsV2StatePatchUserPermissionToAnalyticAction,
  analyticDetailsV2StatePostNewAnalyticsAction,
  analyticDetailsV2StatePutAnalyticDetailsByIdAction,
  analyticDetailsV2StatePutDatasetModulesOrderAction,
  analyticDetailsV2StatePutOutputsOrderAction,
  analyticDetailsV2StatePutPermissionByReportIdAndUserId,
  analyticDetailsV2StatePutVisualThemeAction,
  analyticDetailsV2StateRemoveBookInputAndGetBookRecoV3Action,
  analyticsDetailsV2StateDeleteDatasetModulesAction,
  analyticsDetailsV2StateDeleteImageOutputAction,
  analyticsDetailsV2StateDeleteStudyObjectOutputAction,
  analyticsDetailsV2StateDeleteTextBoxesOutputAction,
  analyticsDetailsV2StateDeleteTitleOutputAction,
  analyticsDetailsV2StateGetAvailableModulesAction,
  analyticsDetailsV2StateGetDatasetModulesPreviewAction,
  analyticsDetailsV2StatePostDatasetModulesAction,
  analyticsDetailsV2StatePostImageOutputAction,
  analyticsDetailsV2StatePostStudyObjectOutputAction,
  analyticsDetailsV2StatePostTextBoxesOutputAction,
  analyticsDetailsV2StatePostTitleOutputAction,
  analyticsDetailsV2StatePutDatasetModulesAction,
  analyticsDetailsV2StatePutImageOutputAction,
  analyticsDetailsV2StatePutPreviewAction,
  analyticsDetailsV2StatePutTextBoxesOutputAction,
  analyticsDetailsV2StatePutTitleOutputAction,
  analyticsDetailsV2StateReplaceDatasetModulesAction,
  analyticsDetailV2StateGetMockReadershipProfile,
  analyticsDetailV2StateGetReportByIdAction,
  analyticsDetailV2StateNewAnalyticAction,
} from '../../actions/analytics-details/analytic-details-v2.actions'
import { AnalyticInputInterface } from '../../../core/interface/analytics/analytic-input.interface'
import { AnalyticDetailsInterface } from '../../../core/interface/analytics/analytic-details.interface'
import { AnalyticsTypeEnum } from '../../../core/enums/analytics-type.enum'
import { NewAnalyticDetailsModel } from '../../../core/model/new-analytic-details.model'
import { AnalyticInputTypeEnum } from '../../../core/enums/analytic-input-type.enum'
import { BookInterface } from '../../../core/interface/book.interface'
import { AnalyticOutputInterface } from '../../../core/interface/analytics/analytic-output.interface'
import { AnalyticOutputTypeEnum } from '../../../core/enums/analytic-output-type.enum'
import { WorkMirrorBookInterface } from '../../../core/interface/report/work-mirror-book.interface'
import { BookDetailResponseInterface } from '../../../core/interface/rank/book-detail.interface'
import { AnalyticAvailableModulesInterface } from '../../../core/interface/analytics/analytic-available-modules.interface'
import {
  DatasetModuleInterface,
  DatasetModulePreviewInterface,
} from '../../../core/interface/analytics/dataset-module.interface'
import { ReadershipProfilePreviewComparisonInterface } from '../../../core/interface/analytics/readership-profile-preview-comparison.interface'
import { AnalyticVisualThemeEnum } from '../../../core/enums/analytic-visual-theme.enum'

const initialState: AnalyticV2StateInterface = {
  isLoadingGetAnalyticDetails: false,
  needSaveAnalyticDetails: false,
  analyticDetails: null,
  getAnalyticDetailsSuccess: false,
  getAnalyticDetailsError: false,
  cancelTokenGetAnalytics: null,
  isLoadingGetBookRecoV3Add: false,
  isLoadingGetBookRecoV3Remove: false,
  getBookRecoV3Success: false,
  getBookRecoV3Error: false,
  cancelTokenGetBookRecoV3: null,
  isLoadingPostNewAnalytic: false,
  cancelTokenPostNewAnalytic: null,
  postNewAnalyticSuccess: false,
  postNewAnalyticError: false,
  isLoadingPutAnalytic: false,
  cancelTokenPutAnalytic: null,
  putAnalyticSuccess: false,
  putAnalyticError: false,
  isLoadingGetBookDetails: false,
  getBookDetailsSuccess: false,
  getBookDetailsError: false,
  cancelTokenGetBookDetails: null,
  bookDetailsSelected: null,
  bookDetailsSelectedEditions: [],
  isLoadingGetEditions: false,
  getEditionsSuccess: false,
  getEditionsError: false,
  cancelTokenGetEditions: null,
  bookDetailsSelectedB2BInfo: null,
  isLoadingGetB2BBookDetails: false,
  getB2BBookDetailsSuccess: false,
  getB2BBookDetailsError: false,
  cancelTokenGetB2BBookDetails: null,
  bookDetailsBookReco: [],
  isLoadingBookDetailsGetBookReco: false,
  bookDetailsGetBookRecoSuccess: false,
  bookDetailsGetBookRecoError: false,
  cancelTokenBookDetailsGetBookReco: null,
  isLoadingPatchUserPermissionToAnalytic: false,
  cancelTokenPatchUserPermissionToAnalytic: null,
  patchUserPermissionToAnalyticSuccess: false,
  patchUserPermissionToAnalyticError: false,
  isLoadingPutUserPermissionToAnalytic: false,
  cancelTokenPutUserPermissionToAnalytic: null,
  putUserPermissionToAnalyticSuccess: false,
  putUserPermissionToAnalyticError: false,
  isLoadingDeleteUserToShareReport: false,
  deleteUserToShareReportSuccess: false,
  deleteUserToShareReportError: false,
  cancelTokenDeleteUserToShareReport: null,
  isLoadingPatchReportByIdChangeOwner: false,
  patchReportByIdChangeOwnerSuccess: false,
  patchReportByIdChangeOwnerError: false,
  cancelTokenPatchReportByIdChangeOwner: null,
  booksInputsMenuOpen: false,
  isLoadingNewConstellationGetBookReco: false,
  cancelTokenGetBookDetailById: null,
  isLoadingGetAvailableModules: false,
  getAvailableModulesSuccess: false,
  getAvailableModulesError: false,
  availableModules: null,
  needToRefreshReport: false,
  showOrganizeMenu: false,
  outputIdToScroll: null,
  datasetModulesPreview: [],
  isLoadingGetDatasetModulesPreview: false,
}

const analyticsDetailsV2Slice = createSlice({
  name: 'analyticsDetailsV2State',
  initialState,
  reducers: {
    analyticsDetailV2StateSetBooksInputsMenuOpen: (
      state: AnalyticV2StateInterface,
      action: PayloadAction<boolean>,
    ): AnalyticV2StateInterface => ({
      ...state,
      booksInputsMenuOpen: action.payload,
    }),
    analyticsDetailV2StateSetIsLoadingGetAnalyticDetails: (
      state: AnalyticV2StateInterface,
      action: PayloadAction<boolean>,
    ): AnalyticV2StateInterface => ({
      ...state,
      isLoadingGetAnalyticDetails: action.payload,
    }),
    analyticsDetailV2StateSetNewAnalyticDetails: (
      state: AnalyticV2StateInterface,
      action: PayloadAction<AnalyticsTypeEnum>,
    ): AnalyticV2StateInterface => ({
      ...state,
      analyticDetails: new NewAnalyticDetailsModel(action.payload),
    }),
    analyticsDetailV2StateSetAnalyticDetailsName: (
      state: AnalyticV2StateInterface,
      action: PayloadAction<string>,
    ): AnalyticV2StateInterface => ({
      ...state,
      analyticDetails: {
        ...(state.analyticDetails as AnalyticDetailsInterface),
        name: action.payload,
      },
    }),
    analyticsDetailV2StateAddBooksToInputValues: (
      state: AnalyticV2StateInterface,
      action: PayloadAction<{ eanId: any }>,
    ): AnalyticV2StateInterface => {
      const { eanId } = action.payload
      const analyticDetails = cloneDeep(
        state.analyticDetails,
      ) as AnalyticDetailsInterface
      analyticDetails.inputs =
        analyticDetails?.inputs.map((input: AnalyticInputInterface) => {
          if (input?.type === AnalyticInputTypeEnum.BOOKS) {
            input.values.push(eanId)
          }
          return input
        }) || ([] as AnalyticInputInterface[])
      return {
        ...state,
        analyticDetails,
      }
    },
    reinitializeAnalyticDetailsV2State: (
      state: AnalyticV2StateInterface,
    ): AnalyticV2StateInterface => ({
      ...state,
      ...initialState,
      booksInputsMenuOpen: state.booksInputsMenuOpen,
    }),
    resetPostNewAnalyticStatus: (
      state: AnalyticV2StateInterface,
    ): AnalyticV2StateInterface => ({
      ...state,
      postNewAnalyticSuccess: false,
      postNewAnalyticError: false,
    }),
    resetPutAnalyticStatus: (
      state: AnalyticV2StateInterface,
    ): AnalyticV2StateInterface => ({
      ...state,
      putAnalyticSuccess: false,
      putAnalyticError: false,
    }),
    resetPatchReportByIdChangeOwnerStatus: (
      state: AnalyticV2StateInterface,
    ): AnalyticV2StateInterface => ({
      ...state,
      patchReportByIdChangeOwnerSuccess: false,
      patchReportByIdChangeOwnerError: false,
    }),
    resetDeleteUserToShareReportStatus: (
      state: AnalyticV2StateInterface,
    ): AnalyticV2StateInterface => ({
      ...state,
      deleteUserToShareReportSuccess: false,
      deleteUserToShareReportError: false,
    }),
    resetPutUserPermissionToAnalyticStatus: (
      state: AnalyticV2StateInterface,
    ): AnalyticV2StateInterface => ({
      ...state,
      putUserPermissionToAnalyticSuccess: false,
      putUserPermissionToAnalyticError: false,
    }),
    resetGetBookRecoV3Status: (
      state: AnalyticV2StateInterface,
    ): AnalyticV2StateInterface => ({
      ...state,
      getBookRecoV3Success: false,
      getBookRecoV3Error: false,
    }),
    analyticsDetailV2StateSetShowOrganizeMenu: (
      state: AnalyticV2StateInterface,
      action: PayloadAction<boolean>,
    ): AnalyticV2StateInterface => ({
      ...state,
      showOrganizeMenu: action.payload,
      outputIdToScroll: null,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        analyticsDetailV2StateGetReportByIdAction.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenGetAnalytics } = state
          if (cancelTokenGetAnalytics) {
            cancelTokenGetAnalytics.cancel()
          }

          return {
            ...state,
            isLoadingGetAnalyticDetails: true,
            analyticDetails: null,
            getAnalyticDetailsSuccess: false,
            getAnalyticDetailsError: false,
            cancelTokenGetAnalytics: axios.CancelToken.source(),
            needToRefreshReport: false,
          }
        },
      )
      .addCase(
        analyticsDetailV2StateGetReportByIdAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticDetailsInterface>,
        ): AnalyticV2StateInterface => {
          const analyticDetails = cloneDeep(action.payload)
          analyticDetails.inputs = analyticDetails?.inputs.map(
            (input: AnalyticInputInterface) => {
              if (input?.type === AnalyticInputTypeEnum.BOOKS) {
                input.outputs = input.outputs.map(
                  (output: AnalyticOutputInterface) => {
                    if (output.type === AnalyticOutputTypeEnum.WORK_MIRROR) {
                      output.work_mirror_books = (
                        output.work_mirror_books || []
                      ).map((node: WorkMirrorBookInterface, index1: number) => {
                        return {
                          ...node,
                          distance: node.distance || node.book.distance || 0,
                          position: index1 + 1,
                        } as WorkMirrorBookInterface
                      })
                    }
                    return output
                  },
                )
              }
              return input
            },
          )
          return {
            ...state,
            isLoadingGetAnalyticDetails: false,
            analyticDetails,
            getAnalyticDetailsSuccess: true,
            getAnalyticDetailsError: false,
          }
        },
      )
      .addCase(
        analyticsDetailV2StateGetReportByIdAction.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetAnalyticDetails: false,
            analyticDetails: null,
            getAnalyticDetailsSuccess: false,
            getAnalyticDetailsError: true,
          }
        },
      )
      .addCase(
        analyticsDetailV2StateNewAnalyticAction.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetAnalyticDetails: true,
            needSaveAnalyticDetails: false,
            analyticDetails: null,
            getAnalyticDetailsSuccess: false,
            getAnalyticDetailsError: false,
          }
        },
      )
      .addCase(
        analyticsDetailV2StateNewAnalyticAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticDetailsInterface>,
        ): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetAnalyticDetails: false,
            needSaveAnalyticDetails: true,
            analyticDetails: action.payload,
            getAnalyticDetailsSuccess: true,
            getAnalyticDetailsError: false,
          }
        },
      )
      .addCase(
        analyticsDetailV2StateNewAnalyticAction.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetAnalyticDetails: false,
            needSaveAnalyticDetails: false,
            analyticDetails: null,
            getAnalyticDetailsSuccess: false,
            getAnalyticDetailsError: true,
          }
        },
      )

      .addCase(
        analyticDetailsV2StateAddBookInputAndGetBookRecoV3Action.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenGetBookRecoV3 } = state
          if (cancelTokenGetBookRecoV3) {
            cancelTokenGetBookRecoV3.cancel()
          }
          return {
            ...state,
            isLoadingGetBookRecoV3Add: true,
            getBookRecoV3Success: false,
            getBookRecoV3Error: false,
            cancelTokenGetBookRecoV3: axios.CancelToken.source(),
            bookDetailsSelected: null,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateAddBookInputAndGetBookRecoV3Action.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticInputInterface>,
        ): AnalyticV2StateInterface => {
          const { values, outputs, books } = action.payload
          const analyticDetails = cloneDeep(
            state.analyticDetails,
          ) as AnalyticDetailsInterface
          if (!analyticDetails?.id) {
            analyticDetails.name = books && books.length ? books[0]?.title : ''
          }

          analyticDetails.inputs =
            (analyticDetails?.inputs || [action.payload]).map(
              (input: AnalyticInputInterface) => {
                if (input?.type === AnalyticInputTypeEnum.BOOKS) {
                  input = {
                    ...input,
                    values,
                    books,
                    outputs: input.outputs.map(
                      (output: AnalyticOutputInterface) => {
                        if (
                          output.type === AnalyticOutputTypeEnum.WORK_MIRROR
                        ) {
                          return {
                            ...output,
                            work_mirror_books: (
                              outputs.find(
                                (outputResult: AnalyticOutputInterface) =>
                                  outputResult.type ===
                                  AnalyticOutputTypeEnum.WORK_MIRROR,
                              )?.work_mirror_books || []
                            ).map(
                              (
                                workMirrorBook: WorkMirrorBookInterface,
                                index,
                              ) => ({
                                ...workMirrorBook,
                                position: index + 1,
                              }),
                            ),
                          }
                        }
                        return output
                      },
                    ),
                  }
                }
                return input
              },
            ) || ([] as AnalyticInputInterface[])
          return {
            ...state,
            isLoadingGetBookRecoV3Add: false,
            needSaveAnalyticDetails: true,
            getBookRecoV3Success: true,
            getBookRecoV3Error: false,
            analyticDetails,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateAddBookInputAndGetBookRecoV3Action.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetBookRecoV3Add: false,
            getBookRecoV3Success: false,
            getBookRecoV3Error: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateNewConstellationGetBookRecoV3Action.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenGetBookRecoV3 } = state
          if (cancelTokenGetBookRecoV3) {
            cancelTokenGetBookRecoV3.cancel()
          }
          return {
            ...state,
            isLoadingNewConstellationGetBookReco: true,
            getBookRecoV3Success: false,
            getBookRecoV3Error: false,
            cancelTokenGetBookRecoV3: axios.CancelToken.source(),
            bookDetailsSelected: null,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateNewConstellationGetBookRecoV3Action.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticInputInterface>,
        ): AnalyticV2StateInterface => {
          const { values, outputs, books } = action.payload
          const analyticDetails = cloneDeep(
            state.analyticDetails,
          ) as AnalyticDetailsInterface
          if (!analyticDetails?.id) {
            analyticDetails.name = books && books.length ? books[0]?.title : ''
          }

          analyticDetails.inputs =
            (analyticDetails?.inputs || [action.payload]).map(
              (input: AnalyticInputInterface) => {
                if (input?.type === AnalyticInputTypeEnum.BOOKS) {
                  input = {
                    ...input,
                    values,
                    books,
                    outputs: input.outputs.map(
                      (output: AnalyticOutputInterface) => {
                        if (
                          output.type === AnalyticOutputTypeEnum.WORK_MIRROR
                        ) {
                          return {
                            ...output,
                            work_mirror_books: (
                              outputs.find(
                                (outputResult: AnalyticOutputInterface) =>
                                  outputResult.type ===
                                  AnalyticOutputTypeEnum.WORK_MIRROR,
                              )?.work_mirror_books || []
                            ).map(
                              (
                                workMirrorBook: WorkMirrorBookInterface,
                                index,
                              ) => ({
                                ...workMirrorBook,
                                position: index + 1,
                              }),
                            ),
                          }
                        }
                        return output
                      },
                    ),
                  }
                }
                return input
              },
            ) || ([] as AnalyticInputInterface[])
          return {
            ...state,
            needSaveAnalyticDetails: true,
            isLoadingNewConstellationGetBookReco: false,
            getBookRecoV3Success: true,
            getBookRecoV3Error: false,
            analyticDetails,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateNewConstellationGetBookRecoV3Action.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingNewConstellationGetBookReco: false,
            getBookRecoV3Success: false,
            getBookRecoV3Error: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateRemoveBookInputAndGetBookRecoV3Action.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenGetBookRecoV3 } = state
          if (cancelTokenGetBookRecoV3) {
            cancelTokenGetBookRecoV3.cancel()
          }
          return {
            ...state,
            isLoadingGetBookRecoV3Remove: true,
            getBookRecoV3Success: false,
            getBookRecoV3Error: false,
            cancelTokenGetBookRecoV3: axios.CancelToken.source(),
            bookDetailsSelected: null,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateRemoveBookInputAndGetBookRecoV3Action.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticInputInterface>,
        ): AnalyticV2StateInterface => {
          const { values, outputs, books } = action.payload
          const analyticDetails = cloneDeep(
            state.analyticDetails,
          ) as AnalyticDetailsInterface
          analyticDetails.inputs =
            (analyticDetails?.inputs || [action.payload]).map(
              (input: AnalyticInputInterface) => {
                if (input?.type === AnalyticInputTypeEnum.BOOKS) {
                  input = {
                    ...input,
                    values,
                    books,
                    outputs: input.outputs.map(
                      (output: AnalyticOutputInterface) => {
                        if (
                          output.type === AnalyticOutputTypeEnum.WORK_MIRROR
                        ) {
                          return {
                            ...output,
                            work_mirror_books: (
                              outputs.find(
                                (outputResult: AnalyticOutputInterface) =>
                                  outputResult.type ===
                                  AnalyticOutputTypeEnum.WORK_MIRROR,
                              )?.work_mirror_books || []
                            ).map(
                              (
                                workMirrorBook: WorkMirrorBookInterface,
                                index,
                              ) => ({
                                ...workMirrorBook,
                                position: index + 1,
                              }),
                            ),
                          }
                        }
                        return output
                      },
                    ),
                  }
                }
                return input
              },
            ) || ([] as AnalyticInputInterface[])
          return {
            ...state,
            needSaveAnalyticDetails: true,
            isLoadingGetBookRecoV3Remove: false,
            getBookRecoV3Success: true,
            getBookRecoV3Error: false,
            analyticDetails,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateRemoveBookInputAndGetBookRecoV3Action.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetBookRecoV3Remove: false,
            getBookRecoV3Success: false,
            getBookRecoV3Error: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePostNewAnalyticsAction.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenPostNewAnalytic } = state
          if (cancelTokenPostNewAnalytic) {
            cancelTokenPostNewAnalytic.cancel()
          }

          return {
            ...state,
            isLoadingPostNewAnalytic: true,
            postNewAnalyticSuccess: false,
            postNewAnalyticError: false,
            cancelTokenPostNewAnalytic: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePostNewAnalyticsAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticDetailsInterface>,
        ): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingPostNewAnalytic: false,
            postNewAnalyticSuccess: true,
            postNewAnalyticError: false,
            analyticDetails: action.payload,
            needSaveAnalyticDetails: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePostNewAnalyticsAction.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingPostNewAnalytic: false,
            postNewAnalyticSuccess: false,
            postNewAnalyticError: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePutAnalyticDetailsByIdAction.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenPutAnalytic } = state
          if (cancelTokenPutAnalytic) {
            cancelTokenPutAnalytic.cancel()
          }

          return {
            ...state,
            isLoadingPutAnalytic: true,
            putAnalyticSuccess: false,
            putAnalyticError: false,
            cancelTokenPutAnalytic: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePutAnalyticDetailsByIdAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticDetailsInterface>,
        ): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingPutAnalytic: false,
            putAnalyticSuccess: true,
            putAnalyticError: false,
            analyticDetails: action.payload,
            needSaveAnalyticDetails: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePutAnalyticDetailsByIdAction.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingPutAnalytic: false,
            putAnalyticSuccess: false,
            putAnalyticError: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetBookDetailsData.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetBookDetails: true,
            getBookDetailsSuccess: false,
            getBookDetailsError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetBookDetailsData.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<WorkMirrorBookInterface | null>,
        ): AnalyticV2StateInterface => {
          return {
            ...state,
            bookDetailsSelected: action.payload,
            isLoadingGetBookDetails: false,
            getBookDetailsSuccess: true,
            getBookDetailsError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetBookDetailsData.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetBookDetails: false,
            getBookDetailsSuccess: false,
            getBookDetailsError: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetBookDetailsDataById.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenGetBookDetailById } = state
          if (cancelTokenGetBookDetailById) {
            cancelTokenGetBookDetailById.cancel()
          }
          return {
            ...state,
            cancelTokenGetBookDetailById: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetBookDetailsDataById.fulfilled,
        (state: AnalyticV2StateInterface): void => {
          const { cancelTokenGetBookDetailById } = state
          if (cancelTokenGetBookDetailById) {
            cancelTokenGetBookDetailById.cancel()
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetBookDetailsDataById.rejected,
        (state: AnalyticV2StateInterface): void => {
          const { cancelTokenGetBookDetailById } = state
          if (cancelTokenGetBookDetailById) {
            cancelTokenGetBookDetailById.cancel()
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetEditionsByEanIdAction.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenGetEditions } = state
          if (cancelTokenGetEditions) {
            cancelTokenGetEditions.cancel()
          }

          return {
            ...state,
            isLoadingGetEditions: true,
            bookDetailsSelectedEditions: [],
            cancelTokenGetEditions: axios.CancelToken.source(),
            getEditionsSuccess: false,
            getEditionsError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetEditionsByEanIdAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<BookInterface[]>,
        ): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetEditions: false,
            bookDetailsSelectedEditions: action.payload,
            getEditionsSuccess: true,
            getEditionsError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetEditionsByEanIdAction.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetB2BBookDetails: false,
            bookDetailsSelectedEditions: [],
            getBookDetailsSuccess: false,
            getBookDetailsError: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetB2BBookByEanId.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenGetB2BBookDetails } = state
          if (cancelTokenGetB2BBookDetails) {
            cancelTokenGetB2BBookDetails.cancel()
          }

          return {
            ...state,
            isLoadingGetB2BBookDetails: true,
            bookDetailsSelectedB2BInfo: null,
            getBookDetailsSuccess: false,
            getBookDetailsError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetB2BBookByEanId.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<BookDetailResponseInterface | null>,
        ): AnalyticV2StateInterface => {
          const { cancelTokenGetB2BBookDetails } = state
          if (cancelTokenGetB2BBookDetails) {
            cancelTokenGetB2BBookDetails.cancel()
          }

          return {
            ...state,
            isLoadingGetB2BBookDetails: false,
            bookDetailsSelectedB2BInfo: action.payload,
            getBookDetailsSuccess: true,
            getBookDetailsError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateGetB2BBookByEanId.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenGetB2BBookDetails } = state
          if (cancelTokenGetB2BBookDetails) {
            cancelTokenGetB2BBookDetails.cancel()
          }

          return {
            ...state,
            isLoadingGetB2BBookDetails: false,
            bookDetailsSelectedB2BInfo: null,
            getBookDetailsSuccess: false,
            getBookDetailsError: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateBookDetailsGetBookRecoAction.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenBookDetailsGetBookReco } = state
          if (cancelTokenBookDetailsGetBookReco) {
            cancelTokenBookDetailsGetBookReco.cancel()
          }

          return {
            ...state,
            isLoadingBookDetailsGetBookReco: false,
            bookDetailsBookReco: [],
            bookDetailsGetBookRecoSuccess: false,
            bookDetailsGetBookRecoError: false,
            cancelTokenBookDetailsGetBookReco: axios.CancelToken.source(),
          }
        },
      )
      .addCase(
        analyticDetailsV2StateBookDetailsGetBookRecoAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<BookInterface[]>,
        ): AnalyticV2StateInterface => {
          const { cancelTokenBookDetailsGetBookReco } = state
          if (cancelTokenBookDetailsGetBookReco) {
            cancelTokenBookDetailsGetBookReco.cancel()
          }

          return {
            ...state,
            isLoadingBookDetailsGetBookReco: false,
            bookDetailsBookReco: action.payload,
            bookDetailsGetBookRecoSuccess: true,
            bookDetailsGetBookRecoError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateBookDetailsGetBookRecoAction.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenBookDetailsGetBookReco } = state
          if (cancelTokenBookDetailsGetBookReco) {
            cancelTokenBookDetailsGetBookReco.cancel()
          }

          return {
            ...state,
            isLoadingBookDetailsGetBookReco: false,
            bookDetailsBookReco: [],
            bookDetailsGetBookRecoSuccess: false,
            bookDetailsGetBookRecoError: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePatchUserPermissionToAnalyticAction.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenPatchUserPermissionToAnalytic } = state
          if (cancelTokenPatchUserPermissionToAnalytic) {
            cancelTokenPatchUserPermissionToAnalytic.cancel
          }
          return {
            ...state,
            isLoadingPatchUserPermissionToAnalytic: true,
            cancelTokenPatchUserPermissionToAnalytic:
              axios.CancelToken.source(),
            patchUserPermissionToAnalyticSuccess: false,
            patchUserPermissionToAnalyticError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePatchUserPermissionToAnalyticAction.fulfilled,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingPatchUserPermissionToAnalytic: false,
            patchUserPermissionToAnalyticSuccess: true,
            patchUserPermissionToAnalyticError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePatchUserPermissionToAnalyticAction.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingPatchUserPermissionToAnalytic: false,
            patchUserPermissionToAnalyticSuccess: false,
            patchUserPermissionToAnalyticError: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePutPermissionByReportIdAndUserId.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenPutUserPermissionToAnalytic } = state
          if (cancelTokenPutUserPermissionToAnalytic) {
            cancelTokenPutUserPermissionToAnalytic.cancel
          }
          return {
            ...state,
            isLoadingPutUserPermissionToAnalytic: true,
            cancelTokenPutUserPermissionToAnalytic: axios.CancelToken.source(),
            putUserPermissionToAnalyticSuccess: false,
            putUserPermissionToAnalyticError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePutPermissionByReportIdAndUserId.fulfilled,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingPutUserPermissionToAnalytic: false,
            putUserPermissionToAnalyticSuccess: true,
            putUserPermissionToAnalyticError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePutPermissionByReportIdAndUserId.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingPutUserPermissionToAnalytic: false,
            putUserPermissionToAnalyticSuccess: false,
            putUserPermissionToAnalyticError: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateDeleteUserToShareReport.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenDeleteUserToShareReport } = state
          if (cancelTokenDeleteUserToShareReport) {
            cancelTokenDeleteUserToShareReport.cancel
          }
          return {
            ...state,
            isLoadingDeleteUserToShareReport: true,
            cancelTokenDeleteUserToShareReport: axios.CancelToken.source(),
            deleteUserToShareReportSuccess: false,
            deleteUserToShareReportError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateDeleteUserToShareReport.fulfilled,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingDeleteUserToShareReport: false,
            deleteUserToShareReportSuccess: true,
            deleteUserToShareReportError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StateDeleteUserToShareReport.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingDeleteUserToShareReport: false,
            deleteUserToShareReportSuccess: false,
            deleteUserToShareReportError: true,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePatchReportByIdChangeOwnerAction.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          const { cancelTokenPatchReportByIdChangeOwner } = state
          if (cancelTokenPatchReportByIdChangeOwner) {
            cancelTokenPatchReportByIdChangeOwner.cancel
          }
          return {
            ...state,
            isLoadingPatchReportByIdChangeOwner: true,
            cancelTokenPatchReportByIdChangeOwner: axios.CancelToken.source(),
            patchReportByIdChangeOwnerSuccess: false,
            patchReportByIdChangeOwnerError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePatchReportByIdChangeOwnerAction.fulfilled,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingPatchReportByIdChangeOwner: false,
            patchReportByIdChangeOwnerSuccess: true,
            patchReportByIdChangeOwnerError: false,
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePatchReportByIdChangeOwnerAction.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingPatchReportByIdChangeOwner: false,
            patchReportByIdChangeOwnerSuccess: false,
            patchReportByIdChangeOwnerError: true,
          }
        },
      )
      .addCase(
        analyticsDetailV2StateGetMockReadershipProfile.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetAnalyticDetails: true,
            analyticDetails: null,
            getAnalyticDetailsSuccess: false,
            getAnalyticDetailsError: false,
          }
        },
      )
      .addCase(
        analyticsDetailV2StateGetMockReadershipProfile.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticDetailsInterface>,
        ): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetAnalyticDetails: false,
            analyticDetails: action.payload,
            getAnalyticDetailsSuccess: true,
            getAnalyticDetailsError: false,
          }
        },
      )
      .addCase(
        analyticsDetailV2StateGetMockReadershipProfile.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetAnalyticDetails: false,
            analyticDetails: null,
            getAnalyticDetailsSuccess: false,
            getAnalyticDetailsError: true,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateGetAvailableModulesAction.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetAvailableModules: true,
            getAvailableModulesSuccess: false,
            getAvailableModulesError: false,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateGetAvailableModulesAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticAvailableModulesInterface>,
        ): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetAvailableModules: false,
            availableModules: action.payload,
            getAvailableModulesSuccess: true,
            getAvailableModulesError: false,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateGetAvailableModulesAction.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetAvailableModules: false,
            getAvailableModulesSuccess: false,
            getAvailableModulesError: true,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StatePostDatasetModulesAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<{
            output: AnalyticOutputInterface
            scrollToOutput: boolean
          }>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: analyticDetails.inputs[0].outputs.map(
                    (output: AnalyticOutputInterface) => {
                      if (
                        output.id === action.payload.output.id &&
                        output.dataset_output
                      ) {
                        return action.payload.output
                      }
                      return output
                    },
                  ),
                },
              ],
            },
            outputIdToScroll:
              (action.payload.scrollToOutput &&
                action.payload.output.dataset_output?.dataset_modules[
                  (action.payload.output.dataset_output?.dataset_modules
                    .length || 1) - 1
                ]?.id) ||
              null,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateReplaceDatasetModulesAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticOutputInterface>,
        ): AnalyticV2StateInterface => {
          return {
            ...state,
            outputIdToScroll:
              action.payload.dataset_output?.dataset_modules[
                (action.payload.dataset_output?.dataset_modules.length || 1) - 1
              ]?.id || null,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateGetDatasetModulesPreviewAction.pending,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetDatasetModulesPreview: true,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateGetDatasetModulesPreviewAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<DatasetModuleInterface>,
        ): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetDatasetModulesPreview: false,
            datasetModulesPreview: [
              ...state.datasetModulesPreview,
              action.payload,
            ],
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateGetDatasetModulesPreviewAction.rejected,
        (state: AnalyticV2StateInterface): AnalyticV2StateInterface => {
          return {
            ...state,
            isLoadingGetDatasetModulesPreview: false,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StatePutDatasetModulesAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticOutputInterface>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: analyticDetails.inputs[0].outputs.map(
                    (output: AnalyticOutputInterface) => {
                      if (
                        output.id === action.payload.id &&
                        output.dataset_output
                      ) {
                        return action.payload
                      }
                      return output
                    },
                  ),
                },
              ],
            },
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateDeleteDatasetModulesAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticOutputInterface>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: analyticDetails.inputs[0].outputs.map(
                    (output: AnalyticOutputInterface) => {
                      if (
                        output.id === action.payload.id &&
                        output.dataset_output
                      ) {
                        return action.payload
                      }
                      return output
                    },
                  ),
                },
              ],
            },
          }
        },
      )
      .addCase(
        analyticsDetailsV2StatePostTitleOutputAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticOutputInterface>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: [
                    ...analyticDetails.inputs[0].outputs,
                    action.payload,
                  ],
                },
              ],
            },
            outputIdToScroll: action.payload.id,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StatePutTitleOutputAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticOutputInterface>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: analyticDetails.inputs[0].outputs.map(
                    (output: AnalyticOutputInterface) =>
                      output.id === action.payload.id ? action.payload : output,
                  ),
                },
              ],
            },
            outputIdToScroll: action.payload.id,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateDeleteTitleOutputAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<string>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: analyticDetails.inputs[0].outputs.filter(
                    (output: AnalyticOutputInterface) =>
                      output.id !== action.payload,
                  ),
                },
              ],
            },
          }
        },
      )
      .addCase(
        analyticsDetailsV2StatePostTextBoxesOutputAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticOutputInterface>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: [
                    ...analyticDetails.inputs[0].outputs,
                    action.payload,
                  ],
                },
              ],
            },
            outputIdToScroll: action.payload.id,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StatePutTextBoxesOutputAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticOutputInterface>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: analyticDetails.inputs[0].outputs.map(
                    (output: AnalyticOutputInterface) =>
                      output.id === action.payload.id ? action.payload : output,
                  ),
                },
              ],
            },
            outputIdToScroll: action.payload.id,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateDeleteTextBoxesOutputAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<string>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: analyticDetails.inputs[0].outputs.filter(
                    (output: AnalyticOutputInterface) =>
                      output.id !== action.payload,
                  ),
                },
              ],
            },
          }
        },
      )
      .addCase(
        analyticsDetailsV2StatePostImageOutputAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticOutputInterface>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: [
                    ...analyticDetails.inputs[0].outputs,
                    action.payload,
                  ],
                },
              ],
            },
            outputIdToScroll: action.payload.id,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StatePutImageOutputAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticOutputInterface>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: [
                    ...analyticDetails.inputs[0].outputs.map(
                      (output: AnalyticOutputInterface) => {
                        if (output.id === action.payload.id) {
                          return action.payload
                        }
                        return output
                      },
                    ),
                  ],
                },
              ],
            },
            outputIdToScroll: action.payload.id,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateDeleteImageOutputAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<string>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: analyticDetails.inputs[0].outputs.filter(
                    (output: AnalyticOutputInterface) =>
                      output.id !== action.payload,
                  ),
                },
              ],
            },
          }
        },
      )
      .addCase(
        analyticsDetailsV2StatePostStudyObjectOutputAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticOutputInterface>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: [
                    ...analyticDetails.inputs[0].outputs,
                    action.payload,
                  ],
                },
              ],
            },
            outputIdToScroll: action.payload.id,
          }
        },
      )
      .addCase(
        analyticsDetailsV2StateDeleteStudyObjectOutputAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<string>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: analyticDetails.inputs[0].outputs.filter(
                    (output: AnalyticOutputInterface) =>
                      output.id !== action.payload,
                  ),
                },
              ],
            },
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePutOutputsOrderAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<AnalyticOutputInterface[]>,
        ): AnalyticV2StateInterface => {
          return {
            ...state,
            analyticDetails: {
              ...(state.analyticDetails as AnalyticDetailsInterface),
              inputs: [
                {
                  ...(state.analyticDetails as AnalyticDetailsInterface)
                    .inputs[0],
                  outputs: action.payload,
                },
              ],
            },
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePutVisualThemeAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<{ visual_theme: AnalyticVisualThemeEnum }>,
        ): AnalyticV2StateInterface => {
          return {
            ...state,
            analyticDetails: {
              ...(state.analyticDetails as AnalyticDetailsInterface),
              inputs: [
                {
                  ...(state.analyticDetails as AnalyticDetailsInterface)
                    .inputs[0],
                  visual_theme: action.payload.visual_theme,
                },
              ],
            },
          }
        },
      )
      .addCase(
        analyticDetailsV2StatePutDatasetModulesOrderAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<{
            outputId: string
            data: { dataset_modules: string[] }
          }>,
        ): AnalyticV2StateInterface => {
          const {
            outputId,
            data: { dataset_modules: datasetModules },
          } = action.payload

          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: analyticDetails.inputs[0].outputs.map(
                    (output: AnalyticOutputInterface) => {
                      if (output.id === outputId && output.dataset_output) {
                        return {
                          ...output,
                          dataset_output: {
                            ...output.dataset_output,
                            dataset_modules: datasetModules.map((id) =>
                              output.dataset_output?.dataset_modules.find(
                                (module) => module.id === id,
                              ),
                            ),
                          },
                        }
                      }
                      return output
                    },
                  ) as AnalyticOutputInterface[],
                },
              ],
            },
          }
        },
      )
      .addCase(
        analyticsDetailsV2StatePutPreviewAction.fulfilled,
        (
          state: AnalyticV2StateInterface,
          action: PayloadAction<ReadershipProfilePreviewComparisonInterface>,
        ): AnalyticV2StateInterface => {
          const analyticDetails =
            state.analyticDetails as AnalyticDetailsInterface

          return {
            ...state,
            analyticDetails: {
              ...analyticDetails,
              inputs: [
                {
                  ...analyticDetails.inputs[0],
                  outputs: analyticDetails.inputs[0].outputs.map(
                    (output: AnalyticOutputInterface) =>
                      output.readership_profile_preview
                        ? {
                            ...output,
                            readership_profile_preview: {
                              ...output.readership_profile_preview,
                              comparison_cards:
                                output.readership_profile_preview.comparison_cards.map(
                                  (
                                    card: ReadershipProfilePreviewComparisonInterface,
                                  ) => {
                                    if (card.id === action.payload.id) {
                                      return action.payload
                                    }
                                    return card
                                  },
                                ),
                            },
                          }
                        : output,
                  ),
                },
              ],
            },
          }
        },
      )
  },
})

export const {
  resetGetBookRecoV3Status,
  resetPostNewAnalyticStatus,
  resetPutAnalyticStatus,
  reinitializeAnalyticDetailsV2State,
  analyticsDetailV2StateSetAnalyticDetailsName,
  resetPatchReportByIdChangeOwnerStatus,
  resetDeleteUserToShareReportStatus,
  resetPutUserPermissionToAnalyticStatus,
  analyticsDetailV2StateSetBooksInputsMenuOpen,
  analyticsDetailV2StateSetShowOrganizeMenu,
} = analyticsDetailsV2Slice.actions

export const analyticDetailsV2Reducer = analyticsDetailsV2Slice.reducer
