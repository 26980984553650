import styled from '@emotion/styled'

const Label = styled.div`
  font-family: 'Vollkorn', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 153.23%;
  color: #252525;
`

const ResultContainer = styled.div`
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #252525;
  margin-right: 40px;
`

interface Props {
  itemCount: number
}
const AudiencesResultsTableSearchResult = ({ itemCount }: Props) => {
  return (
    <div className="flex flex-row justify-between items-center bg-white px-4 py-2">
      <Label>Résultat de recherche</Label>
      <ResultContainer className="flex flex-row gap-2">
        <span>TOTAL</span>
        <span>:</span>
        <span>{itemCount}</span>
      </ResultContainer>
    </div>
  )
}

export default AudiencesResultsTableSearchResult
