import { RootState } from '../store'
import { TopRequestSavedInterface } from '../../core/interface/rank/top-requests-saved.interface'
import { CatalogInterface } from '../../core/interface/rank/catalog.interface'

export const selectAudiencesSearchState = (state: RootState) => {
  const { audiencesSearchState } = state
  return audiencesSearchState
}

export const selectAudiencesSearchIsComputedLoading = (state: RootState) => {
  const { audiencesSearchState } = state
  return audiencesSearchState.isComputedLoading
}

export const selectAudiencesSearchCatalogs = (
  state: RootState,
): CatalogInterface[] => {
  const { audiencesSearchState } = state
  return audiencesSearchState.catalogs || []
}

export const selectAudiencesSearchTopRequestSaved = (
  state: RootState,
): TopRequestSavedInterface[] => {
  const { audiencesSearchState } = state
  // TODO : get result for the moment
  return audiencesSearchState.topRequestSaved.results || []
}

export const selectAudiencesSearchTopRequestSavedCount = (
  state: RootState,
): number => {
  const { audiencesSearchState } = state
  return audiencesSearchState.topRequestSaved.count || 0
}

export const selectAudiencesSearchIsLoadingTopRequestSaved = (
  state: RootState,
): boolean => {
  const { audiencesSearchState } = state
  return audiencesSearchState.isLoadingTopRequestSaved
}

export const selectAudiencesSearchIsLoadingTopRequestSavedNext = (
  state: RootState,
): boolean => {
  const { audiencesSearchState } = state
  return audiencesSearchState.isLoadingTopRequestSavedNext
}
