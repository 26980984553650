import { memo, useState } from 'react'
import { orderBy } from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'
import AnalyticsOutputHeaderTabular from './AnalyticsOutputHeaderTabular'
import { SortDirectionEnum } from '../../../../../../core/enums/sort-direction.enum'
import { DatasetInterface } from '../../../../../../core/interface/analytics/dataset.interface'
import AnalyticsOutputBodyTabular from './AnalyticsOutputBodyTabular'
import { ValueConfigurationsInterface } from '../../../../../../core/interface/analytics/value-configurations.interface'
import { DatasetTypeEnum } from '../../../../../../core/enums/dataset-type.enum'
import { SourceEnum } from '../../../../../../core/interface/analytics/source.enum'
import AnalyticsOutputHorizontalClilLegend from '../AnalyticsOutputHorizontalClilLegend'
import { AnalyticsChartTypeEnum } from '../../../../../../core/enums/analytics-chart-type.enum'

interface Props {
  dataset: DatasetInterface[]
  valuesConfigurations: ValueConfigurationsInterface[]
  handleOpenBookDetails?: (
    eanId: number | null,
    showBookDetail: boolean,
  ) => void
  eanSelected?: number | null
  typeDataset: DatasetTypeEnum
  limitToTenLines?: boolean
  shrink?: boolean
  typeModule: AnalyticsChartTypeEnum
}

const FullTabular = ({
  dataset,
  valuesConfigurations,
  handleOpenBookDetails,
  typeDataset,
  eanSelected,
  limitToTenLines = false,
  shrink,
  typeModule,
}: Props) => {
  const [sortColumn, setSortColumn] = useState<{
    column: SourceEnum
    sortDirection: SortDirectionEnum
  }>({
    column: SourceEnum.RANK,
    sortDirection: SortDirectionEnum.ASC,
  })

  const orderedValuesConfigurations = [...valuesConfigurations].sort((a, b) => {
    if (a.is_primary_key) return -1
    if (b.is_primary_key) return 1
    return 0
  })

  return (
    <>
      <AnalyticsOutputHeaderTabular
        valuesConfigurations={orderedValuesConfigurations}
        sortColumn={sortColumn}
        handleSortColumn={setSortColumn}
        dataset={dataset}
      />
      <PerfectScrollbar
        style={{
          ...(limitToTenLines && {
            maxHeight: '400px',
          }),
          ...(shrink && {
            flex: 1,
          }),
        }}
      >
        {orderBy(
          dataset,
          sortColumn.column ? [sortColumn.column] : [],
          sortColumn?.sortDirection
            ? [
                sortColumn.sortDirection === SortDirectionEnum.DESC
                  ? 'desc'
                  : 'asc',
              ]
            : [],
        )
          .slice(0, 30)
          .map((data: DatasetInterface) => (
            <AnalyticsOutputBodyTabular
              key={data.rank}
              data={data}
              valuesConfigurations={orderedValuesConfigurations}
              eanSelected={eanSelected}
              handleOpenBookDetails={handleOpenBookDetails}
              typeDataset={typeDataset}
            />
          ))}
      </PerfectScrollbar>

      {![
        AnalyticsChartTypeEnum.BUBBLE_CHART,
        AnalyticsChartTypeEnum.SCATTER_PLOT,
        AnalyticsChartTypeEnum.CONSTELLATION,
      ].includes(typeModule) && (
        <AnalyticsOutputHorizontalClilLegend
          dataset={dataset}
          typeDataset={typeDataset}
        />
      )}
    </>
  )
}

export default memo(FullTabular)
