import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ReactComponent as LeftChevronIcon } from '../../assets/icons/app/left-chevron.icon.svg'
import { ReactComponent as ReloadIcon } from '../../assets/icons/app/reload.icon.svg'
import { ReactComponent as SharedIcon } from '../../assets/icons/app/shared.icon.svg'
import { AnalyticDetailsInterface } from '../../core/interface/analytics/analytic-details.interface'
import GlSpacer from '../share/GlSpacer'
import { patchReportService } from '../../core/api/services/bookmetrie/reports.service'
import { useAppDispatch } from '../../store/hooks'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import { AnalyticsStatusEnum } from '../../core/enums/analytics-status.enum'
import { AnalyticsPermissionEnum } from '../../core/enums/analytics-permission.enum'

const HeaderLayoutStyle = styled.header`
  height: 48px;
  padding: 8px;
  color: white;
  background: black;
`

const LabelShareStyle = styled.div`
  display: flex;
  height: 18px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  /* Caption/C2 - Regular */
  font-family: Fira Sans, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 140% */
  letter-spacing: 0.2px;
  padding: 4px;
  margin-left: 4px;
`

const ExitButtonStyle = styled.button`
  color: #929292;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  height: 28px;
  margin: 8px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #929292;
`

const AnalyticsTypeStyleInput = styled.input`
  min-width: 100px;
  border: none;
  outline: none;
  background-color: #000;
  color: var(--Grayscale-White, #fff);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 4px;
  &:disabled {
    pointer-events: none;
  }
  &:hover:enabled {
    background-color: #525252;
  }
  &:focus:enabled {
    background-color: #525252;
  }
`

const AnalyticDetailsHeaderLayout = ({
  isLoading,
  hasButtonPrevious,
  analyticDetails,
  handleExitAnalyticDetails,
}: {
  isLoading: boolean
  hasButtonPrevious: boolean
  analyticDetails: AnalyticDetailsInterface
  handleExitAnalyticDetails: (toExit?: boolean) => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [inputReportNameValue, setInputReportNameValue] = useState<string>('')

  useEffect(() => {
    if (inputReportNameValue === '' && analyticDetails) {
      setInputReportNameValue(analyticDetails.name)
    }
  }, [analyticDetails])

  const handleRenameButton = () => {
    if (inputReportNameValue === analyticDetails.name) return
    patchReportService(analyticDetails.id, { name: inputReportNameValue })
      .then(() => {
        dispatch(
          setShowSnackBar(
            t('snackbarMessage.renameReportConfirm', {
              reportName: inputReportNameValue,
            }),
          ),
        )
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
  }

  function handleKeyEnterUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.currentTarget.blur()
    }
  }

  const getFirstName = (): string => {
    return [
      analyticDetails?.shared_by?.first_name,
      analyticDetails?.shared_by?.last_name,
    ]
      .join(' ')
      .trim()
  }

  return (
    <HeaderLayoutStyle className="flex flex-row items-center justify-center">
      <div className="absolute left-[16px] flex flex-row gap-2 items-center justify-between">
        <ExitButtonStyle
          type="button"
          className="flex flex-row items-center justify-center gap-1"
          onClick={(event: React.MouseEvent) => {
            event.preventDefault()
            handleExitAnalyticDetails()
          }}
        >
          <LeftChevronIcon className="w-[14px] h-[14px]" />
          <span>Retour</span>
        </ExitButtonStyle>
        {(hasButtonPrevious && (
          <ExitButtonStyle
            type="button"
            className="flex flex-row items-center justify-center gap-1"
            onClick={(event: React.MouseEvent) => {
              event.preventDefault()
              navigate(-1)
            }}
          >
            <ReloadIcon className="w-[14px] h-[14px] text-white" />
            <span className="text-white">Recherche précédente</span>
          </ExitButtonStyle>
        )) ||
          null}
      </div>

      <GlSpacer />
      {(!isLoading && (
        <AnalyticsTypeStyleInput
          type="text"
          style={{ width: `${inputReportNameValue.length}ch` }}
          value={inputReportNameValue}
          onChange={(event) => setInputReportNameValue(event.target.value)}
          onBlur={handleRenameButton}
          onKeyUp={handleKeyEnterUp}
          disabled={
            analyticDetails?.status !== AnalyticsStatusEnum.GENERATED ||
            analyticDetails.permission === AnalyticsPermissionEnum.READ
          }
        />
      )) ||
        null}
      {(!!getFirstName() && (
        <LabelShareStyle>
          <SharedIcon />
          <span>partagée par {getFirstName()}</span>
        </LabelShareStyle>
      )) ||
        null}
      <GlSpacer />
    </HeaderLayoutStyle>
  )
}

export default AnalyticDetailsHeaderLayout
