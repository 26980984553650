import React from 'react'
import styled from '@emotion/styled'
import { ReactComponent as EditIcon } from '../../assets/icons/app/edit.icon.svg'
import { ReactComponent as ResetIcon } from '../../assets/icons/app/reset.icon.svg'

const IconStyle = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:hover {
    border: 1px solid #595959;
  }
`
const AudienceSearchTopRequestSavedAction = ({
  handleEditRequestName,
  handleDeleteRequest,
}: {
  handleEditRequestName: () => void
  handleDeleteRequest: () => void
}) => {
  const editRequestName = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    handleEditRequestName()
  }

  const deleteRequest = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    handleDeleteRequest()
  }

  return (
    <div className="action-icon hidden flex-row items-center px-1.5">
      <IconStyle onClick={editRequestName}>
        <EditIcon className="w-6 h-6 color-[#252525]" />
      </IconStyle>
      <IconStyle onClick={deleteRequest}>
        <ResetIcon className="w-6 h-6 color-[#252525]" />
      </IconStyle>
    </div>
  )
}

export default AudienceSearchTopRequestSavedAction
